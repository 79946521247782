
import React,{useEffect} from "react";
import { Container, Row } from "react-bootstrap";
import defaultGallery from '../../../Assets/img/banner404.png';
import { useLocation , useNavigate} from "react-router-dom";
import {parsedUserData } from '../../Admin/Utils.js';


const Page404 = ({}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleRedirect = () => {
        window.location.href = '/';
    }

    useEffect(() => {
        const path = window.location.pathname;
    
        // Check if parsedUserData is null and we're trying to visit a profile page
        if (parsedUserData !== null && !path.includes('/portaladmin')) {
            // Allow access to the profile page
            if (path.includes('profile')) {
                return; // No redirect needed
            }
    
            // If the path is anything else (invalid or non-existent), redirect to 404 page
            // This will catch all other paths that don't match an existing page
            navigate('/404', { replace: true });
        }
    
        // If the user is not logged in (parsedUserData is null)
        if (parsedUserData === null && !path.includes('/portaladmin')) {
            // Redirect both profile and any random page (careers, random paths) to 404
            if (path.includes('profile') || path.includes('careers') || path !== '/') {
                navigate('/404', { replace: true });
            }
        }
    }, []);
    return(<>

        {location.pathname.includes('/profile') ? <> <div className="h100"><h4 className='loaderWrapper'><span className="custom-loader m-auto"></span></h4></div></> : 
        <main className='plp-page pb-5 mt-5 bg-white'>
        <section className="banner404 pt-5">
            <Container>
                <Row>
                    <div className="content404">
                        <h1 className="head404">404</h1>
                        <div className="subhead404">Oops!</div>
                        <div className="desc404">Property not found. Let's discover your dream home together!</div>
                        <button className="btn btn-darkblue bg-theme btn-sm p-2 px-3 mt-3 btn-bk-home" onClick={()=>[handleRedirect()]}>Back to home</button>
                        <img src={defaultGallery} className="img-fluid banner404"/>
                    </div>
                </Row>
            </Container>
        </section>
        </main>
        }
        </>)
};

export default Page404;