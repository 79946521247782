import React, { useEffect, useState, useRef } from 'react';
import { showToastMessage, userToken, executeLaravelAPI, executeLaravelFrontAPI, buttonLoadingClass, uploadAssets } from '../../Utils';
import { Form } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Icon } from '@iconify/react';
// import { SelectToSearch } from '../SelectToSearch';
import SubmitButton from '../../shared/SubmitButton';

const ProjectEditPriceConfiguration = ({selectedProjectId }) => {
    const buttonClass=useRef('theme-btn');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [projectPriceConfigData, setEditedItem] = useState({}); 
    const [towersData, setTowersData] = useState([]);
    const [configurationsData, setConfigurationsData] = useState({});
    const [selectedTowerId, setSelectedTowerId] = useState('');
    const [selectedFetchedFromValue, setSelectedFetchedFromValue] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageUploadConfigIds, setImageUploadConfigIds] = useState([]);

    const [configurationAsset, setConfigurationsAssetData] = useState('');

    const inputReraRefs = useRef([]);
    //const input99acreRefs = useRef([]);
    //const inputDwelloRefs = useRef([]);
    const inputCustomRefs = useRef([]);
    const inputBalconyAreaRefs = useRef([]);
    const inputDryAreaRefs = useRef([]);
    const inputConfigNameRefs = useRef([]);
    const inputCarpetNameRefs = useRef([]);
    const inputActiveRefs = useRef([]);
    const inputOffersRefs = useRef([]);
    const inputQuotedPriceRefs = useRef([]);

    useEffect(() => {
        //getConfig();
        const getProjects = async () => {
            try {
                const result = await executeLaravelAPI(`project/view/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data;

                const configurationsAssetData = await executeLaravelFrontAPI(`projectconfigurationassets/${selectedProjectId}`, '', 'GET', '');
                setConfigurationsAssetData(configurationsAssetData.data);
                setEditedItem(dataProjectDetails); // Set the projectPriceConfigData state
                setSelectedFetchedFromValue(dataProjectDetails.price_fetched_from);
                const towersdata = dataProjectDetails.towers;
                const towerId = towersdata.length > 0 ? towersdata[0].id : '';
                const configurationdata = towersdata.find(tower => tower.id === towerId)
                inputReraRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                //input99acreRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                //inputDwelloRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                inputCustomRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                inputBalconyAreaRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                inputDryAreaRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                inputConfigNameRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                inputCarpetNameRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
                inputActiveRefs.current = Array(configurationdata.length).fill(true).map(() => React.createRef());
                inputOffersRefs.current = Array(configurationdata.length).fill(true).map(() => React.createRef());
                inputQuotedPriceRefs.current = Array(configurationdata.length).fill(true).map(() => React.createRef());
                
                setTowersData(towersdata);
                setConfigurationsData(configurationdata.configurations);
                setSelectedTowerId(towerId);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedProjectId) {
            getProjects();
        }
    }, [selectedProjectId]);

    //console.log(fetchedFromPortal);

    const [tableData, setTableData] = useState([
        { id: 1, config: '', value1: '', value2: '', value3: '', value4: '', value5: '' },
    ]);

    const handleFetchedFromPriceSelectChange = (e) => {
        setSelectedFetchedFromValue(e.target.value);
    };

    const handleTowerChange = (e) => {
        const towerId = parseInt(e.target.value);
        const towersdata = projectPriceConfigData.towers;
        const configurationdata = towersdata.find(tower => tower.id === towerId)

        inputReraRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        // input99acreRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        // inputDwelloRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputCustomRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputBalconyAreaRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputDryAreaRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputConfigNameRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputCarpetNameRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputOffersRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputActiveRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());
        inputQuotedPriceRefs.current = Array(configurationdata.length).fill(null).map(() => React.createRef());

        setTowersData(towersdata);
        setConfigurationsData(configurationdata.configurations);
        setSelectedTowerId(towerId);
       // console.log(projectPriceConfigData);
        setEditedItem(projectPriceConfigData);
        //console.log(configurationdata);

    };

    const handleFileChange = (e, index, id) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = files[0];
            return newFiles;
        });
        setImageUploadConfigIds(prevIds => [...prevIds, id]);
    };

    // const [inputDwelloPriceValue, setDwelloConfPriceInputValue] = useState([configurationsData.length].fill(''));
    // const [input99acrePriceValue, set99acreConfPriceInputValue] = useState([configurationsData.length].fill(''));
    // const [inputReraPriceValue, setReraConfPriceInputValue] = useState([configurationsData.length].fill(''));
    // const [inputCustomPriceValue, setCustomConfPriceInputValue] = useState([configurationsData.length].fill(''));

    // const [inputBalconyArea, setBalconyArea] = useState([configurationsData.length].fill(''));
    // const [inputDryArea, setDryArea] = useState([configurationsData.length].fill(''));
    // const [inputConfigName, setConfigName] = useState([configurationsData.length].fill(''));
    // const [inputCarpetName, setCarpetName] = useState([configurationsData.length].fill(''));
    const defaultInputActive = []; // default empty array

    const [inputActive, setActive] = useState(
        configurationsData && configurationsData.configurations
        ? configurationsData.configurations.map(config => config.is_active)
        : defaultInputActive
    );

    const handleActiveChange = async (index, isChecked, configId) => {
        try {
            const newActive = [...inputActive];
            newActive[index] = isChecked;
            setActive(newActive);

            if (isChecked) {
               
                await executeLaravelAPI(`configuration/${configId}/activate`, {
                }, 'PUT', userToken);
                showToastMessage('Configuration(s) activated', 'top-right', 'success');
            } else {
               
                await executeLaravelAPI(`configuration/${configId}/deactivate`, {
                }, 'PUT', userToken);
                showToastMessage('Configuration(s) deactivated', 'top-right', 'success');
            }
            
            const updateConfigurationsData = configurationsData.map(config => (
                config.id == configId ? { ...config, is_active: isChecked } : config
            ));
            setConfigurationsData(updateConfigurationsData);


        } catch (error) {
            console.error('Error handling active change:', error);
        }
    };

    // const handleConfigNameChange = (index, value) => {
    //     const newInputConfigName = [...inputConfigName];
    //     if (value.trim() !== '') { // Check if the value is not empty after trimming whitespace
    //         newInputConfigName[index] = value;
    //     } else {
    //         newInputConfigName[index] = ''; // If the value is empty, clear the inputConfigName
    //     }
    //     setConfigName(newInputConfigName);
    // }
    
    // const handleCarpetNameChange = (index, value) => {
    //     const newinputCarpetName = [...inputCarpetName];
    //     newinputCarpetName[index] = value;
    //     setCarpetName(newinputCarpetName);
    // }

    // const handleBalconyAreaChange = (index, value) => {
    //     const newInputBalconyArea = [...inputBalconyArea];
    //     newInputBalconyArea[index] = value;
    //     setBalconyArea(newInputBalconyArea);
    // }

    // const handleDryAreaChange = (index, value) => {
    //     const newInputDryArea = [...inputDryArea];
    //     newInputDryArea[index] = value;
    //     setDryArea(newInputDryArea);
    // }

    // const handleDwelloConfPriceChange = (index, value) => {
    //     const newInputPriceValue = [...inputDwelloPriceValue];
    //     newInputPriceValue[index] = value;
    //     setDwelloConfPriceInputValue(newInputPriceValue);
    // };

    // const handle99acreConfPriceChange = (index, value) => {
    //     const newInputPriceValue = [...input99acrePriceValue];
    //     newInputPriceValue[index] = value;
    //     set99acreConfPriceInputValue(newInputPriceValue);
    // };

    // const handleReraConfPriceChange = (index, value) => {
    //     const newInputPriceValue = [...inputReraPriceValue];
    //     newInputPriceValue[index] = value;
    //     setReraConfPriceInputValue(newInputPriceValue);
    // };
    // const handleCustomConfPriceChange = (index, value) => {
    //     const newInputPriceValue = [...inputCustomPriceValue];
    //     newInputPriceValue[index] = value;
    //     setCustomConfPriceInputValue(newInputPriceValue);
    // };

    // const parsePrice = (allInclusivePrice, fetchedfrom) => {
    //     try {
    //         const priceData = JSON.parse(allInclusivePrice);
    //         const reraObject = priceData.find(item => item.fetched_from === 'rera');
    //         const dwelloObject = priceData.find(item => item.fetched_from === 'dwello');
    //         const acre99Object = priceData.find(item => item.fetched_from === '99acre');
    //         const customObject = priceData.find(item => item.fetched_from === 'custom');
    //         if (fetchedfrom === 'rera' && reraObject) {
    //             const priceForRera = priceData.find(item => item.fetched_from === 'rera').price;
    //             return priceFormat(priceForRera);
    //         }
    //         if (fetchedfrom === 'dwello' && dwelloObject) {
    //             const priceForDwello = priceData.find(item => item.fetched_from === 'dwello').price;
    //             return priceFormat(priceForDwello);
    //         }
    //         if (fetchedfrom === '99acre' && acre99Object) {
    //             const priceFor99acre = priceData.find(item => item.fetched_from === '99acre').price;
    //             return priceFormat(priceFor99acre);
    //         }
    //         if (fetchedfrom === 'custom' && customObject) {
    //             const priceForCuatom = priceData.find(item => item.fetched_from === 'custom').price;
    //             return priceFormat(priceForCuatom);
    //         }
    //     } catch (error) {
    //         console.error('Error parsing price:', error);
    //         return '';
    //     }
    // };
    const addNewConfigRow = () => {
        const newConfigurations = [...configurationsData];
        newConfigurations.push({
            id: '',
            name: '',
            carpet: '',
            balcony_area: '',
            dry_area: '',
            rera_price: '',
            custom_price: '',
            is_active: true,
            offers: ''
        });
        setConfigurationsData(newConfigurations);
    };

    const updatePriceConfiguration = async (e) => {
        e.preventDefault();
        try {
            setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
            // let reraConfigPrice = [];
            // let acre99ConfigPrice = [];
            // let dwelloConfigPrice = [];
            // let customConfigPrice = [];
            // let configPriceData = [];
            // const assetsFormData = new FormData();
            // const configid=[];
            const updatedConfigurations = configurationsData.map(async (config, index) => {
                const assetData = new FormData();
                // console.log(JSON.stringify( config))
                //    console.log(imageUploadConfigIds);
                if(config.id === ''){
                    const newConfig = await executeLaravelAPI(`configuration/create`, {
                        ...config,
                        tower_id: selectedProjectId,
                        project_id: selectedProjectId,
                        final_price: config.quoted_price,
                        balcony_area: null,
                        dry_area: null,
                        rera_price: null,
                        custom_price: null,
                        is_active: true,
                        offers: '',
                        is_verified: true

                    }, 'POST', userToken);
                    assetData.append('image', selectedFiles[index]);
                    assetData.append('singleconfigassets', newConfig.data.id);
                    await uploadAssets(`configurationassets/create`, assetData, userToken);
                }else{
                    await executeLaravelAPI(`configuration/edit/${config.id}`, {
                        ...config,
                    }, 'PUT', userToken);
    
                    if(imageUploadConfigIds.length > 0){
                        for (const id of imageUploadConfigIds) {
                            if(id === config.id){
                                if (selectedFiles[index]) {
                                    assetData.append('image', selectedFiles[index]);
                                    assetData.append('singleconfigassets', id);
                                    await uploadAssets(`configurationassets/edit/${config.id}`, assetData, userToken);
                                    
                                }
                            }
                        }
                    }
                }
            });
            await Promise.all(updatedConfigurations);
            showToastMessage('Configuration(s) Updated Successfully', 'top-right', 'success');

            // Refetch updated configurations
            // const updatedResult = await executeLaravelAPI(`project/view/${selectedProjectId}`, '', 'GET', userToken);
            // setProjectPriceConfigData(updatedResult.data);
            // setConfigurationsData(updatedResult.data.towers.length > 0 ? updatedResult.data.towers[0].configurations : []);

            setButtonClass(buttonClass.current);

            const updatedConfigurationsAssetData = await executeLaravelFrontAPI(`projectconfigurationassets/${selectedProjectId}`, '', 'GET', '');
                setConfigurationsAssetData(updatedConfigurationsAssetData.data);
            
        } catch (error) {
            console.error('Error logging in:', error);
            //setErrors(error);
        }
        
    }

    function handleFieldChange(index, field, value) {
        setConfigurationsData(prev => {
            
            const newConfigurations = [...prev];
            if (field === 'quoted_price') {
                newConfigurations[index]['final_price'] = value;
            }
            newConfigurations[index] = { ...newConfigurations[index], [field]: value };
            return newConfigurations;
        });
    }

    // const handleSelectChange = (selectedOptions) => {
    //     setConfigurationsData(prevState => ({
    //         ...prevState,
    //         offers: selectedOptions.length > 0 ? selectedOptions.map(option => ({ id: option.value, name: option.label })) : []
    //     })); 
        
    //     // setConfigurationsData(prev => {
    //     //     const newConfigurations = [...prev];
    //     //     newConfigurations[index] = { ...newConfigurations[index], [field]: value };
    //     //     return newConfigurations;
    //     // });
    // };

    return (
        <form onSubmit={updatePriceConfiguration} className='projectForm' encType="multipart/form-data">
            <table className="table table-resposive mt-0 p-0 mb-0">
                <thead></thead>
                <tbody>
                    <tr>
                        <td className='px-0 pb-2 mb-4'>
                            <Form.Group className="row">
                                <div className="col-sm-12">
                                    
                                    <FloatingLabel controlId="floatingInput" label="Select Price Fetched From">
                                        <select name="fetched_from_price" className="form-select" value={selectedFetchedFromValue} onChange={handleFetchedFromPriceSelectChange} >
                                            <option>--Select Price Fetched From--</option>
                                            {/* <option value="dwello" selected={selectedFetchedFromValue==='dwello'}>Dwello</option>
                                            <option value="99acre" selected={selectedFetchedFromValue==='99acre'}>99Acres</option> */}
                                            <option value="rera" selected={selectedFetchedFromValue==='rera'}>Rera</option>
                                            <option value="custom" selected={selectedFetchedFromValue==='custom'}>Custom</option>
                                        </select>
                                    </FloatingLabel>
                                </div>
                            </Form.Group>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className='px-0 pb-2 mb-4'>
                           
                            <Form.Group className="row">
                                <div className="col-sm-12">
                                    <FloatingLabel controlId="floatingInput" label="Select Tower">
                                        <select name="tower" className="form-select" value={selectedTowerId}
                                            onChange={handleTowerChange} >
                                            {towersData.map((tower, index) => (
                                                <option key={tower.id} value={tower.id}>{tower.building_name}</option>
                                            ))}
                                        </select>
                                    </FloatingLabel>
                                </div>
                            </Form.Group>
                        </td>
                       
                    </tr> */}
                    
                    <tr>
                        <td>
                            <div className='row'>
                                <div className='col-12 px-0'>
                                <input type="hidden" id="configassets" value={imageUploadConfigIds || ''} redOnly />
                                    <table className='table table-resposive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>  
                                        <thead></thead>
                                        <tbody>
                                            <tr className='table-secondary'>
                                                <td>No</td>
                                                <td>Active</td>
                                                <td style={{'width':'130px'}}>BHK Type</td>
                                                <td >No.Bedrooms</td>
                                                <td>Carpet Area (sq.ft.)</td>
                                                <td>Balcony Area (sq.ft.)</td>
                                                <td>Dry Area (sq.ft.)</td>
                                                {/* <td className={`${selectedFetchedFromValue==='dwello' ? 'active' : ''}`}>Dwello Price 
                                                </td> */}
                                                {/* <td className={`${selectedFetchedFromValue==='99acre' ? 'active' : ''}`}>99Acres Price 
                                                </td> */}
                                                <td className={`${selectedFetchedFromValue==='rera' ? 'active' : ''}`}>RERA Price </td>
                                                <td className={`${selectedFetchedFromValue==='custom' ? 'active' : ''}`}>Custom Price </td>
                                                <td>Quoted Price</td>
                                                {/* <td>Unit Plan Images</td>
                                                <td>Offers</td> */}
                                                
                                                <td>
                                                    <button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={(e)=> addNewConfigRow()}>
                                                        <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                                    </button>
                                                </td>
                                                
                                            </tr>
                                            {/* {selectedTowerId && ( */}
                                                <>
                                               
                                                {Array.isArray(configurationsData) ? configurationsData.map((config, index) => (
                                                        
                                                        <tr key={index}>
                                                            
                                                            <td>{(index+1)}</td>
                                                            <td>
                                                                <Form.Check // prettier-ignore
                                                                    type="switch"
                                                                    id={`custom-switch-${index}`}
                                                                    key={index}
                                                                    ref={el => inputActiveRefs.current[index] = el}
                                                                    name={`active${index}`}
                                                                    data-config-id={config.id}
                                                                    checked={config.is_active}       
                                                                    onChange={(e) => handleActiveChange(index, e.target.checked, e.target.getAttribute('data-config-id'))}
                                                                />
                                                            </td>                                                           
                                                            {/* <td>
                                                                <select className='form-select' disabled="disabled">
                                                                    <option key={config.id} value={config.name}>{config.name}</option>
                                                                </select>
                                                            </td> */}
                                                            <td> 
                                                                <input   
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={config.name}
                                                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={config.bedrooms}
                                                                    onChange={(e) => handleFieldChange(index, 'bedrooms', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                

                                                                type="text"
                                                                className="form-control"
                                                                value={config.carpet}
                                                                onChange={(e) => handleFieldChange(index, 'carpet', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    

                                                                    type="text"
                                                                    className="form-control"
                                                                    value={config.balcony_area}
                                                                    onChange={(e) => handleFieldChange(index, 'balcony_area', e.target.value)}
                                                                />
                                                                 {/* { console.log(`Index ${index} - Balcony Area:`, inputBalconyArea [index], 'Config Balcony Area:', config.balcony_area) } */}
                                                            </td>
                                                            <td>
                                                                <input
                                                                   

                                                                    type="text"
                                                                    className="form-control"
                                                                    value={config.dry_area}
                                                                    onChange={(e) => handleFieldChange(index, 'dry_area', e.target.value)}
                                                                />
                                                            </td>
                                                            {/* <td className={`${selectedFetchedFromValue==='dwello' ? 'active' : ''}`}>
                                                                <input
                                                                   
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={config.dwello_price}
                                                                    onChange={(e) => handleFieldChange(index, 'dwello_price', e.target.value)}
                                                                />
                                                            </td>
                                                            <td className={`${selectedFetchedFromValue==='99acre' ? 'active' : ''}`}>
                                                                <input
                                                                
                                                                type="text"
                                                                className="form-control"
                                                                value={config.acre99_price}
                                                                onChange={(e) => handleFieldChange(index, 'acre99_price', e.target.value)}
                                                                />
                                                            </td> */}
                                                            <td className={`${selectedFetchedFromValue==='rera' ? 'active' : ''}`}>
                                                                <input
                                                               
                                                                type="text"
                                                                className="form-control"
                                                                value={config.rera_price}
                                                                onChange={(e) => handleFieldChange(index, 'rera_price', e.target.value)}
                                                                />
                                                            </td>
                                                            <td className={`${selectedFetchedFromValue==='custom' ? 'active' : ''}`}>
                                                                <input
                                                                
                                                                type="text"
                                                                className="form-control"
                                                                value={config.custom_price}
                                                                onChange={(e) => handleFieldChange(index, 'custom_price', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                type="text"
                                                                className="form-control"
                                                                value={config.quoted_price}
                                                                onChange={(e) => handleFieldChange(index, 'quoted_price', e.target.value)}
                                                                />
                                                                <input
                                                                    type="hidden"
                                                                    name="final_price"
                                                                    value={config.final_price}
                                                                    onChange={(e) => handleFieldChange(index, 'final_price', e.target.value)}
                                                                />
                                                            </td>
                                                            {/* <td>
                                                                {configurationAsset && configurationAsset.map((asset) => {
                                                                if (asset.configuration_id === config.id) {
                                                                    return (
                                                                        <img key={asset.id} src={`${s3url}/project-assets${asset.url}`} alt="Configuration" width="100" />
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            <input type="file" className="form-control" onChange={(e) => handleFileChange(e, index, config.id)} />
                                                            </td> */}
                                                            {/* <td>
                                                            <SelectToSearch 
                                                                type={"offers"} 
                                                                defaultValues={config?.offers} 
                                                                name="offers" 
                                                                onChange={(selectedOptions) => handleSelectChange(selectedOptions)} 
                                                                typesection="configuration"
                                                            />
                                                            </td> */}
                                                        </tr>
                                                   )) : null}
                                                </>
                                            {/* )} */}
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>    
                <tfoot>
                    <div className='text-end mt-3'>
                        {/* <Button variant="secondary" onClick={handleCloseForm} className="theme-btn secondary me-2">Cancel</Button> */}
                        <SubmitButton variant="success" type="submit" className={buttonClassLoader} label="Update Price Configurations" />
                    </div>
                </tfoot>
            </table>
            
        </form>                                                 
    )
}
export default ProjectEditPriceConfiguration;
