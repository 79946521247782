import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import { SearchVisibilityContext } from '../../Layouts/SearchVisibilityContext';

const ContentBox = ({ appAbsPath, createGa4Event, Box, Blueright, Flowers, Stars, Hot, Fire, Affordable, Sky1, Sky2, showPara, showTopContent, showForwardIcon, allowLazyLoad, handleClose, origin, Looking1, Looking2, Looking3, Looking4, Looking5, Looking6, hideLookingFor }) => {
    const { globalCity } = useContext(SearchVisibilityContext);
    return (
        <>
            {origin === "search" && (
                <div className="searchcontent">
                    <div className='contentbox'>
                        {showTopContent && (
                            <div className='box-wrapper'>
                                <div className='content'>
                                    <div>
                                        <h2 className='sec-title fw-bold text-md-start text-center'>What Are You Looking For?</h2>
                                        <p className='sec-desc text-md-start text-center'>BeyondWalls is an AI-powered property search platform that offers a comprehensive property consultancy service designed to simplify homebuying for you! This is your one-stop homebuying portal that brings you the right comparison of properties, for the right locations, to help you make the right decision.</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='box-wrapper'>
                            <Link to={`${appAbsPath}/properties/ready-to-move-flats-in-${globalCity}`} onClick={() => { 
                                createGa4Event('Click', 'Home Property Segments', 'Ready to Move In');
                                handleClose();
                                }} className='text-decoration-none'>
                                <div className='box box1'>
                                    <div>
                                        <h2 className='boxhead'>Ready to Move In</h2>
                                        {showPara && (
                                            <p className='sec-desc text-md-start'>Find your dream home that's prepared for immediate occupancy, offering seamless transition and comfort.</p>
                                        )}
                                    </div>
                                    <div className='imgbox'>
                                        <div>
                                            <img
                                                {...(allowLazyLoad ? { 'data-src': Box } : { src: Box })}
                                                alt="area-map"
                                                className={`img-fluid boximg ${allowLazyLoad ? 'lazy' : ''}`}
                                            />
                                        </div>
                                        {showForwardIcon && (
                                            <div>
                                                <img src={Blueright} alt="area-map" className='img-fluid' />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='box-wrapper'>
                            <Link to={`${appAbsPath}/properties/great-view-projects-in-${globalCity}`} onClick={() => { 
                                createGa4Event('Click', 'Home Property Segments', 'What a View');
                                handleClose();
                                }} className='text-decoration-none'>
                                <div className='box box2'>
                                    <div>
                                        <h2 className='boxhead'>What a View</h2>
                                        {showPara && (
                                            <p className='sec-desc text-md-start'>Enjoy stunning vistas that redefine everyday living, providing a serene backdrop to your daily life.</p>
                                        )}
                                    </div>
                                    <div className='imgbox'>
                                        <div>
                                            <img
                                                {...(allowLazyLoad ? { 'data-src': Flowers } : { src: Flowers })}
                                                alt="area-map"
                                                className={`img-fluid flowers ${allowLazyLoad ? 'lazy' : ''}`}
                                            />
                                        </div>
                                        {showForwardIcon && (
                                            <div>
                                                <img src={Blueright} alt="area-map" className='img-fluid' />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='contentbox contentbox1'>
                        <Link to={`${appAbsPath}/properties/new-projects-in-${globalCity}`} onClick={() => { 
                            createGa4Event('Click', 'Home Property Segments', 'New In Town');
                            handleClose();
                            }} className='text-decoration-none'>
                            <div className='box box3'>
                                <div>
                                    <h2 className='boxhead'>New In Town</h2>
                                    {showPara && (
                                        <p className='sec-desc text-md-start'>Explore the latest developments offering modern living solutions, designed to meet the dynamic needs of urban dwellers.</p>
                                    )}
                                </div>
                                <div className='imgbox'>
                                    <div>
                                        <img
                                            {...(allowLazyLoad ? { 'data-src': Stars } : { src: Stars })}
                                            alt="area-map"
                                            className={`img-fluid stars ${allowLazyLoad ? 'lazy' : ''}`}
                                        />
                                    </div>
                                    {showForwardIcon && (
                                        <div>
                                            <img src={Blueright} alt="area-map" className='img-fluid' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                        <Link to={`${appAbsPath}/properties/hot-selling-projects-in-${globalCity}`} onClick={() => { 
                            createGa4Event('Click', 'Home Property Segments', 'Hot Selling');
                            handleClose();
                            }} className='text-decoration-none'>
                            <div className='box box4'>
                                <div>
                                    <h2 className='boxhead'>Hot <br />Selling</h2>
                                    {showPara && (
                                        <p className='sec-desc text-md-start'>Grab the hottest properties with high demand and value, ensuring a smart investment choice for discerning buyers.</p>
                                    )}
                                </div>
                                <div className='hotselldiv'>
                                    <img src={Hot} alt="area-map" className='img-fluid hotsell' />
                                    {
                                        origin === 'search' && (
                                            <p className='hotselltxt visible-ipad'>HOT</p>
                                        )
                                    }
                                </div>
                                <div className='imgbox'>
                                    <div>
                                        <img
                                            {...(allowLazyLoad ? { 'data-src': Fire } : { src: Fire })}
                                            alt="area-map"
                                            className={`img-fluid ${allowLazyLoad ? 'lazy' : ''}`}
                                        />
                                    </div>
                                    {showForwardIcon && (
                                        <div>
                                            <img src={Blueright} alt="area-map" className='img-fluid' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                        <Link to={`${appAbsPath}/properties/affordable-apartments-in-${globalCity}`} onClick={() => { 
                            createGa4Event('Click', 'Home Property Segments', 'Affordable Homes');
                            handleClose();
                            }} className='text-decoration-none'>
                            <div className='box box5'>
                                <div>
                                    <h2 className='boxhead'>Affordable Homes</h2>
                                    {showPara && (
                                        <p className='sec-desc text-md-start'>Experience quality living at prices that fit your budget, without compromising on comfort or style.</p>
                                    )}
                                </div>
                                <div className='imgbox'>
                                    <div>
                                        <img
                                            {...(allowLazyLoad ? { 'data-src': Affordable } : { src: Affordable })}
                                            alt="area-map"
                                            className={`img-fluid afford ${allowLazyLoad ? 'lazy' : ''}`}
                                        />
                                    </div>
                                    {showForwardIcon && (
                                        <div>
                                            <img src={Blueright} alt="area-map" className='img-fluid' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                        <Link to={`${appAbsPath}/properties/high-rise-projects-in-${globalCity}`} onClick={() => { 
                            createGa4Event('Click', 'Home Property Segments', 'Live In The Sky');
                            handleClose();
                            }} className='text-decoration-none'>
                            <div className='box box6'>
                                <div>
                                    <h2 className='boxhead'>Live in the sky</h2>
                                    {showPara && (
                                        <p className='sec-desc text-md-start'>Elevate your living experience with high-rise luxury and breathtaking views, where every sunrise and sunset becomes a spectacle.</p>
                                    )}
                                </div>

                                <div className='imgbox'>
                                    <div>
                                        <img
                                            {...(allowLazyLoad ? { 'data-src': Sky1 } : { src: Sky1 })}
                                            alt="area-map"
                                            className={`img-fluid sky1 ${allowLazyLoad ? 'lazy' : ''}`}
                                        />
                                        <img
                                            {...(allowLazyLoad ? { 'data-src': Sky2 } : { src: Sky2 })}
                                            alt="area-map"
                                            className={`img-fluid sky2 ${allowLazyLoad ? 'lazy' : ''}`}
                                        />
                                    </div>
                                    {showForwardIcon && (
                                        <div>
                                            <img src={Blueright} alt="area-map" className='img-fluid' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            )}
            {origin !== "search" && (
                <div className="lookingfor">
                    <div className="row pdltrt">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <h2 className='sec-title fw-bold text-md-start text-center'>What Are You Looking For?</h2>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <p className='sec-desc text-md-start text-center'>BeyondWalls is an AI-powered property search platform that offers a comprehensive property consultancy service designed to simplify homebuying for you! This is your one-stop homebuying portal that brings you the right comparison of properties, for the right locations, to help you make the right decision.</p>
                        </div>
                    </div>
                    <div>
                        <div className="looking-wrap">
                            <div className="lookbox lookbox1">
                                <Link to={`${appAbsPath}/properties/ready-to-move-flats-in-${globalCity}`} onClick={() => { 
                                    createGa4Event('Click', 'Home Property Segments', 'Ready to Move In');
                                    handleClose();
                                    }} className='text-decoration-none'>
                                    <div className="lookbox-inner">
                                        <div className="lookbox-img">
                                            <img src={Looking1} className='img-fluid' alt="Ready to Move In" />
                                        </div>
                                        <div className="lookbox-content">
                                            <h3 className='looktitle'>Ready to Move In</h3>
                                            <p className='lookdesc'>Find your dream home that's prepared for immediate occupancy, offering seamless transition and comfort.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="lookbox lookbox2">
                                <Link to={`${appAbsPath}/properties/great-view-projects-in-${globalCity}`} onClick={() => { 
                                    createGa4Event('Click', 'Home Property Segments', 'What a View');
                                    handleClose();
                                    }} className='text-decoration-none'>
                                    <div className="lookbox-inner">
                                        <div className="lookbox-img">
                                            <img src={Looking2} className='img-fluid' alt="What a View" />
                                        </div>
                                        <div className="lookbox-content">
                                            <h3 className='looktitle'>What a View</h3>
                                            <p className='lookdesc'>Enjoy stunning vistas that redefine everyday living, providing a serene backdrop to your daily life.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="lookbox lookbox3">
                                <Link to={`${appAbsPath}/properties/new-projects-in-${globalCity}`} onClick={() => { 
                                    createGa4Event('Click', 'Home Property Segments', 'New In Town');
                                    handleClose();
                                    }} className='text-decoration-none'>
                                    <div className="lookbox-inner">
                                        <div className="lookbox-img">
                                            <img src={Looking3} className='img-fluid' alt="New In Town" />
                                        </div>
                                        <div className="lookbox-content">
                                            <h3 className='looktitle'>New In Town</h3>
                                            <p className='lookdesc'>Explore the latest developments offering modern living solutions, designed to meet the dynamic needs of urban dwellers.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="lookbox lookbox4">
                                <Link to={`${appAbsPath}/properties/hot-selling-projects-in-${globalCity}`} onClick={() => { 
                                    createGa4Event('Click', 'Home Property Segments', 'Hot Selling');
                                    handleClose();
                                    }} className='text-decoration-none'>
                                    <div className="lookbox-inner">
                                        <div className="lookbox-img">
                                            <img src={Looking4} className='img-fluid' alt="Hot Selling" />
                                        </div>
                                        <div className="lookbox-content">
                                            <h3 className='looktitle'>Hot Selling</h3>
                                            <p className='lookdesc'>Grab the hottest properties with high demand and value, ensuring a smart investment choice for discerning buyers.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="lookbox lookbox5">
                                <Link to={`${appAbsPath}/properties/affordable-apartments-in-${globalCity}`} onClick={() => { 
                                    createGa4Event('Click', 'Home Property Segments', 'Affordable Homes');
                                    handleClose();
                                    }} className='text-decoration-none'>
                                    <div className="lookbox-inner">
                                        <div className="lookbox-img">
                                            <img src={Looking5} className='img-fluid' alt="Affordable Homes" />
                                        </div>
                                        <div className="lookbox-content">
                                            <h3 className='looktitle'>Affordable Homes</h3>
                                            <p className='lookdesc'>Experience quality living at prices that fit your budget, without compromising on comfort or style.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="lookbox lookbox6">
                                <Link to={`${appAbsPath}/properties/high-rise-projects-in-${globalCity}`} onClick={() => { 
                                    createGa4Event('Click', 'Home Property Segments', 'Live In The Sky');
                                    handleClose();
                                    }} className='text-decoration-none'>
                                    <div className="lookbox-inner">
                                        <div className="lookbox-img">
                                            <img src={Looking6} className='img-fluid' alt="Live In The Sky" />
                                        </div>
                                        <div className="lookbox-content">
                                            <h3 className='looktitle'>Live In The Sky</h3>
                                            <p className='lookdesc'>Elevate your living experience with high-rise luxury and breathtaking views, where every sunrise and sunset becomes a spectacle.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContentBox;
