import React, { useState, useContext } from 'react';
import { executeLaravelAPI, userToken, showToastMessage, parsedUserData, s3url, createGa4Event } from '../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { setUserDownloadBrochure } from '../../Admin/UserActivatyTracker';
import { Icon } from '@iconify/react';

const WishListBtn = ({ projectId, loggeduserdetails, color, width, height, isWishlistActives, iconType = "heart", projectBrochure = '', projectName = '' }) => {
    const [isActive, setIsActive] = useState(false); // State to toggle heart
    const [isWishList, setIsWishList] = useState(false); // State for wishlist active
    const [isInterested, setIsInterested] = useState(false); // State to toggle button text
    const { isContextWishList, setContextIsWishList, setDownloadBrochure, setBrouchureUrl } = useContext(SearchVisibilityContext);
    const brochureURL = projectBrochure ? projectBrochure : '';

    const handleShortListedClick = async (projectId) => {
        try {
            const result = await executeLaravelAPI(`add-wishlist?wishlistid=${projectId}`, '', 'POST', userToken);
            if (result.status === 200) {
                showToastMessage('Shortlisted Project has been added successfully', 'top-right', 'success');
                setIsActive(true);
                setIsWishList(true);
            } else {
                showToastMessage('Shortlisted Project has not been added.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };

    const handleShortListedRemove = async (projectId) => {
        try {
            const result = await executeLaravelAPI(`remove-wishlist?wishlistid=${projectId}`, '', 'POST', userToken);
            if (result.status === 200) {
                showToastMessage('Shortlisted Project has been removed successfully', 'top-right', 'error');
                setIsActive(false);
                setIsWishList(false);
            } else {
                showToastMessage('Shortlisted Project has not been removed.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };
    // console.log(brochureURL)
    const handleNotLogin = (btnClick) => {
        document.getElementById('overlay').style.display = 'block';

        if (btnClick === "Download Brochure") {
            document.getElementById('login_title').textContent = "Your brochure is ready, login to download";
            setDownloadBrochure(true);
            setBrouchureUrl(brochureURL);
        } else {
            document.getElementById('login_title').textContent = "Add this project to your wishlist";
        }
        // setContextIsWishList({isWishList:true});
        if (isContextWishList.isWishList === true) {
            setContextIsWishList({ isWishList: false, projectId: 0 });
        }
        setContextIsWishList({ isWishList: true, projectId: projectId });

    };

    const toggleInterest = async () => {
        if (isInterested) {
            await handleShortListedRemove(projectId); // Remove from wishlist
        } else {
            await handleShortListedClick(projectId); // Add to wishlist
        }
        setIsInterested((prev) => !prev); // Toggle button text
    };

    const renderHeartIcon = (onClickHandler, className) => (
        <div className={className} onClick={onClickHandler}>
            <svg
                id="Component_21_117"
                data-name="Component 21 – 117"
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 31 31"
            >
                <g
                    id="Ellipse_112"
                    data-name="Ellipse 112"
                    fill="none"
                    stroke={color}
                    strokeWidth="1"
                >
                    <circle cx="15.5" cy="15.5" r="15.5" stroke="none" />
                    <circle cx="15.5" cy="15.5" r="15" fill="none" />
                </g>
                <path
                    id="Icon_ionic-ios-heart"
                    data-name="Icon ionic-ios-heart"
                    d="M13.682,3.938h-.034A3.858,3.858,0,0,0,10.427,5.7,3.858,3.858,0,0,0,7.206,3.938H7.172a3.833,3.833,0,0,0-3.8,3.831A8.253,8.253,0,0,0,5,12.268,28.4,28.4,0,0,0,10.427,17.5a28.4,28.4,0,0,0,5.432-5.232,8.253,8.253,0,0,0,1.621-4.5A3.833,3.833,0,0,0,13.682,3.938Z"
                    transform="translate(5.344 4.781)"
                    fill={color}
                />
            </svg>
        </div>
    );

    return (
        iconType === "heart" ? (
            loggeduserdetails !== null ? (
                renderHeartIcon(
                    isWishList || isWishlistActives
                        ? () => handleShortListedRemove(projectId)
                        : () => handleShortListedClick(projectId),
                    `irfswish position-absolute c-pointer zindex-10 top-0 end-0 m-3 my-0 pt-3 ${(isWishList && isActive) || isWishlistActives ? "shortlisted" : ""
                    }`
                )
            ) : (
                <a
                    onClick={handleNotLogin}
                    className="irfswish position-absolute c-pointer zindex-10 top-0 end-0 m-3 my-0 pt-3"
                >
                    {renderHeartIcon(null, "")}
                </a>
            )
        ) : iconType === "button" ? (
            loggeduserdetails !== null ? (
                <>
                    <div className="mt-3">
                        <button onClick={() => { toggleInterest(); createGa4Event("Click", `${window.location.pathname.includes('property') ? 'Project Detail' : 'Project Listing'} Iam_intrested`, projectName); }} className="btn sc me-3">
                            <span>{isInterested ? "Request sent" : "Contact Now"}</span>
                        </button>
                        {brochureURL !== '' && (
                            parsedUserData !== null ? (
                                <a href={`${s3url}/project-assets${brochureURL}`} onClick={() => {
                                    setUserDownloadBrochure(projectId);
                                    createGa4Event("Click",`${window.location.pathname.includes('property') ? 'Project Detail' : 'Project Listing'} Download Brochure`, projectName);
                                }} target='_blank' className='btn sc d-inline-flex align-items-center'>
                                    <span>Download Brochure</span>
                                    <Icon icon="mdi:download" width="1em" height="1em" color='#fff' />
                                </a>
                            ) : (
                                <a onClick={() => {
                                    handleNotLogin("Download Brochure");
                                    setUserDownloadBrochure(projectId);
                                    createGa4Event("Click", `${window.location.pathname.includes('property') ? 'Project Detail' : 'Project Listing'} Download Brochure`, projectName);
                                }} className='btn sc d-inline-flex align-items-center'>
                                    <span>Download Brochure</span>
                                    <Icon icon="mdi:download" width="1em" height="1em" color='#fff' />
                                </a>
                            ))}
                    </div>

                </>

            ) : (
                <>
                    <div className="mt-3">
                        <button onClick={() => { handleNotLogin(); createGa4Event("Click", `${window.location.pathname.includes('property') ? 'Project Detail' : 'Project Listing'}`, "iam_intrested", projectName); }} className="btn sc me-3">
                            <span>Contact Now</span>
                        </button>

                        {brochureURL !== '' && (
                            parsedUserData !== null ? (
                                <a href={`${s3url}/project-assets${brochureURL}`} onClick={() => {
                                    setUserDownloadBrochure(projectId);
                                    createGa4Event("Click", `${window.location.pathname.includes('property') ? 'Project Detail' : 'Project Listing'} Download Brochure`, projectName);
                                }} target='_blank' className='btn sc d-inline-flex align-items-center'>
                                    <span>Download Brochure</span>
                                    <Icon icon="mdi:download" width="1em" height="1em" color='#fff' />
                                </a>
                            ) : (
                                <a onClick={() => {
                                    handleNotLogin("Download Brochure");
                                    setUserDownloadBrochure(projectId);
                                    createGa4Event("Click", `${window.location.pathname.includes('property') ? 'Project Detail' : 'Project Listing'} Download Brochure`, projectName);
                                }} className='btn sc d-inline-flex align-items-center'>
                                    <span>Download Brochure</span>
                                    <Icon icon="mdi:download" width="1em" height="1em" color='#fff' />
                                </a>
                            ))}
                    </div>

                </>
            )
        ) : null
    );
};

export default WishListBtn;
