import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMap } from "react-leaflet";
import { s3url, appAbsPath, getSortBHKConf, getMinMaxPrice, formatNumber, isMobileDevice, generateSlug } from './../../Admin/Utils';
import defaultGallery from '../../../images/default-corporate-image.webp';

const ProjectMapPopupInfo = ({ hoveredProject, setHoveredProject, mouseHover }) => {
  const map = useMap();
  const [positionStyle, setPositionStyle] = useState({});
  const popupRef = useRef(null);
  const isMobile = isMobileDevice();
  const [hideTimer, setHideTimer] = useState(null);  // To hold the timer for auto-hide

  const updatePosition = useCallback(() => {
    if (hoveredProject && popupRef.current) {
      const markerPosition = map.latLngToContainerPoint([hoveredProject.address.latitude, hoveredProject.address.longitude]);
      const mapContainer = map.getContainer();
      const mapBounds = {
        width: mapContainer.clientWidth,
        height: mapContainer.clientHeight,
      };

      const popupWidth = isMobile ? 200 : 240;
      const popupHeight = 250;
      const padding = 10;

      let left = markerPosition.x;
      let top = markerPosition.y;

      // Adjust position to keep popup within map bounds
      if (left + popupWidth > mapBounds.width - padding) {
        left = left - popupWidth - padding;
      } else {
        left = left + padding;
      }

      if (top + popupHeight > mapBounds.height - padding) {
        top = top - popupHeight - padding;
      } else {
        top = top + padding;
      }

      setPositionStyle({
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
        width: `${popupWidth}px`,
        maxWidth: `${mapBounds.width - 2 * padding}px`,
      });
    }
  }, [hoveredProject, map, isMobile]);

  // Hide the popup when clicking outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setHoveredProject(null); // Hide the popup if clicked outside
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setHoveredProject]);

  // Auto-hide the popup after 2 seconds if the mouse hasn't entered
  useEffect(() => {
    if (hoveredProject && !mouseHover) {
      // Set a timer to hide the popup after 2 seconds
      const timer = setTimeout(() => {
        setHoveredProject(null); // Hide popup after 2 seconds
      }, 2000);
      setHideTimer(timer); // Store the timer ID

      return () => {
        clearTimeout(timer); // Clear the timer if the component is unmounted or updated
      };
    }
  }, [hoveredProject, setHoveredProject]);

  // Ensure useEffect always runs to update the popup position
  useEffect(() => {
    if (hoveredProject) {
      updatePosition();
      const handleEvents = () => {
        requestAnimationFrame(updatePosition);
      };
      map.on("zoom move", handleEvents);
      window.addEventListener("resize", handleEvents);
      return () => {
        map.off("zoom move", handleEvents);
        window.removeEventListener("resize", handleEvents);
      };
    }
  }, [hoveredProject, map, updatePosition]);

  // Hide the popup if the mouse leaves the component
  useEffect(() => {
    const handleMouseOut = (event) => {
      // Ensure the mouse has left the body of the popup
      if (popupRef.current && !popupRef.current.contains(event.relatedTarget)) {
        setHoveredProject(null); // Hide the popup when mouse leaves
      }
    };

    const popupElement = popupRef.current;
    if (popupElement) {
      popupElement.addEventListener("mouseleave", handleMouseOut);  // Attach the listener for mouseleave
    }

    // Clean up the event listener
    return () => {
      if (popupElement) {
        popupElement.removeEventListener("mouseleave", handleMouseOut);  // Clean up on unmount
      }
    };
  }, [hoveredProject, map, updatePosition]);

  // Hide the popup if mouse enters before 2 seconds
  useEffect(() => {
    const handleMouseEnter = () => {
      if (hideTimer) {
        clearTimeout(hideTimer); // Cancel the auto-hide timer if mouse enters the popup
        setHideTimer(null); // Reset the timer
      }
    };

    const popupElement = popupRef.current;
    if (popupElement) {
      popupElement.addEventListener("mouseenter", handleMouseEnter);  // Attach the listener for mouseenter
    }

    return () => {
      if (popupElement) {
        popupElement.removeEventListener("mouseenter", handleMouseEnter);  // Clean up on unmount
      }
    };
  }, [hideTimer]);

  if (!hoveredProject) return null; // Render nothing if no project is hovered

  const imageUrl =
    hoveredProject.gallery &&
    Array.isArray(hoveredProject.gallery) &&
    hoveredProject.gallery.length > 0 &&
    hoveredProject?.gallery_thumbnail[0]?.url !== undefined
      ? encodeURI(`${s3url}/project-assets${hoveredProject?.gallery_thumbnail[0]?.url}`)
      : defaultGallery;

  const projectUrl = hoveredProject.is_irfs
    ? `${appAbsPath}/irfs/property/${hoveredProject.slug}`
    : `${appAbsPath}/property/${generateSlug(hoveredProject.address.city)}/${generateSlug(hoveredProject.address.location)}/${hoveredProject.slug}`;

  return (
    <div
      ref={popupRef}
      className="project-info"
      style={{
        ...positionStyle,
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        zIndex: 1000,
      }}
    >
      <div className="inner-map-popup" id={hoveredProject.id}>
        <a href={projectUrl} className="text-decoration-none" target="_blank" rel="noreferrer">
          <img
            src={imageUrl}
            className="project-img"
            alt={hoveredProject.name}
            style={{ maxWidth: "100%", height: isMobile ? "100px" : "150px", objectFit: "cover" }}
          />
          <div className="leaflet-popup-content">
            <p className="dev-name text-truncate" title={hoveredProject.developer.name}>
              {hoveredProject.developer.name}
            </p>
            <a href={projectUrl} target="_blank" className="text-secondary text-capitalize text-decoration-none">
              <h6 className="pname m-0">{hoveredProject.name}</h6>
            </a>
            <p className="conf-desc col-12 mt-1 mb-1 text-grey">
              {getSortBHKConf(hoveredProject)} in {hoveredProject.address?.location}, {hoveredProject.address?.city}
            </p>
            <div className="pricebox">
              <p className="text-black mb-0">
                {getMinMaxPrice(hoveredProject) &&
                getMinMaxPrice(hoveredProject).minPrice !== null &&
                formatNumber(getMinMaxPrice(hoveredProject).minPrice.toFixed(2)) !== 0 ? (
                  getMinMaxPrice(hoveredProject).minPrice === getMinMaxPrice(hoveredProject).maxPrice ? (
                    <span className="project-price">
                      {`₹${formatNumber(getMinMaxPrice(hoveredProject).minPrice.toFixed(2))}`}
                    </span>
                  ) : (
                    <span className="project-price">
                      {`₹${formatNumber(getMinMaxPrice(hoveredProject).minPrice.toFixed(2))} - ₹${formatNumber(
                        getMinMaxPrice(hoveredProject).maxPrice.toFixed(2)
                      )}`}
                    </span>
                  )
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProjectMapPopupInfo;
