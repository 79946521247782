
import React, { useState,createContext,useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { use } from 'react';

import { setCustomCookie, getCustomCookie, createGa4Event } from '../../Admin/Utils';

export const SearchVisibilityContext = createContext();
// Helper function to read a cookie
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const SearchVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [downloadCouponBtn, setDownloadCouponBtn] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [searchOptions, setSearchOptions] = useState([]);
  const [irfsProject, setIrfsProject] = useState(false);
  const [priceFormSubmitted, setPriceFormSubmitted] = useState(false);
  const [isCouponGenerated,setIsCouponGenerated] = useState(false);
  const [showGamification, setShowGamification] = useState(true);
  const [showExitIntentPopup, setShowExitIntentPopup] = useState(false);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [isBwStudioPage, setisBwStudioPage] = useState(false);
  const [universalLoader, setUniversalLoader] = useState(false);
  const [footerVisibility, setFooterVisibility] = useState(true);
  const [selectedValuesCount, setSelectedValuesCount] = useState(0);
  const [irfsAnimationStatus, setIrfsAnimationStatus] = useState(false);
  const [isContextWishList, setContextIsWishList] = useState({isWishList:false, projectId:0});
  const url = new URL(window.location.href);
  const [globalCity, setGlobalCity] = useState(() => {
    const cookieCity = getCookie("globalCity")?.toLowerCase();
    const urlCity = url.pathname.includes("/buy/") ? url.pathname.split("/")[3]?.toLowerCase() : null;    
    return  cookieCity|| urlCity || "pune"; 
  }); 
  const [downloadBrochure, setDownloadBrochure] = useState(false);
  const [brouchureUrl, setBrouchureUrl] = useState('');
  const [lastVisitedPage, setLastVisitedPage] = useState(() => getCustomCookie("lastVisitedPage") || "");
  const [isCompareLogin, setCompareLogin] = useState(false);
  const [compareProjectURL, setCompareProjectURL] = useState('');
  const [homeBannerSearchOpen, setHomeBannerSearchOpen] = useState(false);
   
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const previousPage = getCustomCookie("lastVisitedPage");
    let catogery = 'Navigation';

    if (previousPage && previousPage !== currentPath) {

      if(previousPage === '/'){
          catogery = 'Home';
      }
      else if(previousPage.includes('properties')){
         catogery = 'Properties';
      }else if(previousPage.includes('property')){
         catogery = 'Property';
      }
      // Fire GA4 Event: User moved from `previousPage` to `currentPath`
      createGa4Event("User Navigation Journey", `${catogery}`, `from ${previousPage} to ${currentPath}`);

      // console.log(`GA4 Event: User moved from ${previousPage} to ${currentPath}`);
    }

    // Update the last visited page (store for future navigation tracking)
    setCustomCookie("lastVisitedPage", currentPath, 1);
    setLastVisitedPage(currentPath);
  }, [location.pathname]); // Runs on every page navigation


  useEffect(() => {
    // Define the paths that should hide the footer
    const pathsToHideFooter = [
      'properties',
      `/ready-to-move-flats-in-pune${globalCity}`,
      `/great-view-projects-in-${globalCity}`,
      `/new-projects-in-${globalCity}`,
      `/hot-selling-projects-in-${globalCity}`,
      `/affordable-apartments-in-${globalCity}`,
      `/high-rise-projects-in-${globalCity}`,
    ];
  
    // Check if the URL contains "map-view"
    // const isMapView = location.pathname.includes('map-view');
  
    // Determine if the footer should be hidden
    const shouldHideFooter = pathsToHideFooter.some(path => 
      location.pathname.includes(path)
    );
  
    // Set footer visibility: show the footer if "map-view" is in the path
    // or hide it based on the conditions
    setFooterVisibility(!shouldHideFooter);
  }, [location]);
  

  return (
    <SearchVisibilityContext.Provider
      value={{
        isVisible,
        setIsVisible,
        searchOptions,
        setSearchOptions,
        downloadCouponBtn,
        setDownloadCouponBtn,
        couponData,
        setCouponData,
        irfsProject,
        setIrfsProject,
        priceFormSubmitted,
        setPriceFormSubmitted,
        isCouponGenerated,
        setIsCouponGenerated,
        showGamification,
        setShowGamification,
        showExitIntentPopup,
        setShowExitIntentPopup,
        isDetailsPage,
        setIsDetailsPage,
        isBwStudioPage,
        setisBwStudioPage,
        universalLoader,
        setUniversalLoader,
        footerVisibility,
        setFooterVisibility,
        selectedValuesCount,
        setSelectedValuesCount,
        irfsAnimationStatus,
        setIrfsAnimationStatus,
        isContextWishList,
        setContextIsWishList,
        globalCity,
        setGlobalCity,
        downloadBrochure,
        setDownloadBrochure,
        brouchureUrl,
        setBrouchureUrl,
        lastVisitedPage, 
        setLastVisitedPage , 
        isCompareLogin, 
        setCompareLogin,
        compareProjectURL, 
        setCompareProjectURL,
        homeBannerSearchOpen, 
        setHomeBannerSearchOpen
      }}
    >
      {children}
    </SearchVisibilityContext.Provider>
  );
};
