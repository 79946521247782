import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Card, FloatingLabel } from 'react-bootstrap';
import { appAbsPath, showToastMessage,buttonLoadingClass, executeLaravelAPI,userToken,uploadAssets,validateName,generateSlug } from '../../Utils';
import SubmitButton from '../../shared/SubmitButton';
import { Icon } from '@iconify/react';


const NewLocationAdd = ({ onCreateFormSubmit }) => {
    const buttonClass = useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [newLocation, setNewLocation] = useState({
        name:'',
        slug: '',
        tagline: '',
        description: '',
        landmarks: '',
        pros: '',
        cons: '',
        region:'',
        city:'',
        meta_title:'',
        meta_description:'',
        canonical_url:'',
        seo_index:'',
        apr:''
    });

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [galleryrows, setGalleryRows] = useState([{ id: 1, file: null }]);
    const [govtdevplanfile, setGovtDevPlanFile] = useState(null);
    const [locationfile, setLocationFile] = useState(null);
    const [prosconsfile, setProsConsFile] = useState(null);

    const [prosrows, setProsRows] = useState([{ id:1, text: null }]);
    const [consrows, setConsRows] = useState([{ id:1, text: null }]);
    const [landmarkrows, setLandmarkRows] = useState([{ id:1, text: null }]);

    const inputActiveRefs = useRef([]);

    useEffect(() => {
        document.title = 'Create New Location';
    }, []);
     

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let slugValue;
        const updatedState = { [name]: value };
        if (name === 'name') {
            slugValue = generateSlug(value);
            updatedState.slug = slugValue;
            updatedState.canonical_url = "/" + slugValue;
        }
        setNewLocation((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
        
        // Validate the input
        let error = validateInput(name, value);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateInput = (name, value) => {
        // Your validation logic here
        let error = '';
        if (['name', 'slug', 'tagline', 'description'].includes(name)) {
            error = validateName(value);
        }

        if(name === 'apr'){
            if(isNaN(value)){
                error = 'APR should be a number';
            }
        }
        // Add other validations as needed...
        return error;
    };

    const handleRegionChange = (event) => {
        const region = event.target.value;
        setSelectedRegion(region);
    };

    const handleCityChange = (event) => {
        const city = event.target.value;
        if (city === '') {
            city = 'pune';
        }
        setSelectedCity(city);
    };

    const handleGalleryRemoveRow = (id) => {
        const updatedGalleryRows = galleryrows.filter(row => row.id !== id);
        const updatedRowsWithNewIds = updatedGalleryRows.map((row, index) => ({
            ...row,
            id: index + 1 // Reassign ids starting from 1
        }));
        setGalleryRows(updatedRowsWithNewIds);
    };

    const handleGalleryAddRow = () => {
        const newId = galleryrows.length + 1;
        const newGalleryRow = { id: newId, file: null };
        setGalleryRows([...galleryrows, newGalleryRow]);
    };

    const handleProsAddRow = () => {
        const newProsRow = { id: prosrows.length + 1, text: null };
        setProsRows([...prosrows, newProsRow]);
    };
    
    const handleProsRemoveRow = (id) => {
        const updatedProsRows = prosrows.filter(row => row.id !== id);
        const updatedProsRowsWithNewIds = updatedProsRows.map((row, index) => ({
            ...row,
            id: index + 1 // Reassign ids starting from 1
        }));

        setProsRows(updatedProsRowsWithNewIds);
    };

    const handleConsAddRow = () => {
        const newConsRow = { id: consrows.length + 1, text: null };
        setConsRows([...consrows, newConsRow]);
    };
    
    const handleConsRemoveRow = (id) => {
        const updatedConsRows = consrows.filter(row => row.id !== id);
        const updatedConsRowsWithNewIds = updatedConsRows.map((row, index) => ({
            ...row,
            id: index + 1 // Reassign ids starting from 1
        }));

        setConsRows(updatedConsRowsWithNewIds);
    };

    const handleLandmarkAddRow = () => {
        const newLandmarkRow = { id: landmarkrows.length + 1, text: null };
        setLandmarkRows([...landmarkrows, newLandmarkRow]);
    };
    
    const handleLandmarkRemoveRow = (id) => {
        const updatedLandmarkRows = landmarkrows.filter(row => row.id !== id);
        const updatedLandmarkRowsWithNewIds = updatedLandmarkRows.map((row, index) => ({
            ...row,
            id: index + 1 // Reassign ids starting from 1
        }));
        setLandmarkRows(updatedLandmarkRowsWithNewIds);
    };

    const handleGovtDevPlanFileChange = (event) => {
        setGovtDevPlanFile(event.target.files[0]);
    };
    const handleLocationFileChange = (event) => {
        setLocationFile(event.target.files[0]);
    };
    const handleProsConsFileChange = (event) => {
        setProsConsFile(event.target.files[0]);
    };

    const handleGalleryFileChange = (id, event) => {
        const file = event.target.files[0];
        const updatedRows = galleryrows.map(row => {
          if (row.id === id) {
            return { ...row, file };
          }
          return row;
        });
        setGalleryRows(updatedRows);
    };

    const handleProsTextChange = (event, index) => {
        const newProsText = event.target.value;
        const updatedProsRows = prosrows.map((row, i) =>
          i === index ? { ...row, text: newProsText } : row
        );
        setProsRows(updatedProsRows);
    };

    const handleConsTextChange = (event, index) => {
        const newConsText = event.target.value;
        const updatedConsRows = consrows.map((row, i) =>
          i === index ? { ...row, text: newConsText } : row
        );
        setConsRows(updatedConsRows);
    };

    const handleLandmarkTextChange = (event, index) => {
        const newlandmarkText = event.target.value;
        const updatedLandmarkRows = landmarkrows.map((row, i) =>
          i === index ? { ...row, text: newlandmarkText } : row
        );
        setLandmarkRows(updatedLandmarkRows);
    };

    const createLocation = async (e) => {
        e.preventDefault();
        try {
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(newLocation.name);
            errors.slug = validateName(newLocation.slug);
            errors.tagline = validateName(newLocation.tagline);
            errors.description = validateName(newLocation.description);

            if (selectedRegion === null) {
                errors.region = 'Please select a region.';
            }
            if (!locationfile) {
                errors.location_image = 'Please select an image file.';
            }
            if (!prosconsfile) {
                errors.pros_cons_image = 'Please select an image file.';
            }

            const hasFile = galleryrows.some(row => row.file !== null);
            if (!hasFile) {
                errors.gallery = 'At least one image is required.';
            }

            const hasPros = prosrows.some(row => row.text !== null);
            if (!hasPros) {
                errors.prostitle = 'At least one pros is required.';
            }
            const hasCons = consrows.some(row => row.text !== null);
            if (!hasCons) {
                errors.constitle = 'At least one cons is required.';
            }
            const hasLandmark = landmarkrows.some(row => row.text !== null);
            if (!hasLandmark) {
                errors.landmarktext = 'At least one landmark is required.';
            }
            
            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }

            setValidationErrors(errors);
            if (hasErrors) return;

            document.getElementsByClassName('theme-btn').disabled = true;
            setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);

            const locationFormData = new FormData();
            if (locationfile) {
                locationFormData.append(`location_image`, locationfile);
            }
            if (prosconsfile) {
                locationFormData.append(`pros_cons_image`, prosconsfile);
            }
            if (govtdevplanfile) {
                locationFormData.append(`govt_development_plan`, govtdevplanfile);
            }
            
            galleryrows.forEach((row, index) => {
                if (row.file) {
                    locationFormData.append(`gallery[${index}]`, row.file);
                    locationFormData.append(`galleryalt[${index}]`, row.alt || 'NA');
                }
            });

            newLocation.city=selectedCity;
            newLocation.seo_index=1;
            newLocation.landmarks=JSON.stringify(landmarkrows);
            newLocation.pros=JSON.stringify(prosrows);
            newLocation.cons=JSON.stringify(consrows);
            newLocation.region=selectedRegion;
            newLocation.apr = parseFloat(newLocation.apr);
            //console.log(newLocation);return false;

            // console.log('Location Data:', newLocation);

            const response = await executeLaravelAPI('add-location', newLocation,'POST',userToken);
            await uploadAssets(`locationassets/${response.data.id}`, locationFormData,userToken);
            if (response.status === 200) {
                showToastMessage('Location created successfully', 'top-right', 'success');
            } else {
                showToastMessage('Location creation failed', 'top-right', 'error');
            }

            setButtonClass(buttonClass.current);
            document.getElementsByClassName('theme-btn').disabled = false;

        } catch (error) {
            console.error('Error creating location:', error);
            showToastMessage('Error creating location', 'top-right', 'error');
        }
        if (onCreateFormSubmit) {
            onCreateFormSubmit(newLocation);
        }
    };

    return (
        <form onSubmit={createLocation} className='locationForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-8 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Add New Location</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Name">
                                                    <Form.Control placeholder="Name" type="text" name='name' value={newLocation['name']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.name}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Slug">
                                                    <Form.Control placeholder="Slug" type="text" name='slug' value={newLocation['slug']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.slug}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.slug}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Tagline">
                                                    <Form.Control placeholder="Tagline" type="text" name='tagline' value={newLocation['tagline']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.tagline}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.tagline}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Apr">
                                                    <Form.Control placeholder="APR" type="number" name='apr' value={newLocation['apr']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.apr}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.apr}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Description">
                                                <Form.Control
                                                    placeholder="Description"
                                                    as="textarea"
                                                    name='description'
                                                    value={newLocation.description}
                                                    onChange={handleInputChange}
                                                    disabled={false}
                                                    readOnly={false}
                                                    rows = {20}
                                                    isInvalid={!!validationErrors.description}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validationErrors.description}
                                                </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Region">
                                                    <select name="region" id="region" className="form-control" onChange={handleRegionChange} isInvalid={!!validationErrors.region}>
                                                        <option value="">--Select Region--</option>
                                                        <option value="East">East</option>
                                                        <option value="West">West</option>
                                                        <option value="South">South</option>
                                                        <option value="North">North</option>
                                                        <option value="Central">Central</option>
                                                    </select>
                                                    {validationErrors.region && (
                                                            <span style={{ color: '#dc3545' }}>{validationErrors.region}</span>
                                                    )}
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="City">
                                                    <select name="city" id="city" className="form-control" onChange={handleCityChange} isInvalid={!!validationErrors.city}>
                                                        <option value="pune">Pune</option>
                                                        <option value="mumbai">Mumbai</option>
                                                    </select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.city}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6 mb-2">
                                    Pros<br/>
                                        <table className='table table-responsive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>
                                        <thead>
                                                <tr className='table-secondary'>
                                                    <td>No</td>
                                                    <td>Pros</td>
                                                    <td><button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={handleProsAddRow}>
                                                        <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                                    </button></td>
                                                </tr>
                                            </thead>
                                            <tbody className='assetstbody'>
                                                {prosrows.map((pros,index) => (
                                                    <tr key={pros.id}>
                                                        <td>{pros.id}</td>
                                                        <td><input type="text" name="prostitle[]" className="form-control" 
                                                            value={pros.text}   
                                                            onChange={(e) => handleProsTextChange(e, index)}
                                                            /></td>
                                                        <td>
                                                            {prosrows.length > 1 && (
                                                            <a href="#remove" rel="noopener noreferrer" onClick={() => handleProsRemoveRow(pros.id)}><Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /></a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {validationErrors.prostitle && (
                                                <tr>
                                                    <td colSpan={5}>
                                                    <p style={{ color: '#dc3545' }}>{validationErrors.prostitle}</p>
                                                   </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-6 mb-2">
                                    Cons<br/>
                                        <table className='table table-responsive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>
                                        <thead>
                                                <tr className='table-secondary'>
                                                    <td>No</td>
                                                    <td>Pros</td>
                                                    <td><button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={handleConsAddRow}>
                                                        <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                                    </button></td>
                                                </tr>
                                            </thead>
                                            <tbody className='assetstbody'>
                                                {consrows.map((cons,index) => (
                                                    <tr key={cons.id}>
                                                        <td>{cons.id}</td>
                                                        <td><input type="text" name="constitle[]" className="form-control"
                                                            value={cons.text}   
                                                            onChange={(e) => handleConsTextChange(e, index)}
                                                        /></td>
                                                        <td>
                                                            {consrows.length > 1 && (
                                                            <a href="#remove" rel="noopener noreferrer" onClick={() => handleConsRemoveRow(cons.id)}><Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /></a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {validationErrors.constitle && (
                                                <tr>
                                                    <td colSpan={5}>
                                                    <p style={{ color: '#dc3545' }}>{validationErrors.constitle}</p>
                                                   </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                    Landmarks<br/>
                                        <table className='table table-responsive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>
                                        <thead>
                                                <tr className='table-secondary'>
                                                    <td>No</td>
                                                    <td>Landmarks</td>
                                                    <td><button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={handleLandmarkAddRow}>
                                                        <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                                    </button></td>
                                                </tr>
                                            </thead>
                                            <tbody className='assetstbody'>
                                                {landmarkrows.map((landmark,index) => (
                                                    <tr key={landmark.id}>
                                                        <td>{landmark.id}</td>
                                                        <td>
                                                        <textarea
                                                            name="landmarktext[]" className="form-control"
                                                            value={landmark.text}   
                                                            onChange={(e) => handleLandmarkTextChange(e, index)}
                                                        /></td>
                                                        <td>
                                                            {landmarkrows.length > 1 && (
                                                            <a href="#remove" rel="noopener noreferrer" onClick={() => handleLandmarkRemoveRow(landmark.id)}><Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /></a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {validationErrors.landmarktext && (
                                                <tr>
                                                    <td colSpan={3}>
                                                    <p style={{ color: '#dc3545' }}>{validationErrors.landmarktext}</p>
                                                   </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr/>
                                    
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Govt. Development Plan">
                                                    <Form.Control type="file" id="govt_development_plan" name="govt_development_plan" className="form-control" accept="pdf/*" onChange={handleGovtDevPlanFileChange} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>    

                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Location Image">
                                                    <Form.Control type="file" id="location_image" name="location_image" className="form-control" accept="image/*" onChange={handleLocationFileChange} isInvalid={!!validationErrors.location_image}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.location_image}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>   
                                    </div> 
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Pros/Cons Image">
                                                    <Form.Control type="file" id="pros_cons_image" name="pros_cons_image" className="form-control" accept="image/*" onChange={handleProsConsFileChange} isInvalid={!!validationErrors.pros_cons_image} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.pros_cons_image}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div> 
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                    Gallery<br/>
                                        <table className='table table-responsive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>
                                        <thead>
                                                <tr className='table-secondary'>
                                                    <td>No</td>
                                                    <td>Image</td>
                                                    <td>Alt Tag</td>
                                                    <td>Preview</td>
                                                    <td><button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={handleGalleryAddRow}>
                                                        <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                                    </button></td>
                                                </tr>
                                            </thead>
                                            <tbody className='assetstbody'>
                                                {galleryrows.map((row) => (
                                                    <tr key={row.id}>
                                                        <td>{row.id}</td>
                                                        <td>
                                                        <input type="file" className="form-control" name="gallery[]" onChange={(event) => handleGalleryFileChange(row.id, event)}/></td>
                                                        <td><input type="text" className="form-control"/></td>
                                                        <td>No Image</td>
                                                        <td>
                                                            {galleryrows.length > 1 && (
                                                            <a href="#remove" rel="noopener noreferrer" onClick={() => handleGalleryRemoveRow(row.id)}><Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /></a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {validationErrors.gallery && (
                                                <tr>
                                                    <td colSpan={5}>
                                                    <p style={{ color: '#dc3545' }}>{validationErrors.gallery}</p>
                                                   </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                                              
                                </div>

                        </Card.Body>
                        <Card.Footer className="text-end text-muted">
                            <NavLink className='theme-btn secondary me-2' to={`${appAbsPath}/portaladmin/locations`} >
                                Cancel
                            </NavLink>
                            <SubmitButton type="submit" className={buttonClassLoader} label="Save" />
                        </Card.Footer>
                    </Card>
                </div>


                <div className="col-md-4 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Add SEO Details</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Meta Title">
                                                    <Form.Control placeholder="Meta Title" type="text" name='meta_title' value={newLocation['meta_title']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                   
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Meta Description">
                                                    <Form.Control placeholder="Meta Description" type="text" name='meta_description' value={newLocation['meta_description']} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Canonical Url">
                                                    <Form.Control placeholder="Canonical Url" type="text" name='canonical_url' value={newLocation['canonical_url']} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-6">
                                                <label>SEO Indexing</label>
                                            </div>
                                            <div className="col-6">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    name="seo_index"
                                                    ref={el => inputActiveRefs.current = el}
                                                    checked={true}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    
                                    
                                </div>
                        </Card.Body>
                        
                    </Card>
                </div>
            </div>
        </form>
    );
};

export default NewLocationAdd;
