import ReactGA4 from "react-ga4";
import { v4 as uuidv4 } from 'uuid';
const getUserId = () => {
  let userId = localStorage.getItem("userId");
  if (!userId) {
    userId = uuidv4(); // Create a unique ID if new user
    localStorage.setItem("userId", userId);
  }
  return userId;
};

// const InitializeGoogleAnalytics = () => {
  
//     const userId = getUserId();
//     ReactGA4.initialize("G-B84KD79XTF");
//     // ReactGA4.set({ user_id: userId });
//     // console.log("GA4 INITIALIZED");
 
// };

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const InitializeGoogleAnalytics = () => {
  if (!GA_TRACKING_ID) {
    console.warn("Google Analytics tracking ID is missing!");
    return;
  }

  const userId = getUserId();
  ReactGA4.initialize(GA_TRACKING_ID);
  // ReactGA4.set({ user_id: userId });
  //console.log("GA4 INITIALIZED with ID:", GA_TRACKING_ID);
};


function convertFirstLastToText(phoneNumber) {
  const numberWords = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];

  // Validate if input is a number (or a numeric string)
  if (!/^\d+$/.test(phoneNumber)) {
    return phoneNumber; // Return as-is if not a valid number
  }

  phoneNumber = phoneNumber.toString(); // Ensure it's a string
  if (phoneNumber.length < 2) return phoneNumber; // Handle edge cases

  let firstDigit = numberWords[parseInt(phoneNumber[0])]; // Convert first digit
  let lastDigit = numberWords[parseInt(phoneNumber[phoneNumber.length - 1])]; // Convert last digit

  // Replace first and last digits with words
  return firstDigit + phoneNumber.slice(1, -1) + lastDigit;
}

const TrackGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label: string
) => {
  // console.log("GA event:", category, ":", action, ":", label);
  // Send UA Event
  //   ReactGA.event({
  //     category: category,
  //     action: action,
  //     label: label,
  //   });
  // Send GA4 Event
  label = convertFirstLastToText(label);
  if (category && action && label) {
    try {
      ReactGA4.event({
        category: category,
        action: action,
        label: label,
      });
    } catch (error) {
      console.error("Failed to send GA event:", error);
    }
  }
};

function setUserSessionSource(source, medium, campaign) {
  // Construct the session-scoped parameters
  const sessionParams = {};

  if (source) {
    sessionParams.session_source = source;
  }
  if (medium) {
    sessionParams.session_medium = medium;
  }
  if (campaign) {
    sessionParams.session_campaign = campaign;
  }

  // Send the event with the session-scoped parameters
  if (Object.keys(sessionParams).length > 0) {
    ReactGA4.event('session_start', sessionParams);
    // console.log("GA4 session source parameters set:", sessionParams); //Optional logging
  } else {
    console.log("No session source parameters provided."); //Optional logging
  }
}



function CompletePurchase(phone, url) {
  // console.log("Inside CompletePurchase - Raw inputs:", phone, url);

  
  ReactGA4.event("purchase", {
    transaction_id: convertFirstLastToText(phone),
    affiliation: "Beyondwalls",
    value: 1,
    tax: 1,
    shipping: 1,
    currency: "INR",
    items: [
      {
        item_id: convertFirstLastToText(phone),
        item_name: convertFirstLastToText(phone),
        price: 1,
        quantity: 1,
        category: "lead_category",
      }
    ]
  });
}

// const sendPageView = () => {
//   // ReactGA4.send({hitType: "pageview", page: window.location.pathname});
//   console.log("GA4 PageView Sent");
// }

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, CompletePurchase , setUserSessionSource};