import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, Polygon, ZoomControl } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { executeLaravelFrontAPI, isMobileDevice } from './../../Admin/Utils';
import _ from 'lodash';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import mappinRed from '../../../images/map-pin-red-1.svg';
import mappinGreen from '../../../images/map-pin-blue.svg';
import mappinGold from '../../../images/map-pin-gold.svg';
import ProjectCard from '../Layouts/ProjectCard';
import NewFilterSidebar from './NewFilterSidebar';
import ProjectMapPopupInfo from './ProjectMapPopupInfo';
import locationPolygonCoords from './LocalityCoordinate/locationPolygons.json'
import GenerateFilterSentence from '../Layouts/GenerateFilterSentence';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { use } from 'react';
import Loader from './../../../Components/Loader';



const worldBounds = [
  [90, -180],  // Top-left corner
  [90, 180],   // Top-right corner
  [-90, 180],  // Bottom-right corner
  [-90, -180], // Bottom-left corner
];


const isMobile = isMobileDevice();
const customIcon = new Icon({
  iconUrl: mappinGreen,
  iconSize: [25, "100%"]
});

const highlightedIcon = new Icon({
  iconUrl: mappinRed,
  iconSize: [25, "100%"]
});

const highlightedIrfsIcon = new Icon({
  iconUrl: mappinGold,
  iconSize: [25, "100%"]
});

const initialDisplayLimit = 20;
const maxZoom = 15;
const minZoom = 10;
const zoomThreshold = 13;
const borderBuffer = 20; // 2px buffer from map edges

const fetchProjectsInBounds = async (sw_lat, sw_lng, ne_lat, ne_lng, locality = null, page = 1, perPage = initialDisplayLimit, params = null,city) => {
  try {

    // console.log("params: ",params)
    // const params = { per_page: perPage, page };
    params.per_page = perPage;
    if (sw_lat && sw_lng && ne_lat && ne_lng) {
      params.sw_lat = sw_lat;
      params.sw_lng = sw_lng;
      params.ne_lat = ne_lat;
      params.ne_lng = ne_lng;
    }
    if (locality) {
      params.searchtype = 'locality_name';
      params.locality_name = locality;
    }

    params.city = city;

    const result = await executeLaravelFrontAPI('projects-map', params, 'GET');
    result.data.data.total = result.data.total;
    return result.data.data || [];
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};


const FitBoundsComponent = ({ coords }) => {
  const map = useMap();

  useEffect(() => {
    if (coords && coords.length > 0) {
      map.fitBounds(coords);
    }
  }, [coords, map]);

  return null;
};

const TruliaMap = () => {
  const { setFooterVisibility,globalCity } = useContext(SearchVisibilityContext);

  // fetch city center lat and long 
  
  let centerLatlong = [];
  if(globalCity == 'pune'){
    centerLatlong = [18.5204, 73.8567];
  }else if(globalCity == 'mumbai'){
    centerLatlong = [19.0760, 72.8777];
  }

  const [center, setCenter] = useState(centerLatlong);
  const [projects, setProjects] = useState([]);
  const [zoom, setZoom] = useState(isMobile ? 2 : 10);
  const [hoveredProject, setHoveredProject] = useState(null);
  const [mouseHover, setMouseHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();
  const listRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [mapContainerHeight, setmapContainerHeight] = useState(0);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [isPdp, setPdp] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [urlChanged, setUrlChanged] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const boundsCoordinates = [
    [85, -180],  // Top-left corner of the world
    [85, 180],   // Top-right corner of the world
    [-85, 180],  // Bottom-right corner of the world
    [-85, -180]  // Bottom-left corner of the world
  ];

  const toggleMapExpansion = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  // locality = "ravet";
  const getProjectLimit = (currentZoom) => {
    // console.log("currentZoom: ", currentZoom);
    return currentZoom > zoomThreshold ? -1 : initialDisplayLimit;
  };

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    if (window.location.pathname.includes('irfs')) {
      queryParams.is_irfs = true;
    }
    return queryParams;

  }

  const filterProjectsNearBorder = (projects, bounds, pixelBounds) => {
    const bufferLat = (bounds.getNorth() - bounds.getSouth()) * (borderBuffer / pixelBounds.getSize().y);
    const bufferLng = (bounds.getEast() - bounds.getWest()) * (borderBuffer / pixelBounds.getSize().x);

    return projects.filter(project => {
      const { latitude, longitude } = project.address;
      return (
        latitude > bounds.getSouth() + bufferLat &&
        latitude < bounds.getNorth() - bufferLat &&
        longitude > bounds.getWest() + bufferLng &&
        longitude < bounds.getEast() - bufferLng
      );
    });
  };

  const updateProjectsInBounds = useCallback(
    _.debounce(async () => {
      const params = getQueryParams();
      if ((params.locality_name === undefined || params.locality_name === null || params.locality_name === '') && mapRef.current) {

        const map = mapRef.current;
        const bounds = map.getBounds();
        const pixelBounds = map.getPixelBounds();
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const currentZoom = map.getZoom();
        const limit = getProjectLimit(currentZoom);
        // console.log("limit: ", limit)
        setLoading(true);
        const newProjects = await fetchProjectsInBounds(sw.lat, sw.lng, ne.lat, ne.lng, null, 1, limit, params, globalCity);
        if (newProjects.length === 0) {
          setNoDataFound(true);
         }
        setTotalItems(newProjects?.total)
        const filteredProjects = filterProjectsNearBorder(newProjects, bounds, pixelBounds);
        setProjects(filteredProjects);
        if (limit === -1) {
          setFooterVisibility(true);
        }
        setLoading(false);
      }
    }, 500),
    [location.search]
  );

  useEffect(() => {
    const calculateHeight = () => {
      const viewportHeight = window.innerHeight;
      const navHeight = document.querySelector('.header')?.offsetHeight || 0;
      const breadcrumbHeight = document.querySelector('.filter-sticky-map1 ')?.offsetHeight || 0;

      // Calculate the height
      setmapContainerHeight(viewportHeight - navHeight - breadcrumbHeight - 25);
    };
    calculateHeight();

    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []); // Empty dependency array ensures this runs once and on resize

  useEffect(() => {
    const initializeMap = async () => {
      const params = getQueryParams();
      if (params.locality_name !== undefined && params.locality_name !== '' && params.locality_name !== null) {
        try {

          // alert(params.locality_name)
          const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${params.locality_name},${globalCity}&format=json&limit=1`);
          const data = await response.json();
          if (data && data.length > 0) {
            const { lat, lon } = data[0];
            const newCenter = [parseFloat(lat), parseFloat(lon)];
            if (params.locality_name.toLowerCase() === 'hadapsar') {
              newCenter[0] = 18.507667988298547;
              newCenter[1] = 73.93718618649491;
            }
            setCenter(newCenter);
            setZoom(isMobile ? 12 : 14);
            console.log("zoom: ", zoom);
            let limit = getProjectLimit(14);
            setLoading(true);
            const newProjects = await fetchProjectsInBounds(null, null, null, null, params.locality_name, 1, limit, params, globalCity);
            setProjects(newProjects);
            setLoading(false);
            if (newProjects.length === 0) {
              setNoDataFound(true);
              // setTotalItems(0);
            } 
            setTotalItems(newProjects?.total)
            mapRef.current.setView([newCenter[0], newCenter[1]], isMobile ? 12 : 14);
            if (limit === -1) {
              setFooterVisibility(true);
            }
          }
        } catch (error) {
          console.error('Error fetching coordinates:', error);
        }

      } else {
        setLoading(true);
        const initialProjects = await fetchProjectsInBounds(null, null, null, null, null, 1, initialDisplayLimit, params, globalCity);
        setProjects(initialProjects);
        if (initialProjects.length === 0) {
          setNoDataFound(true);
          // setTotalItems(0);
        }
        setTotalItems(initialProjects?.total)
        const projectListDiv = document.querySelector('.project-list');
        mapRef.current.setView([center[0], center[1]], isMobile ? 9 : 10);
        setLoading(false);
        if (projectListDiv) {
          projectListDiv.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    };
    if (window.location.pathname.includes('irfs')) {
      setPdp(true);
    }
    initializeMap();


    const initializeMapCroods = () => {
      const params = new URLSearchParams(location.search);
      const localityName = params.get('locality_name');
      if (localityName && localityName.toLowerCase() in locationPolygonCoords) {
        // Set polygon coordinates for the given locality
        const currentPolygonCoords = locationPolygonCoords[localityName.toLowerCase()];
        setPolygonCoords(currentPolygonCoords);

        // // Adjust map view to fit polygon bounds
        // if (currentPolygonCoords.length > 0 && mapRef.current) {
        //   const map = mapRef.current;
        //   map.fitBounds(currentPolygonCoords);
        // }
      } else {
        // Remove the polygon when locality_name is not present
        setPolygonCoords([]);
      }
    };

    initializeMapCroods();

    setUrlChanged(true);

    // Set a timeout to reset the state after 2 seconds
    const timer = setTimeout(() => {
      setUrlChanged(false);
    }, 1000);
    const projectListDiv = document.querySelector('.project-list');
    if (projectListDiv) {
      projectListDiv.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // Clear timeout if the component unmounts or the URL changes again before 2 seconds
    return () => clearTimeout(timer);
  }, [location.search]);

  const loadMoreProjects = async () => {
    const params = getQueryParams();
    if (loading && zoom > zoomThreshold) return;
    if(totalItems && projects.length >= totalItems) {
      setFooterVisibility(true);
      return;
    }
    setLoading(true);
    let newProjects;
    if (params.locality_name !== undefined && params.locality_name !== '' && params.locality_name !== null) {
      newProjects = await fetchProjectsInBounds(null, null, null, null, params.locality_name, 1, projects.length + initialDisplayLimit, params, globalCity);
      if (newProjects.length === 0) {
        setNoDataFound(true);
        // setTotalItems(0);
      } 
      setTotalItems(newProjects?.total)
    } else if (mapRef.current) {
      const map = mapRef.current;
      const bounds = map.getBounds();
      const pixelBounds = map.getPixelBounds();
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      newProjects = await fetchProjectsInBounds(sw.lat, sw.lng, ne.lat, ne.lng, null, 1, projects.length + initialDisplayLimit, params, globalCity);
      let inBoudproject = newProjects;
      newProjects = filterProjectsNearBorder(newProjects, bounds, pixelBounds);
      if (newProjects.length === 0) {
        setNoDataFound(true);
        // setTotalItems(0);
      }
      setTotalItems(inBoudproject?.total)
    }
    if (newProjects.length > projects.length) {
      setProjects(newProjects);
    } else {
      setFooterVisibility(true);
    }
    setLoading(false);
  };

  const handleProjectHover = (project) => {
    setHoveredProject(project);
  };

  const MapEventsHandler = () => {
    const map = useMapEvents({
      moveend: updateProjectsInBounds,
      zoomend: () => {
        if (!map) return;
        const currentZoom = map.getZoom();
        setZoom(currentZoom);
        if (currentZoom > maxZoom) {
          map.setZoom(isMobile ? maxZoom - 2 : maxZoom);
        } else if (currentZoom < minZoom) {
          map.setZoom(isMobile ? minZoom - 2 : minZoom);
        }
        updateProjectsInBounds();
      }
    });
    return null;
  };

  const handleMarkerClick = (slug, isIrfs, e) => {
    // Navigate to the desired URL when the marker is clicked
    // Assuming you want to navigate to a page with the project's ID in the URL
    e.preventDefault();
    isIrfs ? navigate(`/irfs/property/${slug}`) : navigate(`/property/${slug}`);
  };

  const ProjectMarkers = () => {
    // const map = useMap();

    return (
      <>
        {projects.map((project) => (
          <Marker
            key={project.slug}
            position={[parseFloat(project?.address?.latitude), parseFloat(project?.address?.longitude)]}
            icon={project.id === hoveredProject?.id ? (project.is_irfs ? highlightedIrfsIcon : highlightedIcon) : customIcon}
            title={`${project.name.toString()}, ${project.locality_name}`}
            zIndexOffset={project.id === hoveredProject?.id ? 100 : 0}
            eventHandlers={{
              mouseover: () => handleProjectHover(project), // Show ProjectInfo on hover
              // mouseout: () => setHoveredProject(null), // Hide ProjectInfo when not hovering
              onClick: () => handleMarkerClick(project.slug, project.is_irfs),
            }}
          // onClick={handleMarkerClick(project.slug)}
          />
        ))}
      </>
    );
  };


 

  return (
    <Container fluid className={`h-90 mam-project-listing-wrapper ${window.location.pathname.includes('irfs') ? 'irfslist-class' : ''}`}>
      <div className="h-90 position-relative">
        {/* <FilterComponent isPdp={isPdp} appAbsPath={appAbsPath} isMobile={isMobile} /> */}
        <NewFilterSidebar />
        <Row className='flex-column-reverse flex-xl-row'>
          {!isMapExpanded && (
            <Col xl={7} md-order="first" className={`p-3 ps-0 pt-0 overflow-hidden list-container list-container ${!isMapExpanded ? 'list-container-collapsed' : ''}`}>

              {/* <h3 className="mb-4">Project Listings</h3> */}

              <div
                className="project-list hr-scroll-style"
                ref={listRef}
                style={{ height: mapContainerHeight, overflowY: 'auto', overflowX: 'hidden' }}
                onScroll={(e) => {
                  const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

                  if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && zoom <= zoomThreshold) {
                    loadMoreProjects();
                  }
                }}
                onTouchMove={(e) => {
                  const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

                  if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && zoom <= zoomThreshold) {
                    loadMoreProjects();
                  }
                }}
              >
                <div className='col-lg-8 col-12 ps-3 pe-3 ps-lg-0 pe-lg-0 countSentenceWrapper'>
                  {projects ? (
                  
                    projects.length === 0 && loading ? (
                      (noDataFound ? (
                        <></>
                      ) : (
                        <Placeholder animation="wave" >
                          <Placeholder xs={12} className="custom-loading-skeleton" />
                        </Placeholder>
                      ))

                    ) : (
                      <h1 className="mt-2 countSentence">
                        {
                          urlChanged ? (
                            // <span className="text-primary text-orange"></span>
                            <Placeholder animation="wave" >
                              <Placeholder xs={12} className="custom-loading-skeleton" />
                            </Placeholder>
                            // <Spinner animation="grow" />
                            // <Loader />
                          ) : (
                            // totalItems > 0 && (
                            //     <>
                            //         <span className="text-primary text-orange fw-bold">{totalItems} {totalItems === 1 ? "Result" : "Results"} </span>
                            //         <span id="search-count" className="text-black">{generateSentence()} </span>
                            //     </>
                            // )
                            <GenerateFilterSentence totalItems={totalItems} />
                          )
                        }
                      </h1>

                    )
                  ) : (
                    <Placeholder animation="wave" >
                      <Placeholder xs={12} className="custom-loading-skeleton" />
                    </Placeholder>
                  )}
                </div>
                <Row className='top-projects-slider mapView mt-0 '>
                  {
                   totalItems == 0 ? (
                    <div className="noProjectWrapper w-90 m-auto">
                         <p className="text-center" style={{ textWrap: 'balance' }}>
                             {/* <Icon
                                 icon="fa6-regular:face-sad-tear"
                                 className="me-1 mb-1 align-middle"
                                 color="#667486"
                             /> */}
                             Sorry, we couldnt find any projects matching your criteria.
                             <br />
                             {/* Explore similar nearby projects or refine your search for more options. */}
                           
                         </p>
                     </div>
                   ) : (
                  projects.map((project, index) => (

                    <Col md={6} className="p-3 overflow-hidden">
                      <div
                        key={project.id}
                        onMouseEnter={() => {
                          handleProjectHover(project);
                          setMouseHover(true);
                        }}
                        onMouseLeave={() => {
                          setHoveredProject(null);
                          setMouseHover(false);
                        }}
                      >
                        {/* <Card.Body>
                      <Card.Title>{project.name}</Card.Title>
                      <Card.Text>{project.locality_name}</Card.Text>
                    </Card.Body> */}
                        <ProjectCard
                          key={index}
                          project={project}
                          cardType="Map View Projects"
                        // isWishlistActive={isWishlistActive}
                        />
                      </div>
                    </Col>
                  )) )}
                </Row>
                {loading && <h4 className='loaderWrapper mt-3 mb-3'><span className="custom-loader m-auto"></span></h4>}
              </div>
            </Col>
          )}
          <Col xl={isMapExpanded ? 12 : 5} md-order="last" className={`postion-relative map-container ${isMapExpanded ? 'map-container-expanded' : ''
            }`} style={{ flexGrow: isMapExpanded ? 1 : 0 }}>
            <button
              className={`btn mapsliderbtn position-absolute top-2 z-10 transition-all `}
              onClick={toggleMapExpansion}
              style={{ display: isMobile ? 'none' : 'block', zIndex: 1000 }}
            >

              {isMapExpanded ? 'Show List' : 'Expand Map'}
            </button>

            <button
              className={`btn mapslidecount position-absolute top-2 z-10 transition-all `}
              style={{ zIndex: 1000, bottom: '30px', top: 'auto' }}
            >

              {`Showing ${projects.length} of ${totalItems} Properties`}
            </button>

              {loading && <div className="map-container-loader"><h4 className='loaderWrapper'><span className="custom-loader m-auto"></span></h4></div>}


            <MapContainer
              key={`${mapContainerHeight}-${isMapExpanded}-${center.join(',')}`}
              center={center}
              zoom={isMobile ? zoom - 2 : zoom}
              ref={mapRef}
              style={{ height: mapContainerHeight, width: isMapExpanded ? "100%" : "100%" }}
              maxZoom={maxZoom}
              minZoom={minZoom}
              zoomControl={!isMobile ? false : true}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png
              />
              {polygonCoords.length > 0 && (
                <>
                  {/* Render the outside area with a hole */}
                  <Polygon
                    positions={[worldBounds, polygonCoords]}
                    pathOptions={{
                      color: "#192951", // Outside area color
                      fillColor: "#192951d6", // Fill color for the outside area
                      fillOpacity: 0.5,
                      weight: 3,
                      fillRule: "evenodd", // Create a hole for the polygon
                      filterProjectsNearBorder: true,
                    }}
                  />
                  {/* Render the main polygon for transparency */}
                  <Polygon
                    positions={polygonCoords}
                    pathOptions={{
                      color: "#192951", // Border color
                      fillColor: "#192951d6", // Transparent fill
                      fillOpacity: 0,
                      weight: 3,
                      filterProjectsNearBorder: true,
                    }}
                  />
                  <FitBoundsComponent coords={polygonCoords} />
                </>
              )}

              {!isMobile ? <ZoomControl position="bottomright" /> : ''}

              <ProjectMarkers />
              <MapEventsHandler />
              <ProjectMapPopupInfo hoveredProject={hoveredProject}
                setHoveredProject={setHoveredProject} mouseHover={mouseHover} />

            </MapContainer>
          </Col>
        </Row>
      </div>
    </Container >
  );
};

export default TruliaMap;