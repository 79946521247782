import React, { useEffect, useState } from 'react';
// import Loader from '../../Loader';
import { appAbsPath, executeLaravelFrontAPI,formatNumber, getMinMaxPrice, getSortBHKConf, s3url, getMinMaxCarpets,toCovertYear, convertShortDate,parsedUserData, createGa4Event, generateSlug } from '../../Admin/Utils';
// import ReactPaginate from 'react-paginate';
// import { useLocation } from 'react-router-dom';
import 'rc-slider/assets/index.css';
import { Icon } from '@iconify/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultGallery from '../../../images/default-corporate-image.jpg';
import RelatedProjects from './RecomendedProjects'; 

const ComparisonProjectDetails = ({projectslug }) => {
    
    const [projects, setProject] = useState([]);
    useEffect(() => {
        getProjects(projectslug);
    }, [projectslug]);

    const handleNotLogin = (btnClick) => {
        document.getElementById('overlay').style.display = 'block';
        document.getElementById('login_title').textContent = "Want to compare projects? Log in first!";
        createGa4Event("Compare Projects", "Login", "Compare Projects Login");
        
    };
    
    const getProjects = async (slugs) => {
        try {
            // console.log(paramsData);   
            // projectslug = {projects:projectslug}
            const result = await executeLaravelFrontAPI('compareprojects', { projects: slugs }, 'GET');
            setProject(result.data);
            // console.log(result.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    }

    const settings = {
        dots: false,
        arrows: projects.length > 3,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:<Icon icon="ri:arrow-left-s-line"  color="#000" className='prev-arrow' />,
		nextArrow:<Icon icon="ri:arrow-right-s-line"   color="#000" className='next-arrow' />  ,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows:true,
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                infinite: true,
                slidesToShow: 2,
                veriableWidth:true,
                slidesToScroll: 1,
                arrows: projects.length > 2,
              }
            }
          ]        
    };
    
    return (
        <>
            <React.Fragment>
            <main className="comparison pb-3">
                <section className="d-sm-block">
                    <div className="container">
                        <div className="row comparison-left-right">
                            <div className="d-lg-flex flex-column left-details col-3  d-none d-md-block">
                                <div className="d-flex flex-column" style={{ "height": "191px" }}>
                                    {/* <span className="feature-detail">Features</span> */}
                                    <span className="feature-detail my-auto">Property Images</span>
                                </div>
                                <div className="left-desc d-flex flex-column">
                                    <span className="details">Property Name</span>
                                    <span className="details">Price</span>
                                    <span className="details">Avg Price/ Sq.ft</span>
                                    <span className="details">Configuration</span>
                                    <span className="details">Location</span>
                                    <span className="details">Project Size</span>
                                    {/* <span className="details">Number of floors</span> */}
                                    <span className="details">Units</span>
                                    <span className="details">Density</span>
                                    <span className="details">Carpet Area</span>
                                    <span className="details">Amenities</span>
                                    <span className="details">Possession Date</span>
                                    <span className="details">Developer Name</span>
                                    <span className="details">Years of experience</span>
                                </div>
                            </div>
                            <div className={`col-12 col-md-9 position-relative `} >


                                {parsedUserData === null &&
                                    <div className="compare-login">
                                        <div className="compare-login-inner">
                                            <h5 className="text-center">Login to compare</h5>
                                            <button className="btn sc w-100" onClick={() => {handleNotLogin()}} tabindex="0"
                                            >Login</button>
                                        </div>
                                    </div>}
                                <div className={`${parsedUserData !== null ? '' : 'uautnnot'}`}>               
                                    <div className="comparison-slider position-relative">
                                        <Slider {...settings}>
                                        {projects && projects.map((project, index) => (
                                                <div className="comparison-card position-relative" key={index}> 
                                                <div className="right-details">
                                                    <div className="position-relative">
                                                        <div className={project.is_irfs === true ? 'compareirfs' : ''}>
                                                            <div className="irfs-exl">IRFS Exclusive Offer</div>
                                                        </div>
                                                        
                                                        {/* {project.gallery && project.gallery[0]?.url !== undefined && checkFileExtension(project.gallery[0].url) ?
                                                        <img src={`${s3url}/project-assets${project.gallery[0].url}`} className="w-100 comparison-card-img" width="196" height="196" naptha_cursor="text" alt={project.name} />
                                                        :
                                                        <img src={defaultGallery} className="w-100 comparison-card-img" width="196" height="196" naptha_cursor="text" alt={project.name} />
                                                        } */}
                                                        <img src={project.gallery && project.gallery[0]?.url !== undefined ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery} className="w-100 comparison-card-img" width="196" height="196" naptha_cursor="text" alt={project.name} />
                                                        
                                                        <div className="position-absolute green-cross remove-compare-element">
                                                            <img src="/assets/images/green-cross.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className=" right-desc">
                                                        <h6 className="right-heading m-0" title={project.name}>
                                                            {project.name}
                                                        </h6>
                                                        <div className="text-property details">
                                                            <span className="details d-block d-md-none">Price</span>
                                                            <span className="right-detail">₹ 
                                                            {parsedUserData === null ? 'xxxxxxxxxxx' : formatNumber(getMinMaxPrice(project).minPrice)} - ₹ {parsedUserData === null ? 'xxxxxxxxxxx' : formatNumber(getMinMaxPrice(project).maxPrice)}
                                                            </span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Avg Price/ Sq.ft</span>
                                                            <span className="right-detail">₹ 
                                                            {parsedUserData === null ? 'xxxxxxxxxxx' : formatNumber(parseFloat(project.quoted_per_sqft_price).toFixed(0))}
                                                            </span>
                                                        </div>
                                                        
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Configuration</span>
                                                            <span className="right-detail">
                                                                {parsedUserData === null ? 'xxxxxxx' : getSortBHKConf(project)}
                                                            </span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Location</span>
                                                            <span className="right-detail">{parsedUserData === null ? 'xxxxxxxxxxx' : project.address.location}</span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Project Size</span>
                                                            <span className="right-detail">
                                                                {parsedUserData === null ? 'xxxxxxxxxxx' : Number(project.project_size).toFixed(2)} acres
                                                            </span>
                                                        </div>
                                                        {/* <div className="text-property details">
                                                        <span className="details d-block d-md-none">Number of floors</span>
                                                            <span className="right-detail">{project.no_sanctioned_floors}</span>
                                                        </div> */}
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Units</span>
                                                            <span className="right-detail"> {parsedUserData === null ? 'xxxxxxxxxxx' : project.total_units}</span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Density</span>
                                                            <span className="right-detail">
                                                                {parsedUserData === null ? 'xxxxxxxxxxx' : (project.total_units / project.project_size).toFixed(0)}</span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Carpet Area</span>
                                                            <span className="right-detail"> {parsedUserData === null ? 'xxxxxxxxxxx' : (getMinMaxCarpets(project).minCarpet).toFixed(0)} - {parsedUserData === null ? 'xxxxxxxxxxx' : (getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.</span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Amenities</span>
                                                            <div className="d-flex flex-wrap">
                                                                <p className=" m-0 text-property fs-14">{parsedUserData === null ? 'xxxxxxxxxxx' : project.amenities.length}</p>
                                                            </div>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Possession Date</span>
                                                            <span className="right-detail">
                                                                {/* {
                                                                project.possession_date_readable === null || project.possession_date_readable === undefined ? 'NA' :
                                                                    new Date(project.possession_date_readable).toLocaleString('en-US', { month: 'short', year: 'numeric' })
                                                            } */}
                                                            {parsedUserData === null ? 'xxxxxxxxxxx' : convertShortDate(project.possession)}
                                                            </span>
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Developer Name</span>
                                                            {
                                                                project.developer?.name === null || project.developer?.name === undefined ? 'NA' :
                                                                parsedUserData === null ? 'xxxxxxxxxxx' : project.developer?.name
                                                            }
                                                        </div>
                                                        <div className="text-property details">
                                                        <span className="details d-block d-md-none">Years of experience</span>
                                                            {
                                                                project.developer?.established_time_stamp === null || project.developer?.established_time_stamp === undefined ? 'NA' : 
                                                                parsedUserData === null ? 'xxxxxxxxxxx' : toCovertYear(project.developer?.established_time_stamp)
                                                            }
                                                        </div>

                                                        
                                                        <div className="pt-4">
                                                            <a className="btn sc w-100" href={`${appAbsPath}/property/${generateSlug(project.address.city)}/${generateSlug(project.address.location)}/${project.slug}`} 
                                                            onClick={() => {
                                                            createGa4Event(
                                                                'project_comparison', 
                                                                'Comparison', 
                                                                project.name // Using project name instead of slug
                                                            );
                                                            }}
                                                            tabindex="0">VIEW DETAIL</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                            
                                        </Slider>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <div className='row'>
                                      <div className='mt-5'>
                    <RelatedProjects slidesToShow={[3,3,2,2,1]}/>
                </div>  
                    </div>
                </div>
            </main>
            </React.Fragment>
        </>
    )
}
export default ComparisonProjectDetails;