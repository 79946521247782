import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Carousel, Accordion } from 'react-bootstrap';
import { appAbsPath, executeLaravelFrontAPI, s3url, userData, userToken, executeLaravelAPI, showToastMessage, formatNumber, getSortBHKConf, getMinMaxCarpets, toCovertYear, parsedUserData, numberWithCommas, isMobileDevice, convertShortDate, dateDiffInMonths, getMinMaxPrice, createGa4Event, capitalize, checkFileExtension, generateSlug } from './../../Admin/Utils';
// import ProjectDetailsLayout from './ProjectDetailsLayout';
import { Icon } from '@iconify/react';
import Loader from '../../Loader';
import defaultGallery from '../../../images/default-corporate-image.jpg';
// import bwLogo from '../../../Assets/img/bw-logo.svg';
// import scoreBoard from '../../../Assets/img/score-board.png';
import buildImg from '../../../Assets/img/building-img.png';
import wishlistBanner from '../../../Assets/img/wishlist-banner.png';
import rating1 from '../../../Assets/img/rating-1.svg';
import rating2 from '../../../Assets/img/rating-2.svg';
import rating3 from '../../../Assets/img/rating-3.svg';
import rating4 from '../../../Assets/img/rating-4.svg';
import rating5 from '../../../Assets/img/rating-5.svg';
import Vector1 from '../../../Assets/img/vector1.png';
import Discount from '../../../Assets/img/discount.svg';
import Vector2 from '../../../Assets/img/vector2.png';
import ExploreMore from '../../../Assets/img/explore-more.png';
import InterestedImg from '../../../Assets/img/interested.png';
import highlighesImg from '../../../Assets/img/highlighesImg.png';
import highlightsImg from '../../../Assets/img/highlight.png';
import irfscuponlogo from '../../../Assets/img/irfscuponlogo.png';
import RelatedProjects from './RecomendedProjects';
import Gamification from '../Layouts/Gamification';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import MapContainerLeaflet from './MapContainerLeaflet';
import WishListBtn from './WishListBtn';
// import CircularProgress from './CircularProgress';
// import { Link } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import EMICalculator from './EMICalculator';
import ProjectRating from './ProjectRating';
import ProjectRatingSet from './ProjectRatingSet';
import FormEnquiry from './FormEnquiry';
import EsriLeafletMap from './EsriLeafletMapDetailsPage';
// import ScorePopup from './ScorePopup';
import { setUserProjectView, setUserDownloadBrochure } from '../../Admin/UserActivatyTracker';
import { Helmet } from 'react-helmet-async';
import { CompareProjectContext } from '../Layouts/CompareProjectContext';
import ShareButtonWithPopup from './ShareButtonWithPopup';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import ProjectDetailUnitFloorPlan from './ProjectDetailUnitFloorPlan';
import CouponButtons from '../Layouts/CouponButtons';
import ExitIntentComponent from '../Layouts/ExitIntentComponent';
import ProjectReraQrImage from './ProjectReraQrImage';


const isMobile = isMobileDevice();

const ConfigurationDetails = ({ configuration, minCarpet, maxCarpet, minPrice, maxPrice, quoted_per_sqft_price, project }) => {
    const [emiPopup, setEmiPopup] = useState(false);
    const { isProjectId, setProjectId } = useContext(CompareProjectContext);
    const { isDeveloperId, setDeveloperId } = useContext(CompareProjectContext);
    const {  irfsAnimationStatus } = useContext(SearchVisibilityContext)

    const handleEMIClick = () => {
        setEmiPopup(!emiPopup);
    };
    const handleClickEnquiryForm = () => {
        setProjectId(project.id);
        setDeveloperId(project.developer.id);
        document.getElementById('overlayEnquiry').style.display = 'block';
        document.getElementById('enquiry_triggered_from').value = 'Price on Request - Project Detail Page';
    }


    return (
        <><div className="mt-2 ">
            <p className="text-mob-comparision fs-6 plp-carpet-area">Carpet area: <span
                className="text-listing fw-bold">
                {minCarpet.toFixed(0) === maxCarpet.toFixed(0) ?
                    numberWithCommas(minCarpet.toFixed(0))
                    :
                    numberWithCommas(minCarpet.toFixed(0)) + " - " + numberWithCommas(maxCarpet.toFixed(0))
                } <span className='fw-normal'>sq.ft.</span></span>
            </p>
        </div>
            <div className={`tab-pane fade show active`}>
                <div className="plp-price-cards mt-1 parent">
                    <div className="card border-primary text-dark d-none d-sm-block">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0">Avg Price/ Sq.ft
                            <OverlayTrigger delay={{ hide: 150, show: 200 }} overlay={(props) => (
                                <Tooltip {...props} > Avg Price/ Sq.ft</Tooltip>
                            )} placement={isMobile ? "top" : "right"}>
                                <Icon icon="akar-icons:info-fill" className='position-absolute pdpcolor' style={{ "color": "#43A66A", "right": "8px", "top": "6px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary requesttitle" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    // parseInt(minPrice) === 0 ?
                                    // `₹ ${formatNumber((maxPrice / maxCarpet).toFixed(0))}` : `₹ ${formatNumber((minPrice / minCarpet).toFixed(0))}`
                                    //)
                                    project.per_sqft_price != "0" ? (
                                        `₹\u00A0${numberWithCommas(formatNumber(project.per_sqft_price).toFixed(0))}`
                                    ) : (
                                        'NA'
                                    )
                                )}
                            </span>

                        </div>
                    </div>
                    <div className="card border-primary text-dark">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0">Total Price
                            <OverlayTrigger delay={{ hide: 150, show: 200 }} overlay={(props1) => (
                                <Tooltip {...props1}> Total Price = Agreement Price + SDR + GST*.
                                    (*The actual total price may vary depending on other charges)</Tooltip>
                            )} placement={isMobile ? "top" : "right"}>
                                <Icon icon="akar-icons:info-fill" className='position-absolute pdpcolor' style={{ "color": "#43A66A", "right": "8px", "top": "6px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className="card-body bg-white p-2 mobPrice pt-1 pb-1">
                            <span className="card-title fw-bold">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary requesttitle" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    minPrice === maxPrice ? (
                                        <span className='d-flex gap-1'>
                                            <span className='fw-normal visible-mob totPrice'>Total Price: </span>
                                            <span>₹&nbsp;{formatNumber(minPrice)}</span>
                                        </span>
                                    ) : (
                                        <span className='d-flex gap-1'>
                                            <span className='fw-normal visible-mob totPrice'>Total Price: </span>
                                            <span>₹&nbsp;{formatNumber(minPrice)} - ₹&nbsp;{formatNumber(maxPrice)}</span>
                                        </span>
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="card border-primary text-dark position-none d-none d-sm-block">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0  position-relative">EMI Starts At
                            {/* <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props2) => ( 
                            <Tooltip {...props2}> Total Price = Ag Price + SDR + GST*.
                            (*The actual total price may vary depending on other charges)</Tooltip> 
                        )} placement="right">   
                            <Icon icon="akar-icons:info-fill" className='position-absolute'  style={{"color": "#43A66A", "right":"8px", "top":"6px"}} />
                        </OverlayTrigger> */}
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold ">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary requesttitle" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    <><div className='emiCalcPopup1' onClick={() => handleEMIClick()}>
                                        <a href='javascript:;' className='text-decoration-none'>
                                            <EMICalculator loanPrice={maxPrice} showEMI={emiPopup} />
                                        </a>
                                    </div>
                                        {/* <EMICalculator loanPrice={maxPrice} showEMI={emiPopup} /> */}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {irfsAnimationStatus && (
                <div className="mt-2 visible-mob">
                    <Gamification projectDetails={project} />
                </div>
            )}
        </>
    );
};
const ProjectDetails = ({ configuration, loggeduserdetails, handleLoginCoupon, minPrice, maxPrice }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isIRFS, setIRFS] = useState(false);
    const [isSectionOneVisible, setIsSectionOneVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const { projectslug } = useParams();
    const [project, showProjectData] = useState({});
    const [relatedProject, setRelatedProject] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [userdetails, setUserResponseData] = useState(null);
    const [isWishList, isWishlistActive] = useState(false);
    const [projectId, setProjectId] = useState();
    const [developerId, setDeveloperId] = useState();
    const [projectName, setprojectName] = useState();
    const [showAll, setShowAll] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [places, setPlaces] = useState([]);
    const [emiPopup, setEmiPopup] = useState(false);

    const [initialMouse, setInitialMouse] = useState(0);
    const [slideMovementTotal, setSlideMovementTotal] = useState(0);
    const [mouseIsDown, setMouseIsDown] = useState(false);
    const [sliderLeft, setSliderLeft] = useState(-10);
    const [slideTextOpacity, setSlideTextOpacity] = useState(1);
    const [isUnlocked, setIsUnlocked] = useState(false);
    const sliderRef = useRef(null);
    const [rating, setRating] = useState(0);
    const [ratingReview, setRatingReview] = useState(rating);
    const [ratingReviewForm, setRatingReviewForm] = useState(true);
    const [inputValue, setInputValue] = useState('wakad');
    const [reviewText, setReviewText] = useState('');
    const [recommendChecked, setRecommendChecked] = useState(false);
    const [isSubmitedReview, setIsSubmitedReview] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState([]);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isPopupVisible, setPopupVisible] = useState(false);

    const [sortedCombinedData, setSortedCombinedData] = useState([]);
    const [hasReloaded, setHasReloaded] = useState(false);
    const [visible, setVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // const [photoIndex, setPhotoIndex] = useState(0)


    const layoutPlanRef = useRef(null);
    const propertyDetailsRef = useRef(null);
    const highlightsRef = useRef(null);
    const amenitiesRef = useRef(null);
    const specificationRef = useRef(null);
    const locationRef = useRef(null);
    const faqRef = useRef(null);
    const developerRef = useRef(null);
    const projOverviewHeadRef = useRef(null);
    const reraQrImageRef = useRef(null);
    const [activeSection, setActiveSection] = useState(null);
    const [couponDetails, setCouponDetails] = useState({});
    const [coupon, setCoupon] = useState(false);

    const { setDownloadCouponBtn, couponData, setCouponData, downloadCouponBtn, setIrfsProject, showExitIntentPopup, setIsDetailsPage, irfsAnimationStatus, downloadBrochure, setDownloadBrochure, brouchureUrl, setBrouchureUrl, globalCity } = useContext(SearchVisibilityContext)

    useEffect(() => {
        // Set the state to true when this component mounts (user is on the details page)
        setIsDetailsPage(true);

        // Clean up: Set the state to false when the component unmounts (user navigates away)
        return () => setIsDetailsPage(false);
    }, [location.pathname, setIsDetailsPage, navigate]);
    
    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // Scrolling down
            setVisible(false);
        } else {
            // Scrolling up
            setVisible(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const projectOffsetTop = projOverviewHeadRef.current.offsetTop; //stored here as on scroll value is getting
        const handleScroll = () => {
            const yOffset = window.pageYOffset + 80; // Add 100px offset
            // console.log("offset--"+yOffset);
            // console.log("project overview--"+projectOffsetTop);
            const sections = [
                { name: 'layout', ref: layoutPlanRef },
                { name: 'propertyDetails', ref: propertyDetailsRef },
                { name: 'highlights', ref: highlightsRef },
                { name: 'amenities', ref: amenitiesRef },
                { name: 'specifications', ref: specificationRef },
                { name: 'location', ref: locationRef },
                { name: 'developers', ref: developerRef },
                { name: 'reradetails', ref: reraQrImageRef }
                // Add other sections...
            ];
            // console.log("project offset top--"+projectOffsetTop);
            const footer = document.getElementById('footerdiv');
            const projOverviewHead = projOverviewHeadRef.current;
            // const yOffset = window.pageYOffset;
            // const projectOffsetTop = projOverviewHead.offsetTop;

            const footerRect = footer.getBoundingClientRect();
            const isFooterVisible = footerRect.top <= window.innerHeight && footerRect.bottom >= 0;

            if (yOffset > projectOffsetTop && !isFooterVisible) {
                projOverviewHead.classList.add('sticky');
            } else {
                projOverviewHead.classList.remove('sticky');
            }

            for (let section of sections) {
                // console.log(section.ref.current, yOffset)
                if (section.ref.current && yOffset >= section.ref.current.offsetTop - 80) {
                    setActiveSection(section.name);
                }
            }
        };


        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
            if (location.pathname === '/' || location.pathname.includes('/property/')) {
                localStorage.removeItem('selectedValues');
            }
        };
    }, [location.pathname]);
    useEffect(() => {
        // Get the active button
        const activeButton = document.querySelector('.menu-scroll-container .active');
        if (activeButton) {
            // Scroll the container to center the active button
            activeButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    // React.useEffect(() => {
    //     const handleScroll = () => {
    //         const fixedDiv = document.getElementById('menu-sticky');
    //         const footer = document.getElementById('footerdiv');
    //         const yOffset = window.pageYOffset + 80;
    //         if (fixedDiv && footer) {
    //             const footerRect = footer.getBoundingClientRect();
    //             const fixedDivRect = fixedDiv.getBoundingClientRect();

    //             if (window.scrollY > 88 && footerRect.top > window.innerHeight) {
    //                 fixedDiv.classList.add('sticky');
    //                 fixedDiv.style.position = 'fixed';
    //                 fixedDiv.style.bottom = 'initial';
    //             } else if (footerRect.top <= window.innerHeight) {
    //                 fixedDiv.classList.remove('sticky');
    //                 fixedDiv.style.position = 'absolute';
    //                 fixedDiv.style.bottom = '0';
    //                 // fixedDiv.classList.add('col-md-12');
    //             } else {
    //                 fixedDiv.classList.remove('sticky');
    //                 fixedDiv.style.position = 'relative';
    //                 fixedDiv.style.bottom = 'initial';
    //                 // fixedDiv.classList.add('col-md-12');
    //             }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const scrollToSection = (ref) => {
        const yOffset = ref.current.offsetTop - 150; // Subtract 100px from the top
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
    };
    useEffect(() => {
        // Fetch project data when component mounts
        const getProjects = async () => {
            try {
                const result = await executeLaravelFrontAPI(`project/${projectslug}`, '', 'GET');
                const dataProjectDetails = result.data;

                if (result.success == false) {
                    window.location.href = '/404';
                } else {
                    const cityinurl=generateSlug(dataProjectDetails.address.city);
                    const locationinurl=generateSlug(dataProjectDetails.address.location);
                    navigate("/property/"+cityinurl+"/"+locationinurl+"/"+projectslug, { replace: true });
                    setUserProjectView(dataProjectDetails.id);
                    showProjectData(dataProjectDetails); // Set the editedItem state
                    setProjectId(dataProjectDetails.id);
                    setDeveloperId(dataProjectDetails.developer.id);
                    setprojectName(dataProjectDetails.name)
                    isWishlistActive(false);
                    // setPdp(dataProjectDetails.is_irfs);
                    setIRFS(dataProjectDetails.is_irfs);
                    //  console.log('is irfs',dataProjectDetails.is_irfs);
                    setIrfsProject(dataProjectDetails.is_irfs);
                    if (dataProjectDetails && dataProjectDetails?.towers?.length > 0 && dataProjectDetails?.towers[0]?.configurations.length > 0) {
                        setSelectedTab(dataProjectDetails.towers[0].configurations[0].name.replace(/\s/g, ' ').toUpperCase());
                    }
                }

                // set Cookies in userProjectView
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (projectslug) {
            getProjects();

        }

    }, [projectslug]);
    useEffect(() => {
        if (project) {
            const uniqueConfigs = getUniqueConfigurations();
            setSelectedConfig(uniqueConfigs[0]);
        }
    }, [project, selectedTab]);

    useEffect(() => {
        if (loggeduserdetails) {
            if (Object.keys(loggeduserdetails).length === 0) {
                userData().then((result) => {
                    setUserResponseData(result);
                    //getWishListProjects();
                })
            }
        }
        // getRelatedProjects();
    }, []);
    useEffect(() => {
        if (project) {
            setInputValue(project?.address?.location === undefined ? project?.address?.locality_name : project?.address?.location);
            setRatingReviewForm(true)
            if (project.name !== undefined && project.name !== null && project.name !== '') {
                createGa4Event("project_view", "project_view", project.name);
            }
        }
    }, [project]);

    // useEffect to initialize the sortedCombinedData
    useEffect(() => {
        if (project && project.specifications) {
            const sortedData = sortAndCombine(project.specifications);
            setSortedCombinedData(sortedData);
        }
    }, [project && project.specifications]); // Added validation for project and project.specifications


    // console.log(project.id);
    // console.log(project.developer.id);

    const handleConfigClick = (configName) => {
        setSelectedConfig(configName);
    };

    const getMinMaxCarpet = (configurationName) => {
        let minCarpet = Infinity;
        let maxCarpet = -Infinity;

        project.towers.forEach((tower) => {
            tower.configurations.forEach((config) => {
                if (config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === configurationName) {
                    const carpet = parseFloat(config.carpet);
                    minCarpet = Math.min(minCarpet, carpet);
                    maxCarpet = Math.max(maxCarpet, carpet);
                }
            });
        });

        return { minCarpet, maxCarpet };
    };
    const getMinMaxPrices = (configurationName) => {
        let minPrice = Infinity;
        let maxPrice = -Infinity;
        project.towers.forEach((tower) => {
            tower.configurations.forEach((config) => {
                if (config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === configurationName) {
                    const price = parseInt(config.final_price);
                    minPrice = Math.min(minPrice, price);
                    maxPrice = Math.max(maxPrice, price);
                }
            });
        });

        return { minPrice, maxPrice };
    };

    const getUniqueConfigurations = () => {
        const configurationsSet = new Set();
        project?.towers?.forEach((tower) => {
            tower?.configurations?.forEach((config) => {
                const processedName = config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase();
                configurationsSet.add(processedName);
            });
        });
        return Array.from(configurationsSet).sort();
    };
    if (!project) {
        return <Loader />;
    }
    // console.log(JSON.parse(project?.gallery));

    const uniqueConfigs = getUniqueConfigurations();
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Display 3 items at a time
        slidesToScroll: 5
    };

    function fixNumberTwoDecimalFromString(str) {
        const formatToTwoDecimals = match => parseFloat(match).toFixed(2);
        // Replace numbers in the string using the format function
        const updatedStr = str.replace(/-?\d+(\.\d+)?/g, formatToTwoDecimals);
        return updatedStr;// "The cost is 100.26 dollars and 20.79 euros"
    }
    const formatExpiryDate = (dateString) => {
        // Parse the date string into a Date object
        const expiryDate = new Date(dateString);

        // Check if the parsed date is valid
        if (isNaN(expiryDate)) {
            return 'Invalid date';
        }

        // Format the date using toLocaleDateString with the desired format
        return expiryDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    let position = [];
    if (project?.address?.latitude === undefined || project?.address?.longitude === undefined) {
        position = [18.324234234, 73.123123];
    } else {
        position = [project.address.latitude, project.address.longitude];
    }



    const handleMouseDown = (event) => {
        setMouseIsDown(true);
        const clientX = event.clientX || event.touches[0].pageX;
        setInitialMouse(clientX);
        const buttonBackgroundWidth = document.getElementById('button-background').offsetWidth;
        setSlideMovementTotal(buttonBackgroundWidth - sliderRef.current.offsetWidth + 10);
        if (parsedUserData === null) {
            handleNotLogin("Wishlist");
        } else {
            handleShortListedClick(project.id);
        }
    };

    const handleMouseUp = () => {
        if (!mouseIsDown) return;
        setMouseIsDown(false);
        const relativeMouse = sliderLeft + 10;

        if (relativeMouse < slideMovementTotal) {
            setSlideTextOpacity(1);
            setSliderLeft(-10);
            return;
        }

        setIsUnlocked(true);
        if (parsedUserData === null) {
            handleNotLogin("Wishlist");
        } else {
            handleShortListedClick(project.id);
        }

        sliderRef.current.removeEventListener('click', handleSliderClick);
        setTimeout(() => {
            sliderRef.current.addEventListener('click', handleSliderClick);
        }, 0);
    };

    const handleMouseMove = (event) => {
        if (!mouseIsDown) return;
        const currentMouse = event.clientX || event.touches[0].pageX;
        const relativeMouse = currentMouse - initialMouse;
        const slidePercent = 1 - (relativeMouse / slideMovementTotal);

        setSlideTextOpacity(slidePercent);

        if (relativeMouse <= 0) {
            setSliderLeft(-10);
            return;
        }
        if (relativeMouse >= slideMovementTotal) {
            setSliderLeft(slideMovementTotal);
            return;
        }
        setSliderLeft(relativeMouse - 10);
    };

    const handleSliderClick = () => {
        if (!isUnlocked) return;
        setIsUnlocked(false);
        sliderRef.current.removeEventListener('click', handleSliderClick);
    };

    const handleShortListedClick = async (projectId) => {
        try {
            const result = await executeLaravelAPI(`add-wishlist?wishlistid=${projectId}`, '', 'POST', userToken);
            if (result.status === 200) {
                showToastMessage('Shorlisted Project has been added successfully', 'top-right', 'success');
                if (!isActive) {
                    setIsActive(true);
                }
                isWishlistActive(true);
            } else {
                showToastMessage('Shorlisted Project has not been added.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };

    // const getRelatedProjects = async () => {
    //     const paramsData = {};
    //     // console.log(inputValue)
    //     paramsData.locality_name = inputValue;
    //     paramsData.searchtype = "locality_name";
    //     paramsData.per_page = 10;

    //     // console.log(paramsData);
    //     let result = {};
    //     try {
    //         result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
    //         setRelatedProject(result.data.data);

    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //     }
    // }
    // const settingsForRelatedProject = {
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 800,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
    //     nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 arrows: true,
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }
    //     ]
    // };
    const handleNotLogin = (btnClick, project = '', offer_id = '') => {

        document.getElementById('overlay').style.display = 'block';
        if (btnClick === "Download Brochure") {
            document.getElementById('login_title').textContent = "Your brochure is ready, login to download";
            setDownloadCouponBtn(false);
            setDownloadBrochure(true);
            setBrouchureUrl(brochureURL);
        }
        // if (btnClick === "Download Coupon") {
        //     document.getElementById('login_title').textContent = "Your Coupon is ready, login to download";
        //     setDownloadCouponBtn(true);
        //     setCouponData({ project_id: project.id , offer_id: parseInt(offer_id) });

        //     // document.getElementById('couponProjectid').value = project.id;
        //     // document.getElementById('couponOfferid').val = project?.offers && project.offers.length > 0 ? project.offers.map(offer => offer.id).join(',') : '';
        // }
        if (btnClick === "Last Transacations") {
            document.getElementById('login_title').textContent = "Stay updated with the latest transactions";
            setDownloadCouponBtn(false);
        }
        if (btnClick === "Wishlist") {
            document.getElementById('login_title').textContent = "Add this project to your wishlist";
            setDownloadCouponBtn(false);
        }
    }


    const handlerelatedProj = () => {
        window.scrollTo(0, 0);
    }
    const headleSubmitReview = () => {
        setRatingReview(rating);
        setRatingReviewForm(false)
    }

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setSelectedParameters([...selectedParameters, id]);
        } else {
            setSelectedParameters(selectedParameters.filter(param => param !== id));
        }
    };

    const handleReviewTextChange = (e) => {
        setReviewText(e.target.value);
    };
    const handleRecommendChange = (e) => {
        setRecommendChecked(e.target.checked);
    };
    const handleSubmitReview = async () => {
        // console.log(loggeduserdetails);
        // console.log(loggeduserdetails.id);
        const formData = {
            project_id: project.id,
            user_id: loggeduserdetails.id,
            rating: rating,
            parameter: selectedParameters.join(),
            review: reviewText,
            recommend: recommendChecked === true ? 1 : 0
        };
        // console.log(formData);
        try {
            const result = await executeLaravelAPI(`projectrating`, formData, 'POST', userToken);

            if (result.status === 200 && result.success === true) {
                showToastMessage('Project Review has been added successfully', 'top-right', 'success');
                if (!isSubmitedReview) {
                    setIsSubmitedReview(true);
                }
            }

            else if (result.status === 200 && result.success === false) {
                showToastMessage('Project Review has already been added', 'top-right', 'error');
            }
            else {
                showToastMessage('Project Review has not been added.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    }
    const brochureURL = project.brochure && project.brochure.length > 0 ? project.brochure[0].url : '';
    // const images = project?.gallery?.map(image => `${s3url}/project-assets${image.url}`) ?? [];

    const images = Array.isArray(project?.gallery) ? project.gallery.map(image => `${s3url}/project-assets${image.url}`) : [];



    const openLightbox = (index) => {
        setTimeout(() => {
            setPhotoIndex(index);
            setLightboxOpen(true);
        }, 10);
    };

    // console.log(project.specifications);

    // Function to sort and combine data
    const sortAndCombine = (data) => {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        const combinedData = {};

        data.forEach((item) => {
            const { specification_type, description } = item;
            if (combinedData[specification_type]) {
                combinedData[specification_type].description += `\n${description}`;
            } else {
                combinedData[specification_type] = { ...item };
            }
        });

        const result = Object.values(combinedData);
        result.sort((a, b) => a.specification_type.localeCompare(b.specification_type));

        return result;
    };

    const handleToggle = () => {
        setIsSectionOneVisible(!isSectionOneVisible);
    };
    const handleButtonClick = () => {
        setPopupVisible(true); // Show popup when button is clicked
    };
    //     const handleRedeemCoupon = async () => {
    //         // /add-couponcode

    //         try {

    //             const couponrequiredData = { offer_id: couponData.offer_id, project_id:  couponData.project_id};

    //             // console.log(couponrequiredData);
    //             const couponDetails = await executeLaravelAPI('add-couponcode', couponrequiredData, 'POST', localStorage.getItem('bwToken'));

    //             if (couponDetails.status === 200) {
    //                 showToastMessage('Coupon Redeemed', 'top-right', 'success');
    //                 // setThankYou(true); // Show thank you step
    //                 setCoupon(true);
    //                 setCouponDetails(couponDetails.data);
    //                 setDownloadCouponBtn(false);
    //             }

    //         }
    //         catch (error) {
    //             console.error('Error redeeming coupon:', error);
    //         }

    // }

    const downloadCoupon = () => {
        try {
            // download coupone html as img
            console.log('download coupon');
            const couponData = document.querySelector('.coupon-wrapper');

            const canvas = document.createElement('canvas');
            canvas.width = couponData.offsetWidth;
            canvas.height = couponData.offsetHeight;
            const ctx = canvas.getContext('2d');
            const img = new Image();

            img.onload = function () {
                console.log('Image loaded successfully');
                ctx.drawImage(img, 0, 0);
                const a = document.createElement('a');
                a.href = canvas.toDataURL('image/png');
                a.download = 'coupon.png';
                a.click();
            };

            img.onerror = function (error) {
                console.error('Error loading image:', error);
            };

            try {
                const svgString = new XMLSerializer().serializeToString(couponData);
                const base64Data = btoa(svgString);
                img.src = 'data:image/svg+xml;base64,' + base64Data;
                console.log('Image source set:', img.src);
            } catch (error) {
                console.error('Error serializing SVG:', error);
            }
            console.log(img)
            setDownloadCouponBtn(false);

            setTimeout(() => {
                window.location.href = `${appAbsPath + window.location.href}`;
            }, 3000);
        }
        catch (error) {
        }
    }

    const handleClosePopup = () => {
        setPopupVisible(false); // Hide popup when close button is clicked
    };
    const handleClickEnquiryForm = () => {
        setProjectId(project.id);
        setDeveloperId(project.developer.id);
        document.getElementById('overlayEnquiry').style.display = 'block';
        document.getElementById('enquiry_triggered_from').value = 'Price on Request - Project Detail Page';
    }





    return (
        <>
            <Helmet>
                <title>{project.name ? project.name : ''} in {project?.address?.location ? project.address.location : ''}, {project?.address?.city ? capitalize(project.address.city) : ''} : Reviews, Prices & Floor Plans - BeyondWalls</title>
                <meta name="description" content={project.name ? `${project.name} by ${project.developer?.name ? project.developer.name : ''} offers ${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}Flats for sale in ${project?.address?.location ? project.address.location : ''}, ${project?.address?.city ? capitalize(project.address.city) : ''}. To check price, floor plan, master plan, amenities, agreement Value, photos, visit BeyondWalls!` : ''} />
                <link rel="canonical" href={`https://www.beyondwalls.com/property/${project.slug ? project.slug : ''}`} />

                {/* Open Graph */}
                <meta property="og:title" content={`${project.name ? project.name : ''} in ${project?.address?.location ? project.address.location : ''},  ${project?.address?.city ? capitalize(project.address.city) : ''} : Reviews, Prices & Floor Plans - BeyondWalls`} />
                <meta property="og:description" content={project.name ? `${project.name} by ${project.developer?.name ? project.developer.name : ''} offers ${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}Flats for sale in ${project?.address?.location ? project.address.location : ''}, ${project?.address?.city ? capitalize(project.address.city) : ''}. To check price, floor plan, master plan, amenities, agreement Value, photos, visit BeyondWalls!` : ''} />
                <meta property="og:image" content={project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? `${s3url}/project-assets${project.gallery[0].url}` : ''} />
                <meta property="og:url" content={`https://www.beyondwalls.com/property/${project.slug ? project.slug : ''}`} />
                <meta property="og:image:width" content="1200" />  {/* Optional: Specify the width of the image */}
                <meta property="og:image:height" content="630" />  {/* Optional: Specify the height of the image */}
                <meta property="og:image:alt" content={`${project.name ? project.name : ''} in ${project?.address?.location ? project.address.location : ''},  ${project?.address?.city ? capitalize(project.address.city) : ''} : Reviews, Prices & Floor Plans - BeyondWalls`} />

                {/* twitter */}
                <meta name="twitter:card" content={project.name ? `${project.name} by ${project.developer?.name ? project.developer.name : ''} offers ${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}Flats for sale in ${project?.address?.location ? project.address.location : ''}, ${project?.address?.city ? capitalize(project.address.city) : ''}. To check price, floor plan, master plan, amenities, agreement Value, photos, visit BeyondWalls!` : ''} />
                <meta name="twitter:site" content="@BeyondWallsCo" />
                <meta name="twitter:title" content={`${project.name ? project.name : ''} in ${project?.address?.location ? project.address.location : ''},  ${project?.address?.city ? capitalize(project.address.city) : ''} : Reviews, Prices & Floor Plans - BeyondWalls`} />
                <meta name="twitter:description" content={project.name ? `${project.name} by ${project.developer?.name ? project.developer.name : ''} offers ${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}Flats for sale in ${project?.address?.location ? project.address.location : ''}, ${project?.address?.city ? capitalize(project.address.city) : ''}. To check price, floor plan, master plan, amenities, agreement Value, photos, visit BeyondWalls!` : ''} />
                <meta name="twitter:image" content={project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? `${s3url}/project-assets${project.gallery[0].url}` : ''} />


            </Helmet>
            <div className='d-block d-sm-none'>
                {isIRFS === true && irfsAnimationStatus ===true && (
                    <div className={`dflex irfsactive irfsactive-mob ${visible ? 'slide-up' : 'slide-down'}`}>
                        <div className='secondcupon'>
                            <CouponButtons
                                project={project}
                                parsedUserData={parsedUserData}
                                handleLoginCoupon={handleLoginCoupon}
                                handleNotLogin={handleNotLogin}
                                buttonText="Download Coupon"
                            />
                        </div>
                        {Array.isArray(project.offers) && project.offers.length > 0 && (
                            <div className='bgorange discimgoffer'>
                                <img src={Discount} className="img-fluid me-2 discimg spin" />
                                <Carousel data-bs-theme="light" controls={false} indicators={false}>
                                    {Array.isArray(project.offers) && project.offers.map((offer, offersIndex) => (
                                        <Carousel.Item key={offersIndex}>
                                            <p className='irfsdisc d-none d-sm-block'>Offer: <span>{offer.name}</span></p>
                                            {/* <p className='offerdesc d-block d-sm-none'>Offer:</p> */}
                                            <p className='irfsdisc d-block d-sm-none'>{offer.name}</p>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        )}

                        {/* <div>
                                <a className='lmore' onClick={handleToggle}>IRFS Offers</a>
                            </div> */}
                    </div>
                )}
            </div>
            <div className={isIRFS == true && irfsAnimationStatus ? 'pdp-class' : ''}>

                {/* {console.log("isIRFS", isIRFS)} */}
                <div className='d-block d-sm-none'>



                </div>

                <main className="project-detail-main pdp-main pb-5 pt-5 mt-5">
                    <section className="project-main pt-3">
                        <div className="px-0 px-md-5 px-sm-5">
                            <div className={isSectionOneVisible ? 'dnone' : 'tabcontentproject'}>
                                <div className="tabcontent rounded-8 plp-card mb-3 float-start float-md-none col-12 insideCardWrapper">
                                    <div className="d-flex flex-lg-row flex-column rounded pe-md-0 gap-md-4 gap-1 plp-card-main pdp-details-card insideCard">
                                        <div className={`image-side position-relative flex-grow-1 ${project.gallery && project.gallery.length <= 3 ? 'full-img' : ''}`}>
                                            {project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                                                project.gallery.length === 1 ? (
                                                    <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${s3url}/project-assets${project.gallery[0].url})` }}></div>
                                                ) : (
                                                    <Carousel data-bs-theme="light">
                                                        {project.gallery.map((image, index) => (
                                                            checkFileExtension(image.url) && (
                                                                <Carousel.Item key={index}>
                                                                    {/* <div className='lazyload-placeholder project-img' 
                                                                style={{ backgroundImage: `url(${s3url}/project-assets${image.url})` }}
                                                                ></div> */}
                                                                    <img src={`${s3url}/project-assets${image.url}`} />
                                                                </Carousel.Item>
                                                            )
                                                        ))}
                                                    </Carousel>
                                                )
                                            ) : (
                                                <>
                                                    <div className='lazyload-placeholder project-img' style={{ paddingTop: '100%', backgroundImage: `url(${defaultGallery})` }}></div>
                                                </>
                                            )}
                                            {project.is_soldout === false && (
                                            <WishListBtn projectId={project.id} isWishlistActive={isWishlistActive} loggeduserdetails={parsedUserData} color={"#fff"} width={"30px"} height={"30px"} handleNotLogin projectBrochure = {project.brochure && project.brochure.length > 0 ? project.brochure[0].url : ''} projectName={project.name}/>
                                            )}
                                            {/* {console.log("Not Login", isLogin)} */}
                                            <div className="rera-main-div">
                                                {project.possession && (
                                                    <p className='text-decoration-underline pos-date'><span>Possession by:</span> {convertShortDate(project.possession)}</p>
                                                )}
                                                <div className='list-rerabox'>
                                                    <p className="rera-txt">
                                                        {/*<Icon icon="material-symbols:check" color={"#fff"} />*/}
                                                        RERA Certified
                                                    </p>
                                                    <div className="zindex-10 top-picks">
                                                        <div className="d-flex flex-column text-center">
                                                            <span className="fw-bold"><Icon icon="mingcute:star-fill" className='mb-1' color={"#ffce31"} />
                                                                {/* {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status} */}
                                                                {project.project_status ? project.project_status : 'Under Construction'}
                                                            </span>
                                                            {/* <span className="ready-since text-white">(Since Jun, 2021)</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="position-absolute zindex-10 end-0 m-3 top-picks">
                                            <div className="d-flex flex-column text-center">
                                                <span className="fw-bold"><Icon icon="mingcute:star-fill" className='mb-1' color={"#ffce31"} /> {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status}</span>
                                                <span className="ready-since text-white">(Since Jun, 2021)</span> 
                                            </div>
                                        </div>
                                        {/* {project?.scoreranking && project.scoreranking !== undefined && 
                                        <div className='position-absolute zindex-10 rank-wrap pt-2 pdp-rank-wrap d-flex'>
                                            <span className='circle-1'>
                                            <span className='circle-2'>
                                                <span className='circle-3'>
                                                    <span className='rank-number'>#{project?.scoreranking}</span>
                                                </span>
                                            </span>
                                            </span>
                                            <span className='rank-in-locality'> in {project?.address.location}</span>
                                        </div>
                                        } */}
                                        </div>
                                        {project?.gallery && project?.gallery?.length >= 4 && (
                                            <div className='extraGalleryImg d-flex flex-column align-items-center justify-content-center'>
                                                {window.innerWidth > 640 ? (
                                                    <>
                                                        <img
                                                            src={`${s3url}/project-assets${project?.gallery[1]?.url}`}
                                                            onClick={() => openLightbox(1)}
                                                            className="img-fluid"
                                                            alt={project.name}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <img
                                                            src={`${s3url}/project-assets${project?.gallery[2]?.url}`}
                                                            onClick={() => openLightbox(2)}
                                                            className="img-fluid"
                                                            alt={project.name}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <div
                                                            className='position-relative total-img'
                                                            onClick={() => openLightbox(3)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <img
                                                                src={`${s3url}/project-assets${project?.gallery[3]?.url}`}
                                                                className="img-fluid"
                                                                alt={project.name}
                                                            />
                                                            <span>+{project.gallery.length} more</span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img
                                                            src={`${s3url}/project-assets${project?.gallery[1]?.url}`}
                                                            onClick={() => openLightbox(1)}
                                                            className="img-fluid"
                                                            alt={project.name}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <img
                                                            src={`${s3url}/project-assets${project?.gallery[2]?.url}`}
                                                            onClick={() => openLightbox(2)}
                                                            className="img-fluid"
                                                            alt={project.name}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <img
                                                            src={`${s3url}/project-assets${project?.gallery[3]?.url}`}
                                                            onClick={() => openLightbox(3)}
                                                            className="img-fluid"
                                                            alt={project.name}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <div
                                                            className='position-relative total-img'
                                                            onClick={() => openLightbox(4)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <img
                                                                src={`${s3url}/project-assets${project?.gallery[4]?.url}`}
                                                                className="img-fluid"
                                                                alt={project.name}
                                                            />
                                                            <span>+{project.gallery.length} more</span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {lightboxOpen && (
                                            <Lightbox
                                                mainSrc={images[photoIndex]}
                                                nextSrc={images[(photoIndex + 1) % images.length]}
                                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                onCloseRequest={() => setLightboxOpen(false)}
                                                onMovePrevRequest={() =>
                                                    setPhotoIndex((photoIndex + images.length - 1) % images.length)
                                                }
                                                onMoveNextRequest={() =>
                                                    setPhotoIndex((photoIndex + 1) % images.length)
                                                }
                                                toolbarButtons={[
                                                    <div key="index-info" className='start-0 top-0 ps-3 position-absolute'>
                                                        {photoIndex + 1} / {images.length}
                                                    </div>,
                                                ]}
                                            />
                                        )}
                                        <div className={`position-relative me-2 ms-0 mb-sm-3 mb-md-0 ms-md-0 project-right-content mt-sm-3 mt-md-0 flex-fill me-md-4 col-12 ${project.is_soldout ? 'soldout' : ''}`}>
                                            {/* {console.log(project.is_soldout)} */}
                                            <div className="position-relative">
                                                {/* Compare the project */}
                                                {/* <div className="compare-btn text-end">
                                                <button className="btn btn-theme bg-theme btn-sm"
                                                // onClick={() => handleCompare(project)}
                                                >
                                                    Compare
                                                </button>
                                            </div> */}
                                                <div className="pe-2 position-absolute top-0 end-0"><ShareButtonWithPopup projectName={project.name} projecturl={`${window.location.href}`} /></div>
                                                <h1 className="plpcard-name noto-sans innerTitle">{project.name}</h1>
                                                <p className='mb-0 developer-name text-theme'>By <span>{project.developer?.name}</span></p>
                                                <div className='prjt-location border-top py-2 mt-2 d-block d-sm-none'>
                                                    <div className=''>
                                                        <Icon icon="ri:map-pin-fill" className='me-2 mb-1' color='#667486' height={"13px"} />
                                                        <span className='col-9 noto-sans project-location'>
                                                            {
                                                                [
                                                                    project.address?.address1,
                                                                    project.address?.address2,
                                                                    project?.address?.location,
                                                                    project.address?.city ? capitalize(project.address?.city) : '',
                                                                    project?.address?.state ? capitalize(project.address?.state) : '',
                                                                    project.address?.zip
                                                                ]
                                                                    .filter(Boolean)
                                                                    .join(', ')
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-inline bhk-tab nav fs-6">
                                                    {uniqueConfigs.map((configName, index) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            className={`nav-link d-inline unitConfig p-0 bhk-color ps-2 pe-2 mt-2 me-2 ${(!selectedConfig && index === 0) || selectedConfig === configName ? 'active' : ''}`}
                                                            onClick={() => [handleConfigClick(configName), createGa4Event("Click", "Project Detail", `${project.name} - ${configName}`)]}>
                                                            {configName}
                                                        </button>
                                                    ))}
                                                </div>
                                                <div className='prjt-location border-top py-2 mt-2 d-none d-sm-block'>
                                                    <div className=''>
                                                        <Icon icon="ri:map-pin-fill" className='me-2 mb-1' color='#667486' height={"13px"} />
                                                        <span className='col-9 noto-sans project-location'>
                                                            {
                                                                [
                                                                    project.address?.address1,
                                                                    project.address?.address2,
                                                                    project?.address?.location,
                                                                    project.address?.city ? capitalize(project.address?.city) : '',
                                                                    project?.address?.state ? capitalize(project.address?.state) : '',
                                                                    project.address?.zip
                                                                ]
                                                                    .filter(Boolean)
                                                                    .join(', ')
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="plp-card-detail border-bottom col-12 col-md-12 border-top border-1 pt-2">
                                                <div className="d-flex flex-wrap amenities-gap pdl-amenities align-content-center">
                                                    {Array.isArray(project.amenities) && project.amenities.length > 0 ? (

                                                        (showAll ? project.amenities : project.amenities).map((amenity, index) => (
                                                            <> {amenity.is_featured &&
                                                                <div key={index} className="plp-amenties-tag" title={amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}>
                                                                    <p className="m-0 text-property text-truncate fs-14">{amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}</p>
                                                                </div>}
                                                            </>
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {project?.amenities?.length > 5 && project?.amenities?.some(amenity => amenity.is_featured) && (
                                                        <p className="text-property moreno text-truncate fs-14">+{(project?.amenities?.length ?? 0) - 3}</p>
                                                    )}
                                                </div>
                                                <div className="tab-content col-12">
                                                    {selectedConfig &&
                                                        <div>
                                                            {/* Log selectedConfig */}
                                                            {/* {console.log("Selected Config:", selectedConfig)} */}
                                                            {project.towers.map((tower, towerIndex) => {
                                                                // Log tower configurations
                                                                // console.log("Tower Configurations:", tower.configurations);
                                                                const matchingConfig = tower.configurations.find(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig);
                                                                // console.log("Matching Configuration:", matchingConfig);
                                                                if (matchingConfig) {
                                                                    const { minCarpet, maxCarpet } = getMinMaxCarpet(selectedConfig);
                                                                    const { minPrice, maxPrice } = getMinMaxPrices(selectedConfig);
                                                                    // Check if this is the first occurrence of the configuration
                                                                    const isFirstOccurrence = project.towers
                                                                        .slice(0, towerIndex)
                                                                        .every(otherTower => !otherTower.configurations.some(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig));
                                                                    if (isFirstOccurrence) {
                                                                        return (
                                                                            <ConfigurationDetails
                                                                                key={towerIndex}
                                                                                configuration={matchingConfig}
                                                                                minCarpet={minCarpet}
                                                                                maxCarpet={maxCarpet}
                                                                                minPrice={minPrice}
                                                                                maxPrice={maxPrice}
                                                                                project={project}
                                                                                quoted_per_sqft_price={project.quoted_per_sqft_price}
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                            {project.is_soldout === false && (
                                            <WishListBtn iconType="button" projectId={project.id} isWishlistActives={isWishlistActive} loggeduserdetails={parsedUserData} color={"#fff"} handleNotLogin projectBrochure = {project.brochure && project.brochure.length > 0 ? project.brochure[0].url : ''} projectName={project.name}/>
                                            )}
                                            <div className="col-12 pe-0 pt-1">
                                                {/* <div className='bw-score'>
                                                {project?.project_score && project.project_score[0]?.total_score >= 1 &&
                                                    <p className='mb-0'>BW Score <CircularProgress percentage={project.project_score[0]?.total_score} /></p>
                                                }
                                            </div> */}
                                                <div className='d-none d-sm-block'>
                                                    <div className='irfsactive'>
                                                        <div className='dflex'>
                                                            {project.offers && project.offers.length > 0 && (
                                                                <div className='bgorange position-relative discdivactive'>
                                                                    <img src={Discount} className="img-fluid me-2 discimg spin" />
                                                                    <Carousel data-bs-theme="light" controls={false} indicators={false}>
                                                                        {project.offers.map((offer, offersIndex) => (
                                                                            <Carousel.Item key={offersIndex}>
                                                                                <p className='irfsdisc d-none d-sm-block'>Offer: <span>{offer.name}</span></p>
                                                                                {/* <p className='offerdesc d-block d-sm-none'>Offer:</p> */}
                                                                                <p className='irfsdisc d-block d-sm-none'>{offer.name}</p>
                                                                            </Carousel.Item>
                                                                        ))}
                                                                    </Carousel>
                                                                </div>
                                                            )}
                                                            {/* <div className='bgorange position-relative discdivactive'>
                                                        <img src={Discount} className="img-fluid me-2 discimg d-none d-sm-block"/>
                                                        <Carousel data-bs-theme="light" controls={false} indicators={false}>
                                                            {project.offers && project.offers.map((offer, offersIndex) => (
                                                                <Carousel.Item>
                                                                    <p className='irfsdisc d-none d-sm-block'><span>{offer.name}</span></p>
                                                                    <p className='offerdesc d-block d-sm-none'>Offer:</p>
                                                                    <p className='irfsdisc d-block d-sm-none'>{offer.name}</p>
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>
                                                    </div> */}
                                                            <div className='secondcupon'>
                                                                <CouponButtons
                                                                    project={project}
                                                                    parsedUserData={parsedUserData}
                                                                    handleLoginCoupon={handleLoginCoupon}
                                                                    handleNotLogin={handleNotLogin}
                                                                    buttonText="Download Free Coupon"
                                                                />
                                                            </div>
                                                            {/* <div>
                                                        <a className='lmore' onClick={handleToggle}>IRFS Offers</a>
                                                    </div> */}
                                                        </div>
                                                        <Gamification projectDetails={project} />
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isPopupVisible && parsedUserData && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: '11111111',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '20px',
                                            borderRadius: '10px',
                                            position: 'relative',
                                            height: '70%',
                                            width: '70%',
                                        }}
                                    >
                                        <h2>Popup Content</h2>
                                        <p>This is your popup content.</p>
                                        <div onClick={handleClosePopup} className='closebtn'>
                                            X
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={isSectionOneVisible ? 'tabcontentproject' : 'tabcontentirfs px-3 mt-2'}>
                                <div className="tabcontent rounded-8 plp-card mb-3 float-start float-md-none col-12">

                                    <img src={Vector2} className='img-fluid vector2' />
                                    <div className="plp-card-main testtttt pdp-details-card">
                                        <div className="position-relative ms-0 mb-3 mb-md-0 ms-md-0 project-right-content mt-sm-3 mt-md-0">
                                            <img src={Vector1} className='img-fluid vector1' />
                                            <div className="position-relative">
                                                <h1 className="plpcard-name noto-sans">{project.name}</h1>
                                                <div className='configflex'>
                                                    <div className="d-flex align-items-center">
                                                        {/* {uniqueConfigs && uniqueConfigs.length > 0 &&
                                                            <>
                                                                {uniqueConfigs.map((configName, index) => (
                                                                    <p className='mb-1 bhkp'>
                                                                        {configName.replace('BHK', '').trim()}
                                                                        {index < uniqueConfigs.length - 1 ? ', ' : ''}
                                                                    </p>
                                                                ))}

                                                                <p className='bhkp ms-2 mb-1'>BHK Apartments</p> <span className='mx-1'>in {project?.address?.location}</span>
                                                            </>
                                                        } */}
                                                        <p className='mb-1 bhkp'>
                                                            {getSortBHKConf(project)} <span className='mx-1'>in {project?.address?.location}</span></p>

                                                    </div>
                                                </div>
                                                <div className='configflex'>
                                                    <div className="tab-content col-12">
                                                        {selectedConfig &&
                                                            <div>
                                                                {project.towers.map((tower, towerIndex) => {
                                                                    const matchingConfig = tower.configurations.find(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig);
                                                                    if (matchingConfig) {
                                                                        const { minCarpet, maxCarpet } = getMinMaxCarpet(selectedConfig);
                                                                        const { minPrice, maxPrice } = getMinMaxPrices(selectedConfig);
                                                                        const isFirstOccurrence = project.towers
                                                                            .slice(0, towerIndex)
                                                                            .every(otherTower => !otherTower.configurations.some(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig));
                                                                        if (isFirstOccurrence) {
                                                                            return (
                                                                                <p className='mb-0'> Total Price:
                                                                                    <span className="card-title fw-bold mx-1">
                                                                                        {isNaN(getMinMaxPrice(project).minPrice) || isNaN(getMinMaxPrice(project).maxPrice) || getMinMaxPrice(project).minPrice === 0 || getMinMaxPrice(project).maxPrice === 0 ? (
                                                                                            <span className="text-primary requesttitle" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                                                                        ) : (
                                                                                            getMinMaxPrice(project).minPrice === getMinMaxPrice(project).maxPrice ? (
                                                                                                `₹\u00A0${formatNumber(getMinMaxPrice(project).minPrice)}`
                                                                                            ) : (
                                                                                                // (getMinMaxPrice(project).minPrice !== '0' ?
                                                                                                // `₹\u00A0${formatNumber(maxPrice)}` : `₹\u00A0${formatNumber(getMinMaxPrice(project).minPrice)} - ₹${formatNumber(maxPrice)}`
                                                                                                // )
                                                                                                `₹\u00A0${formatNumber(getMinMaxPrice(project).minPrice)} - ${formatNumber(getMinMaxPrice(project).maxPrice)}`
                                                                                            )
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='configflex'>
                                                    <div className="tab-content col-12">
                                                        <p>Carpet Area: <span className='fw-semibold'>{(getMinMaxCarpets(project).minCarpet).toFixed(0)} - {(getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.</span></p>
                                                        {/* {console.log(getMinMaxCarpets(project))} */}
                                                    </div>
                                                </div>

                                                <p className='mb-0 developer-name text-theme'>IRFS Exclusive</p>




                                                <Carousel data-bs-theme="light" controls={false} indicators={false}>
                                                    {project.offers && project.offers.map((offer, offersIndex) => (
                                                        <Carousel.Item>
                                                            <h2 className='discount'>{offer.name}</h2>
                                                            <div className='btnflex'>
                                                                <CouponButtons
                                                                    project={project}
                                                                    parsedUserData={parsedUserData}
                                                                    handleLoginCoupon={handleLoginCoupon}
                                                                    handleNotLogin={handleNotLogin}
                                                                    buttonText="Download Free Coupon"
                                                                />
                                                                <div>
                                                                    <a className='lmore' onClick={handleToggle}>Learn more</a>
                                                                </div>
                                                            </div>
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                                {
                                                    project.slug === 'geras-island-of-joy-project-c' && (
                                                        <div className="mt-3 fw-semibold disclaimerTxt">
                                                            <p>Disclaimer: Offer only valid till {formatExpiryDate(project?.offers[0]?.expires_at)}</p>
                                                        </div>
                                                    )
                                                }
                                                {irfsAnimationStatus && (
                                                    <div className="mt-2 text-center-mob">
                                                        <Gamification projectDetails={project} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`image-side position-relative col-6 ${project.gallery && project.gallery.length <= 3 ? 'full-img' : ''}`}>
                                            <div className="irfs-exl"><img src={irfscuponlogo} className='img-fluid irfscuponlogo' /></div>
                                            {project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                                                project.gallery.length === 1 ? (
                                                    <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${s3url}/project-assets${project.gallery[0].url})` }}></div>
                                                ) : (
                                                    <Carousel data-bs-theme="light">
                                                        {project.gallery.map((image, index) => (
                                                            checkFileExtension(image.url) && (
                                                                <Carousel.Item key={index}>
                                                                    <img className='elevationImg' src={`${s3url}/project-assets${image.url}`} />
                                                                </Carousel.Item>
                                                            )
                                                        ))}
                                                    </Carousel>
                                                )
                                            ) : (
                                                <>
                                                    <div className='lazyload-placeholder project-img' style={{ paddingTop: '100%', backgroundImage: `url(${defaultGallery})` }}></div>
                                                </>
                                            )}
                                            <WishListBtn projectId={project.id} isWishlistActive={isWishlistActive} loggeduserdetails={parsedUserData} color={"#fff"} width={"30px"} height={"30px"} handleNotLogin projectBrochure = {project.brochure && project.brochure.length > 0 ? project.brochure[0].url : ''} projectName={project.name}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="projet-details-main py-sm-5 pb-3">
                                <div className="">
                                    <div className="row padinline">
                                        <div className="col-xl-9 col-12">
                                            <div className="proj-overview-head-wrapper">
                                                <div className="proj-overview-head py-2" id='menu-sticky' ref={projOverviewHeadRef}>
                                                    <div className={`menu-scroll-container d-flex justify-content-between align-items-center border-bottom-plp layout-mobile-slider`}>
                                                        <button className={`btn rounded-0 pb-2 ${activeSection === 'layout' ? 'active' : ''}`} id="toggle-button-2" onClick={() => {createGa4Event("Click","Project Detail Tabs", "Layout Plan"); scrollToSection(layoutPlanRef)}}>Layout Plan</button>

                                                        <button className={`btn rounded-0 pb-2 ${activeSection === 'propertyDetails' ? 'active' : ''}`} id="toggle-button-1" onClick={() => {createGa4Event("Click","Project Detail Tabs", "Property Details"); scrollToSection(propertyDetailsRef)}}>Property Details</button>
                                                        {project.usp && JSON.parse(project.usp).length > 0 && (
                                                            JSON.parse(project.usp)?.map((usp, index) => (
                                                                (index < 1 ? (
                                                                    <button className={`btn rounded-0 pb-2 ${activeSection === 'highlights' ? 'active' : ''}`} id="toggle-button-4" onClick={() => {createGa4Event("Click","Project Detail Tabs", "Highlights"); scrollToSection(highlightsRef)}}>Highlights</button>
                                                                ) : (
                                                                    <></>
                                                                ))
                                                            ))
                                                        )}
                                                        {project?.amenities?.length > 2 && (
                                                            <button className={`btn rounded-0 pb-2 ${activeSection === 'amenities' ? 'active' : 'Amenities'}`} id="toggle-button-1" onClick={() => {createGa4Event("Click","Project Detail Tabs", ""); scrollToSection(amenitiesRef)}}>Amenities</button>
                                                        )}
                                                        <button className={`btn rounded-0 pb-2 ${activeSection === 'specifications' ? 'active' : ''}`} id="toggle-button-1" onClick={() => {createGa4Event("Click","Project Detail Tabs", "Specifications"); scrollToSection(specificationRef)}}>Specifications</button>
                                                        <button className={`btn rounded-0 pb-2 ${activeSection === 'location' ? 'active' : ''}`} id="toggle-button-3" onClick={() => {createGa4Event("Click","Project Detail Tabs", "Location"); scrollToSection(locationRef)}}>Location</button>
                                                        {/* {project.faqs && JSON.parse(project.faqs).length > 0 && (
                                                        JSON.parse(project.faqs)?.map((faq, index) => (
                                                            faq.title && faq.description && (
                                                                (index < 1 ? (
                                                                    <button className={`btn rounded-0 pb-2 ${activeSection === 'developers' ? 'active' : ''}`} id="toggle-button-6" onClick={() => createGa4Event("Click","Project Detail Tabs", ""); scrollToSection(faqRef)}>FAQ</button>
                                                                ) : (
                                                                    <></>
                                                                ))
                                                            )
                                                        ))
                                                    )} */}
                                                        {project.developer?.established_time_stamp !== null && project.developer?.established_time_stamp !== undefined && project.developer?.established_time_stamp !== NaN && project?.developer?.total_ongoing_projects + project?.developer?.total_completed_projects !== 0 &&
                                                            <button className={`btn rounded-0 pb-2 ${activeSection === 'developers' ? 'active' : ''}`} id="toggle-button-3" onClick={() => {createGa4Event("Click","Project Detail Tabs", "About Developer"); scrollToSection(developerRef)}}>About Developer</button>
                                                        }

                                                        {/* {
                                                            project.rera_qr_image && (
                                                                <button className={`btn rounded-0 pb-2 ${activeSection === 'rera' ? 'active' : ''}`} id="toggle-button-3" onClick={() => createGa4Event("Click","Project Detail Tabs", ""); scrollToSection(reraQrImageRef)}>RERA Details</button>
                                                            )

                                                        } */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-column gap-3 pt-4">

                                                <div id="element-5" ref={layoutPlanRef}>
                                                    <div className="pdplist-background ">
                                                        <p className="fs-5 text-plp fw-bold m-0">Layout Plan</p>
                                                    </div>
                                                    <ProjectDetailUnitFloorPlan projectSlug={project.slug} projectId={project.id} developerId={project.developer?.id} projectIsSoldOut = {project.is_soldout} />
                                                </div>
                                                {project.is_soldout === false && (
                                                <div id='element-8' className='mt-sm-5 mt-3'>
                                                    <div className='wishlist-banner'>
                                                        <div className='d-flex align-items-bottom justify-content-around'>
                                                            <div className='d-flex align-items-center wishlist-inner'>
                                                                <img src={wishlistBanner} className='tabcontentproject' />
                                                                <img src={InterestedImg} className='tabcontentirfs' />
                                                                <div className='inner mt-4 mt-sm-3 ms-sm-5 ms-0'>
                                                                    {
                                                                        isIRFS === true && irfsAnimationStatus === true && (Array.isArray(project?.offers)) ? (
                                                                            <>
                                                                                <h3 className='text-white ms-2 hurryUp'>Hurry! Indian Realty Flash Sale is a Limited Period Event</h3>
                                                                                <div className='hurryCoupon'>
                                                                                    <CouponButtons
                                                                                        project={project}
                                                                                        parsedUserData={parsedUserData}
                                                                                        handleLoginCoupon={handleLoginCoupon}
                                                                                        handleNotLogin={handleNotLogin}
                                                                                        buttonText="Download Coupon and Redeem on Site Today!"
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <h3 className='text-white ms-2'>Interested In This Property?</h3>
                                                                                <div className="text-center text-md-start">
                                                                                    {/* <span className="slide-text" style={{ opacity: slideTextOpacity }}>Click To Shortlist!</span>
                                                                                    <div
                                                                                        id="slider"
                                                                                        onMouseDown={handleMouseDown}
                                                                                        onTouchStart={handleMouseDown}
                                                                                        onMouseUp={handleMouseUp}
                                                                                        onTouchEnd={handleMouseUp}
                                                                                        // onMouseMove={handleMouseMove}
                                                                                        // onTouchMove={handleMouseMove}
                                                                                        style={{ left: `${sliderLeft}px` }}
                                                                                        ref={sliderRef}
                                                                                    >
                                                                                        <Icon icon={`${isUnlocked ? 'gravity-ui:check' : 'ep:arrow-right-bold'}`} id="locker" width={"26px"} color='#00208B' />
                                                                                    </div> */}
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            if (parsedUserData === null) {
                                                                                                handleNotLogin("Wishlist");
                                                                                            } else {
                                                                                                handleShortListedClick(project.id);
                                                                                            }
                                                                                            createGa4Event('Click', 'Project Detail', `Shortlist Button - ${project.name}`);
                                                                                        }}
                                                                                        className="btn sc rounded btnHover position-relative z-2 ms-lg-2"
                                                                                    >
                                                                                        Click To Shortlist!
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )}

                                                <div id="element-2" ref={propertyDetailsRef}>
                                                    <div className="pdplist-background">
                                                        <p className="fs-5 text-plp fw-bold m-0">Property Details</p>
                                                    </div>
                                                    <div className="d-flex flex-wrap bg-white property-desc" style={{ rowGap: "25px" }}>
                                                        {project.total_buildings && (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Units</span>
                                                                    <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-bedrooms">{/*{project.total_buildings} Buildings - */} {project.total_units} units</span></span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {project.project_size_readable && (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Project Area</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-bathrooms">{fixNumberTwoDecimalFromString(project.project_size)} acres</span></span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Carpet area</span>

                                                                {
                                                                    getMinMaxCarpets(project).minCarpet === getMinMaxCarpets(project).maxCarpet ? (
                                                                        <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-carpet"></span>{(getMinMaxCarpets(project).minCarpet).toFixed(0)} sq.ft.</span>
                                                                    ) : (
                                                                        <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-carpet"></span>{(getMinMaxCarpets(project).minCarpet).toFixed(0)} - {(getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Configurations</span>

                                                                <span className="mt-2 d-block text-secondary fw-500">
                                                                    <span className="property-detail-carpet">
                                                                        {`${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}`}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {project?.developer?.name && (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Developer</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500">{project?.developer?.name}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/* <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                    <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                        <span className="text-property">Project</span>

                                                        <span className="mt-2 d-block text-secondary fw-500"></span>
                                                    </div>
                                                </div> */}
                                                        {/* <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                    <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                        <span className="text-property">Facing</span>

                                                        <span className="mt-2 d-block text-secondary fw-500">East</span>
                                                    </div>
                                                </div> */}

                                                        {project.project_status != '' ? (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Status</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500">
                                                                        {/* {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status} */}
                                                                        {project.project_status ? project.project_status : 'Under Construction'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Status</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500"> {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {project.possession_date_readable && (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Possession by</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500">{convertShortDate(project.possession_date_readable)}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {project.project_type && (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">Property type</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500">{project.project_type}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(project?.rera_registration_no) && (
                                                            <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                                <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                    <span className="text-property">RERA ID</span>

                                                                    <span className="mt-2 d-block text-secondary fw-500">{(project?.rera_registration_no)?.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>
                                                {project.usp && JSON.parse(project.usp).length > 0 && (
                                                    JSON.parse(project.usp).map((usp, index) => (
                                                        (index < 1 ? (
                                                            <div id={"element-4"} key={index} ref={highlightsRef}>
                                                                <div className="pdplist-background ">
                                                                    <p className="fs-5 text-plp fw-bold m-0">Highlights</p>
                                                                </div>
                                                                <div className="d-flex flex-column flex-md-row bg-white highlight-desc">
                                                                    <div className='highligh-img hidden-ipad'>
                                                                        <div className='inner-wrap'>
                                                                            <img src={highlightsImg} className='img-fluid tabcontentirfs' />
                                                                            <img src={highlighesImg} className='img-fluid tabcontentproject' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="">

                                                                        <ol className="highlight-ul row-first d-flex flex-column gap-1 flex-md-row mb-1">
                                                                            {project.usp &&
                                                                                JSON.parse(project.usp)?.map((usp, index) => (
                                                                                    index < 2 &&
                                                                                    <li key={index} className="text-property fs-6">
                                                                                        <p className="m-0">{usp}</p>
                                                                                    </li>
                                                                                ))}
                                                                        </ol>
                                                                        <ol className="highlight-ul row-second  d-flex gap-2 flex-column flex-md-row">
                                                                            {project.usp &&
                                                                                JSON.parse(project.usp)?.map((usp, index) => (
                                                                                    index >= 2 && usp != '' &&
                                                                                    <li key={index} className="text-property fs-6">
                                                                                        <p className="m-0">{usp}</p>
                                                                                    </li>
                                                                                ))}
                                                                        </ol>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                        )
                                                    ))
                                                )}
                                                {project?.amenities?.length > 2 && (
                                                    <div className='' ref={amenitiesRef}>
                                                        <Accordion defaultActiveKey="0" className='mainSectionAccordion' >
                                                            <Accordion.Item eventKey={0}>
                                                                <Accordion.Header>
                                                                    <div className="pdplist-background col-12">
                                                                        <p className="fs-5 text-plp fw-bold m-0">Amenities</p>
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="bg-white amenities-desc" style={{ rowGap: "15px" }}>
                                                                        <ul className='row ps-5' style={{ 'listStyle': 'disc' }}>
                                                                            {project.amenities
                                                                                .sort((a, b) => a.name.split(' ').length - b.name.split(' ').length)
                                                                                .map((amenity, index) => (
                                                                                    <li key={index} className="col-6 col-md-3 pe-4 ps-0 pt-3" style={{ 'listStyle': 'disc' }}>
                                                                                        <span className="mt-2 d-block text-plp" title={amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}>
                                                                                            {amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}
                                                                                        </span>
                                                                                    </li>
                                                                                ))}
                                                                        </ul>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                )}

                                                {project.specifications && project.specifications !== '[]' && (
                                                    <div ref={specificationRef}>
                                                        <Accordion defaultActiveKey="0" className='mainSectionAccordion'>
                                                            <Accordion.Item eventKey={0}>
                                                                <Accordion.Header>
                                                                    <div className="pdplist-background col-12" >
                                                                        <p className="fs-5 text-plp fw-bold m-0">Specifications</p>
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="d-flex flex-column bg-white specification-desc">
                                                                        <div className="d-flex flex-column gap-3 specification-wrap">
                                                                            <Accordion defaultActiveKey="0">
                                                                                {sortedCombinedData.map((item, index) => (
                                                                                    <Accordion.Item key={index} eventKey={index}>
                                                                                        <Accordion.Header>
                                                                                            <span className='text-capitalize'>{item.specification_type.replace(/_/g, ' ').toLowerCase().replace(' specs', '')}</span>
                                                                                        </Accordion.Header>
                                                                                        <Accordion.Body>
                                                                                            <ul className='specification-listing'>
                                                                                                {item.description.split('\n').map((line, lineIndex) => (
                                                                                                    <li key={lineIndex}>{line}</li>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </Accordion.Body>
                                                                                    </Accordion.Item>
                                                                                ))}
                                                                            </Accordion>
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                )}
                                                {project?.address?.latitude !== undefined && project?.address?.longitude !== undefined && (
                                                    <div id="element-3" ref={locationRef}>
                                                        <div className="pdplist-background">
                                                            <p className="fs-5 text-plp fw-bold m-0">Project Location & Connectivity</p>
                                                        </div>
                                                        <div className='col-12 position-relative mapwrap'>
                                                            {/* <MapContainerLeaflet latitude={position[0]} longitude={position[1]}
                                                            projectname={project.name}
                                                            address1={project.address.address1}
                                                            address2={project.address.address2}
                                                            address3={project.address.address3}
                                                            city={project.address.city}
                                                            height={isMobile ? '250px' : '450px'}
                                                            image={project?.gallery && project.gallery[0] ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery}
                                                        /> */}
                                                            <EsriLeafletMap latitude={position[0]} longitude={position[1]} height={'450px'} project={project} />
                                                            {/* <h4 style={{ "position": "absolute", "top": "10px", "left": "10px" }}>{project.name}</h4> */}
                                                        </div>

                                                    </div>
                                                )}
                                                {/* {project.faqs && JSON.parse(project.faqs).length > 0 && (
                                                <div id="element-6" ref={faqRef}>
                                                    <div className="pdplist-background">
                                                        <p className="fs-5 text-plp fw-bold m-0">FAQ</p>
                                                    </div>
                                                    <div className="d-flex flex-column bg-white faq-desc">
                                                        <div className="d-flex flex-column gap-3">
                                                            <ol className="highlight-ul d-flex flex-column gap-3">
                                                                {JSON.parse(project.faqs).map((faq, index) => (
                                                                    <li key={index} className="text-property fs-6">
                                                                        <p className="m-0 mb-2 text-secondary fs-6">{faq.question}</p>
                                                                        <p>{faq.answer}</p>
                                                                    </li>
                                                                ))}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            )} */}                                            
                                                {project.developer?.established_time_stamp !== null && project.developer?.established_time_stamp !== undefined && project.developer?.established_time_stamp !== NaN && project?.developer?.total_ongoing_projects + project?.developer?.total_completed_projects !== 0 &&
                                                    <><div id="element-7" ref={developerRef}>
                                                        <div className="pdplist-background">
                                                            <p className="fs-5 text-plp fw-bold m-0">Developer Legacy</p>
                                                        </div>
                                                        <div className='col-12 position-relative'>
                                                            <div className='d-md-flex flex-wrap gap-0 gap-md-5 bg-white developer-desc justify-content-center justify-content-md-between'>
                                                                <div className='developer-logo pe-0 pe-md-5 border-md-end text-md-left text-center'>
                                                                    <img src='' alt='' />
                                                                    <p>{project?.developer?.name}</p>
                                                                </div>
                                                                <div className='d-md-flex flex-md-row justify-content-md-around text-center gap-0 gap-md-5 flex-md-fill'>
                                                                    <div className='developer-stats col-xs-6 mb-4'>
                                                                        <p>{
                                                                            project.developer?.established_time_stamp === null || project.developer?.established_time_stamp === undefined || project.developer?.established_time_stamp === NaN ? 'NA' :
                                                                                toCovertYear(project.developer?.established_time_stamp) == 0 ? 1 : toCovertYear(project.developer?.established_time_stamp)
                                                                        }+</p>
                                                                        <span>Years of experience</span>
                                                                    </div>
                                                                    <div className='developer-stats col-xs-6 mb-4'>
                                                                        <p>{project?.developer?.total_ongoing_projects + project?.developer?.total_completed_projects}+</p>
                                                                        <span>Total projects </span>
                                                                    </div>
                                                                    {/* <div className='developer-stats col-xs-6 mb-4'>
                                                                <p>15+</p>
                                                                <span>Sq.Ft delivered</span>
                                                            </div>
                                                            <div className='developer-stats col-xs-6 mb-4'>
                                                                <p>15+</p>
                                                                <span>Customers served</span>
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></>
                                                }

                                                {project.rera_qr_image && project.rera_qr_image.length > 0 && (
                                                    <div id="element-6" ref={reraQrImageRef}>
                                                        <div className="pdplist-background">
                                                            <p className="fs-5 text-plp fw-bold m-0">Rera Details</p>
                                                        </div>
                                                        <div className="d-flex flex-column bg-white rera-desc">
                                                            
                                                                <ProjectReraQrImage reraImageArray={project.rera_qr_image} projectName={project.name} reraId = {(project?.rera_registration_no)?.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}/>
                                                        </div>
                                                    </div>
                                                )}
                                                {parsedUserData !== null ? (
                                                    <div id="element-9">
                                                        <div className='project-ratting'>
                                                            <div className='d-flex flex-wrap gap-5 bg-white ratting-desc align-items-center'>
                                                                <div className='project-img pe-5'>
                                                                    <img
                                                                        src={project?.gallery && project.gallery ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery}
                                                                        className="img-fluid"
                                                                        alt={project.name}
                                                                    />
                                                                    <p className='text-center fw-bold mt-3 mb-0'>{project?.name}</p>
                                                                </div>
                                                                {!isSubmitedReview ? (
                                                                    ratingReviewForm ? (
                                                                        <div className='d-flex flex-row justify-content-around text-center gap-5 flex-fill'>
                                                                            <div className='ratting-info text-center'>
                                                                                <h4>Your Opinion matters to us!</h4>
                                                                                <p className='mb-0'>Rate this property</p>
                                                                                <div className='d-flex justify-content-center'>
                                                                                    <ProjectRating className="justify-content-center" rating={rating} setRating={setRating} edit={true} />
                                                                                </div>
                                                                                <a onClick={() => headleSubmitReview()} className='mt-3 btn btn-theme rounded-sm fw-bold px-4'>Next</a>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='d-flex flex-fill fill-review-form flex-column'>
                                                                            <div className='d-flex ratting-info align-items-center justify-content-start'>
                                                                                <h6 className='mb-0 me-3 review-form-title'>What impressed you the most? </h6>
                                                                                <ProjectRatingSet className="" rating={rating} setRating={setRating} size={"30"} edit={true} />
                                                                                {(rating >= 0 && rating < 2) && <img src={rating1} className='ms-2 total-rating-emogi' />}
                                                                                {(rating >= 2 && rating < 3) && <img src={rating2} className='ms-2 total-rating-emogi' />}
                                                                                {(rating >= 3 && rating < 4) && <img src={rating3} className='ms-2 total-rating-emogi' />}
                                                                                {(rating >= 4 && rating < 5) && <img src={rating4} className='ms-2 total-rating-emogi' />}
                                                                                {rating === 5 && <img src={rating5} className='ms-2 total-rating-emogi' />}
                                                                                <p className='mb-0 ms-2'>
                                                                                    {(rating >= 0 && rating < 2) && <span>Poor</span>}
                                                                                    {(rating >= 2 && rating < 3) && <span>Unsatisfactory</span>}
                                                                                    {(rating >= 3 && rating < 4) && <span>Satisfactory</span>}
                                                                                    {(rating >= 4 && rating < 5) && <span>Good</span>}
                                                                                    {rating === 5 && <span>Excellent</span>}
                                                                                </p>
                                                                            </div>
                                                                            <div className='project-parameter-list'>
                                                                                <div className='d-flex flex-wrap gap-2 mt-3'>
                                                                                    <p><input type='checkbox' name='projectParameter' id="location" onChange={handleCheckboxChange} /><label htmlFor='location'>Location</label></p>
                                                                                    <p><input type='checkbox' name='projectParameter' id="pricing" onChange={handleCheckboxChange} /><label htmlFor='pricing'>Pricing</label></p>
                                                                                    <p><input type='checkbox' name='projectParameter' id="amenities" onChange={handleCheckboxChange} /><label htmlFor='amenities'>Amenities</label></p>
                                                                                    <p><input type='checkbox' name='projectParameter' id="brand" onChange={handleCheckboxChange} /><label htmlFor='brand'>Brand</label></p>
                                                                                    <p><input type='checkbox' name='projectParameter' id="layout" onChange={handleCheckboxChange} /><label htmlFor='layout'>Layout</label></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='write-review mt-2'>
                                                                                <h6 className='mb-0 me-3 review-form-title text0left'>Write your review</h6>
                                                                                <div className='d-flex gap-3 align-items-end'>
                                                                                    <textarea className='form-control' placeholder='Type your review here…' value={reviewText} onChange={handleReviewTextChange}></textarea>
                                                                                    <a className='mt-3 px-4 review-btn' onClick={handleSubmitReview}>Submit</a>
                                                                                </div>
                                                                            </div>
                                                                            <div className='recommend-friend'>
                                                                                <div className='d-flex gap-2'>
                                                                                    <input type='checkbox' name="recommend" id="recommend" checked={recommendChecked} onChange={handleRecommendChange} />
                                                                                    <label htmlFor='recommend'>I would recommend this property to my friends!</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    <div className='text-center'>
                                                                        <h4>Thank you for your feedback!</h4>
                                                                        <p>Your review has been submitted successfully.</p>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                                }
                                            </div>
                                            <div className='d-block d-lg-none'>
                                                {brochureURL && (
                                                    <div className='brochure-wrap mt-4'>
                                                        <div className='brochure-inner'>
                                                            <div className='d-flex flex-row user-info align-self-center align-items-center p-3'>
                                                                <span className='nameTitle'>
                                                                    {parsedUserData && parsedUserData.name ? (
                                                                        <span className=''>
                                                                            {parsedUserData.name
                                                                                .split(" ")
                                                                                .map(word => word.charAt(0))
                                                                                .join("")
                                                                                .toUpperCase() // Optionally, convert to uppercase
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <span className=''>
                                                                            G
                                                                        </span>

                                                                    )}
                                                                </span>
                                                                <div className='user-name-info align-self-center flex-fill ps-3'>
                                                                    <p className='mb-0'>Dear <span>{parsedUserData !== null ? parsedUserData.name : 'Guest'}</span></p>
                                                                    <span>Your Brochure Is Ready!</span>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-center align-self-center align-items-center py-3 download-btn-info'>
                                                                <span className='happy-face'><Icon icon="system-uicons:face-happy" width="22px" height="22px" color='#fff' /></span>
                                                                <p className='align-self-center mb-0 mx-2'>Take The Next Step</p>
                                                                {parsedUserData !== null ? (
                                                                    <a href={`${s3url}/project-assets${brochureURL}`} onClick={() => { setUserDownloadBrochure(project.id); createGa4Event("Click", 'Project Detail Download Brochure', projectName); }} target='_blank' className='btn btn-theme rounded-pill fw-700'>
                                                                        <span>Download</span>
                                                                        <Icon icon="ep:arrow-right-bold" width="1em" height="1em" color='#fff' />
                                                                    </a>
                                                                ) : (
                                                                    <a onClick={() => {
                                                                        handleNotLogin("Download Brochure");
                                                                        setUserDownloadBrochure(project.id);
                                                                        createGa4Event("Click", 'Project Detail Download Brochure', projectName);
                                                                    }} className='btn btn-theme rounded-pill fw-700'>
                                                                        <span>Download </span>
                                                                        <Icon icon="ep:arrow-right-bold" width="1em" height="1em" color='#fff' />
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                        <div className="col-3 d-none d-xl-block ">
                                            {
                                                // project.total_score >= 1 && (
                                                //     <div className="right-card-one">
                                                //         <div className='right-card-one-inner position-relative'>
                                                //             <div className='inner-content'>
                                                //                 <img src={bwLogo} />
                                                //                 <h3>Property <br />Scorecard!</h3>
                                                //                 {/* <div>
                                                //                 <a onClick={() => setOpen(true)} className='btn btn-theme rounded-pill fw-700'>Know more</a>
                                                //                 {open ? <ScorePopup text="Hello there!" closePopup={() => setOpen(false)} /> : null}
                                                //             </div> */}
                                                //             </div>
                                                //             <div className='scorecard position-absolute'>
                                                //                 <img src={scoreBoard} className='' />
                                                //                 <p>Your BW Score Is:</p>
                                                //                 <div className='scoreValue'>
                                                //                     <CircularProgress percentage={project?.total_score} />
                                                //                 </div>
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                // )
                                            }
                                            {brochureURL && (
                                                <div className='brochure-wrap mt-4'>
                                                    <div className='brochure-inner'>
                                                        <div className='d-flex flex-row user-info align-self-center align-items-center p-3'>
                                                            <span className='nameTitle'>
                                                                {parsedUserData && parsedUserData.name ? (
                                                                    <span className=''>
                                                                        {parsedUserData.name
                                                                            .split(" ")
                                                                            .map(word => word.charAt(0))
                                                                            .join("")
                                                                            .toUpperCase() // Optionally, convert to uppercase
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    <span className=''>
                                                                        G
                                                                    </span>

                                                                )}
                                                            </span>
                                                            <div className='user-name-info align-self-center flex-fill ps-3'>
                                                                <p className='mb-0'>Dear <span>{parsedUserData !== null ? parsedUserData.name : 'Guest'}</span></p>
                                                                <span>Your Brochure Is Ready!</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-center align-self-center align-items-center py-3 download-btn-info'>
                                                            <span className='happy-face'><Icon icon="system-uicons:face-happy" width="22px" height="22px" color='#fff' /></span>
                                                            <p className='align-self-center mb-0 mx-2'>Take The Next Step</p>
                                                            {parsedUserData !== null ? (
                                                                <a href={`${s3url}/project-assets${brochureURL}`} onClick={() => {
                                                                    setUserDownloadBrochure(project.id);
                                                                    createGa4Event("Click", 'Project Detail Download Brochure', projectName);
                                                                }} target='_blank' className='btn btn-theme rounded-pill fw-700'>
                                                                    <span>Download</span>
                                                                    <Icon icon="ep:arrow-right-bold" width="1em" height="1em" color='#fff' />
                                                                </a>
                                                            ) : (
                                                                <a onClick={() => {
                                                                    handleNotLogin("Download Brochure");
                                                                    setUserDownloadBrochure(project.id);
                                                                    createGa4Event("Click", 'Project Detail Download Brochure', projectName);
                                                                }} className='btn btn-theme rounded-pill fw-700'>
                                                                    <span>Download </span>
                                                                    <Icon icon="ep:arrow-right-bold" width="1em" height="1em" color='#fff' />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='releated-dev-wrap mt-4'>
                                                <div className='releated-dev-inner'>
                                                    <h3>Explore Other Top Developers In</h3>
                                                    <a href={`${appAbsPath}/properties/buy/${globalCity}?searchtype=locality_name&locality_name=${project?.address?.location}`} className='btn btn-theme rounded-pill fw-700'>{project?.address?.location}</a>
                                                    <img src={buildImg} className='tabcontentproject' />
                                                    <img src={ExploreMore} className='tabcontentirfs' />
                                                </div>
                                            </div>

                                            {project.is_soldout === false && (
                                                <FormEnquiry projectId={projectId} developerId={developerId} projectName={projectName} />
                                            )}



                                        </div>
                                    </div>
                                </div>
                            </section>
                            <RelatedProjects project={project}
                                slidesToShow={[4, 3, 3, 2, 1]}
                                locationName={project?.address?.location}
                                is_irfs={project?.is_irfs}
                            />

                            <>
                                <div className="projectDisclaimer mt-2 mt-md-5">
                                    <p className="text-grey">
                                        <span>Disclaimer:</span> The information and materials provided here are for general informational purposes only and should not be considered an endorsement, guarantee, representation, or offer from BeyondWalls. Information Sources: The project details displayed on this platform are gathered from publicly accessible sources, such as State RERA websites (where applicable), official project websites managed by builders or their authorized partners, and formal documents shared by these advertisers (including brochures, price lists, and payment plans). BeyondWalls presents this information in a simplified format to assist users with research and learning but does not claim ownership of the content. It is recommended that users verify all details directly with the builder or promoter before proceeding with any purchase decisions.
                                    </p>
                                </div>
                            </>

                            {/* <section className='similer-project'>
                            <h2 className='fw-bold mb-3'>You may also like</h2>

                            <div className='row'>
                                <Slider {...settingsForRelatedProject}>
                                    {relatedProject.map((relatedProject, index) => (
                                        <div className='' key={index}>
                                            <Link to={`${appAbsPath}/project/${relatedProject.slug}`} className='text-decoration-none' onClick={() => handlerelatedProj()}>
                                                <div className='card mx-2'>
                                                    <img src={relatedProject.gallery[0] && relatedProject.gallery[0].url !== undefined ? `${s3url}/project-assets${relatedProject.gallery[0].url}` : defaultGallery} className="img-fluid" alt={relatedProject.name} />
                                                    <div className='card-body d-flex align-items-center justify-content-between pb-0'>
                                                        <h5 className='mb-0 text-truncate' title={relatedProject.name}>{relatedProject.name}</h5>
                                                        <p className='mb-0'>{getMinMaxPrice(relatedProject) && getMinMaxPrice(relatedProject).minPrice !== null && formatNumber((getMinMaxPrice(relatedProject).minPrice).toFixed(2)) !== 0
                                                            ? <span>{`₹\u00A0${formatNumber((getMinMaxPrice(relatedProject).minPrice).toFixed(2))} - ${getMinMaxPrice(relatedProject) && getMinMaxPrice(relatedProject).maxPrice !== null ? formatNumber((getMinMaxPrice(relatedProject).maxPrice).toFixed(2)) : ''}`}</span>
                                                            : null
                                                        }</p>
                                                    </div>
                                                    <p className='ps-3 text-truncate'>By {relatedProject.developer.name}</p>
                                                    <div className='card-footer border-0'>
                                                        <p>{relatedProject.locality_name} | {getSortBHKConf(relatedProject)}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </section> */}
                        </div>
                    </section>
                </main>
                {
                    showExitIntentPopup && (
                        <ExitIntentComponent
                            specificProject={project}
                        />
                    )
                }
            </div>
        </>
    );
};

export default ProjectDetails;
