import React from "react";
import { parsedUserData } from "../../../Admin/Utils";

// console.log('parsedUserData:', parsedUserData);

const Breadcrumbs = ({ breadcrumbs }) => {
    return (
        <section className="navigation-bar mb-5">
        <div className="container">
            <div className="row">
                <nav className="py-3 navbar-expand ">
                    <div className="">
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item ">
                            <a className="nav-link roc-font usp" href="/profile">Profile</a>
                        </li>
                        {parsedUserData.userrole !== 'developer' &&(
                        <li className="nav-item ">
                            <a className="nav-link roc-font usp" href="/profile/my-coupons">My Coupons</a>
                        </li>
                        )}

                        {parsedUserData.userrole === 'developer' &&(
                            <li className="nav-item">
                                <a className="nav-link roc-font usp" href="/profile/user-coupons">User Coupons</a>
                            </li>
                        )}
                        </ul>
                    </div>
                    </div>
                </nav>
            </div>
        </div>
    </section>
    );

}

export default Breadcrumbs;