import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { appAbsPath, userToken, executeLaravelAPI } from '../../Utils';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import addNewImg from '../../../../Assets/img/add-new.svg';

const itemsPerPageOptions = [20, 50, 100, 500, 1000];

const LocationsList = () => {
    const inputRef = useRef(null);
    const pageDetails = { pagetitle: 'Locations', pageslug: 'locations' };

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        getLocations();
    }, [currentPage, itemsPerPage]);

    const getLocations = async () => {
        try {
            setLoading(true);
            const params = { page: currentPage + 1, per_page: itemsPerPage, show_all: 1 };
            const result = await executeLaravelAPI('locations', params, 'GET', userToken);
            const locationData = result.data.data;
            setPaginatedData(locationData);
            setTotalItems(result.data.total);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(0);
    };

    const handleStatusChange = async (e) => {
        const locationId = parseInt(e.target.dataset.locationId, 10);
        const updatedData = paginatedData.map(location => 
            location.id === locationId ? { ...location, is_active: e.target.checked } : location
        );
        setPaginatedData(updatedData);
        try {
            await executeLaravelAPI(`location/update/${locationId}`, { is_active: e.target.checked }, 'PUT', userToken);
        } catch (error) {
            console.error('Error updating location status:', error);
        }
    };

    return (
        <section className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="box">
                        <div className='col-lg-12 col-12 pe-0 d-flex justify-content-end'>
                            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/add/location`}>
                                <img src={addNewImg} alt="Add New" />
                            </NavLink>
                        </div>
                        <div className="box-body table-container mt-4 hr-scroll-style">
                            <table className="table table-responsive table-hover dashboard-table shadow-sm mb-0">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Location Name</th>
                                        <th>Status</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr><td colSpan={4}>Loading...</td></tr>
                                    ) : (
                                        paginatedData.map((location, index) => (
                                            <tr key={location.id}>
                                                <td>{index + 1 + currentPage * itemsPerPage}</td>
                                                <td>{location.name}</td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`custom-switch-${index}`}
                                                        data-location-id={location.id}
                                                        checked={location.is_active}
                                                        onChange={handleStatusChange}
                                                    />
                                                </td>
                                                <td>
                                                    <NavLink className='btn btn-primary btn-sm' to={`${appAbsPath}/portaladmin/location/edit/${location.id}`}>Edit</NavLink>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='row mt-5 align-items-center'>
                            <div className='col-lg-4'>
                                <p className='graylight mb-0'>Showing {currentPage * itemsPerPage + 1}-{Math.min((currentPage + 1) * itemsPerPage, totalItems)} of {totalItems} items</p>
                            </div>
                            <div className='col-lg-8 d-flex justify-content-end align-items-center'>
                                <div className='me-3'>
                                    <span className='graylight'>Results per page </span>
                                    <select className='showPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                        {itemsPerPageOptions.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <ReactPaginate
                                    pageCount={Math.ceil(totalItems / itemsPerPage)}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination justify-content-end mb-0'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LocationsList;
