import { useEffect } from "react";

const UTMTracker = () => {
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  const getCookie = (name) => {
    const match = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
    return match ? match[2] : null;
  };

  const getUTMParameter = (param) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  };

  useEffect(() => {
    // Load Google Tag script only if not already present
    if (!window.gtag) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16665468291";
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag("js", new Date());
        window.gtag("config", "AW-16665468291");
      };
      document.head.appendChild(script);
    }

    // Get UTM parameters from URL
    let source = getUTMParameter("utm_source");
    let campaign = getUTMParameter("utm_campaign");
    let medium = getUTMParameter("utm_medium");

    // Check cookies/localStorage for stored UTM parameters
    if (!source && !campaign && !medium) {
      source = getCookie("utm_source") || localStorage.getItem("utm_source");
      campaign = getCookie("utm_campaign") || localStorage.getItem("utm_campaign");
      medium = getCookie("utm_medium") || localStorage.getItem("utm_medium");
    } else {
      // Save UTM in cookies and localStorage for persistence
      if (source) {
        setCookie("utm_source", source, 7);
        localStorage.setItem("utm_source", source);
      }
      if (campaign) {
        setCookie("utm_campaign", campaign, 7);
        localStorage.setItem("utm_campaign", campaign);
      }
      if (medium) {
        setCookie("utm_medium", medium, 7);
        localStorage.setItem("utm_medium", medium);
      }
    }

    // Stop execution if no UTM parameters found
    if (!source && !campaign && !medium) {
      console.log("❌ No UTM parameters found. Conversion event not triggered.");
      return;
    }

    console.log("✅ UTM Parameters Found (including persisted):", { source, campaign, medium });

    // Fire conversion event if UTM parameters exist
    const fireConversionEvent = () => {
      if (typeof window.gtag === "function") {
        window.gtag("event", "conversion", {
          send_to: "AW-16665468291/ysI3CPSojskZEIO724o-",
        });
        console.log("🔥 Google Ads conversion event fired!");
      } else {
        console.warn("⚠️ gtag function is not available.");
      }
    };

    // Wait for gtag to load and retry if necessary
    let retries = 0;
    const interval = setInterval(() => {
      if (typeof window.gtag === "function" || retries >= 10) {
        clearInterval(interval);
        fireConversionEvent();
      }
      retries++;
    }, 500); // Check every 500ms

  }, []); // Runs once on mount, persists across pages

  return null;
};

export default UTMTracker;
