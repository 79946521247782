
import React from 'react';
import LinkImg from '../../../../Assets/img/link.png';
import ProjectImg from '../../../../Assets/img/project1.png';
import Building from '../../../../Assets/img/building.svg';
// import Next from '../../../../Assets/img/next-btn.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {SlickArrowLeft, SlickArrowRight} from '../../../Admin/Utils';


const Punelocal = ({}) => {

    const Localitysettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        variableWidth:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        centerMode: false,
        adaptiveHeight:false,
        prevArrow: <SlickArrowLeft currentSlide slideCount/>,
        nextArrow: <SlickArrowRight currentSlide slideCount/>,
        responsive: [{
                breakpoint: 1024,
                settings: {
                  arrows:true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }]     
    };


    return(<>
        <section className='pt-5 punelocal'>
            <div className='container'>
                <div className='row punelocalrow'>
                    <div className='bnrsubhead'>1305 Localities in Pune</div>
                    <div className='buildflex'>
                        <div className='bluehead'>
                            <div className='location-title'>Homes in <b>Talegaon Dabhade</b></div>
                            <div className='linkimg'>
                                <img src={LinkImg} className="img-fluid"/>
                            </div>
                            <div className='build-box'>
                                <div>
                                    <img src={Building} className="img-fluid"/>
                                </div>
                                <div className='build-cont'>
                                    <p className='project-number'>112</p>
                                    <p>Projects</p>
                                </div>
                            </div>
                        </div>
                        <div className='build-card build-card1'>
                            <div className='rate'>Avg. Rating</div>
                            <p className='sqft cardtxt'>₹5,879/- per sqft.</p>
                        </div>
                        <div className='build-card build-card2'>
                            <div className='rate'>Rank <span className='rank'>236</span></div>
                            <p className='prop cardtxt'>out of 2345 Properties</p>
                        </div>
                    </div>
                    <div className='local-slider'>
                        <Slider {...Localitysettings}>
                            <div className='local-card'>
                                <div className='card-inner'>
                                    <img src={ProjectImg} className="img-fluid"/>
                                    <div className='card-content'>
                                        <div className='card-subtitle'>Siddhashila Groups</div>
                                        <div className='card-title'>Treasure Troves</div>
                                        <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                    </div>
                                </div>
                            </div>
                            <div className='local-card'>
                                <div className='card-inner'>
                                    <img src={ProjectImg} className="img-fluid"/>
                                    <div className='card-content'>
                                        <div className='card-subtitle'>Siddhashila Groups</div>
                                        <div className='card-title'>Treasure Troves</div>
                                        <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                    </div>
                                </div>
                            </div>
                            <div className='local-card'>
                                <div className='card-inner'>
                                    <img src={ProjectImg} className="img-fluid"/>
                                    <div className='card-content'>
                                        <div className='card-subtitle'>Siddhashila Groups</div>
                                        <div className='card-title'>Treasure Troves</div>
                                        <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                    </div>
                                </div>
                            </div>
                            <div className='local-card'>
                                <div className='card-inner'>
                                    <img src={ProjectImg} className="img-fluid"/>
                                    <div className='card-content'>
                                        <div className='card-subtitle'>Siddhashila Groups</div>
                                        <div className='card-title'>Treasure Troves</div>
                                        <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className='row-btm'>
                        <div>
                            <div className='subhead'>Near Old Mumbai-Pune Highway, At Tank Bond</div>
                            <div className='rating-div'><span className='rate-txt'>Avg. Rating</span><span className='starts'></span></div>
                        </div>
                        <div className='btngroup'>
                            <button type="button" className="btn btn-secondary">See All Projects</button>
                            <button className="btn btn-theme bg-theme btn-sm p-2 px-3">Get Directions</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='container'>
                <div className='row bgimg-row'>
                    <div className='col-7'>
                        <h2>Find Your Dream Home With Us!</h2>
                    </div>
                    <div className='col-5'>&nbsp;</div>
                </div>
            </div>
        </section>

        <section className='pt-5 punelocal'>
        <div className='container'>
            <div className='row punelocalrow'>
                <div className='bnrsubhead'>1305 Localities in Pune</div>
                <div className='buildflex'>
                    <div className='bluehead'>
                        <div className='location-title'>Homes in <b>Talegaon Dabhade</b></div>
                        <div className='linkimg'>
                            <img src={LinkImg} className="img-fluid"/>
                        </div>
                        <div className='build-box'>
                            <div>
                                <img src={Building} className="img-fluid"/>
                            </div>
                            <div className='build-cont'>
                                <p className='project-number'>112</p>
                                <p>Projects</p>
                            </div>
                        </div>
                    </div>
                    <div className='build-card build-card1'>
                        <div className='rate'>Avg. Rating</div>
                        <p className='sqft cardtxt'>₹5,879/- per sqft.</p>
                    </div>
                    <div className='build-card build-card2'>
                        <div className='rate'>Rank <span className='rank'>236</span></div>
                        <p className='prop cardtxt'>out of 2345 Properties</p>
                    </div>
                </div>
                <div className='local-slider'>
                    <Slider {...Localitysettings}>
                        <div className='local-card'>
                            <div className='card-inner'>
                                <img src={ProjectImg} className="img-fluid"/>
                                <div className='card-content'>
                                    <div className='card-subtitle'>Siddhashila Groups</div>
                                    <div className='card-title'>Treasure Troves</div>
                                    <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                </div>
                            </div>
                        </div>
                        <div className='local-card'>
                            <div className='card-inner'>
                                <img src={ProjectImg} className="img-fluid"/>
                                <div className='card-content'>
                                    <div className='card-subtitle'>Siddhashila Groups</div>
                                    <div className='card-title'>Treasure Troves</div>
                                    <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                </div>
                            </div>
                        </div>
                        <div className='local-card'>
                            <div className='card-inner'>
                                <img src={ProjectImg} className="img-fluid"/>
                                <div className='card-content'>
                                    <div className='card-subtitle'>Siddhashila Groups</div>
                                    <div className='card-title'>Treasure Troves</div>
                                    <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                </div>
                            </div>
                        </div>
                        <div className='local-card'>
                            <div className='card-inner'>
                                <img src={ProjectImg} className="img-fluid"/>
                                <div className='card-content'>
                                    <div className='card-subtitle'>Siddhashila Groups</div>
                                    <div className='card-title'>Treasure Troves</div>
                                    <div className='card-desc'>2 & 3BHK Residences in Wakad, Pune</div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className='row-btm'>
                    <div>
                        <div className='subhead'>Near Old Mumbai-Pune Highway, At Tank Bond</div>
                        <div className='rating-div'><span className='rate-txt'>Avg. Rating</span><span className='starts'></span></div>
                    </div>
                    <div className='btngroup'>
                        <button type="button" className="btn btn-secondary">See All Projects</button>
                        <button className="btn btn-theme bg-theme btn-sm p-2 px-3">Get Directions</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>);
};

export default Punelocal;
