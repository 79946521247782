import React, { useEffect, useState, lazy, useContext, Suspense } from 'react';
import Loader from './../../../Components/Loader';
// import {Carousel, Accordion, Tab} from 'react-bootstrap';
import {appAbsPath, parsedUserData, executeLaravelAPI, userToken, userData, executeLaravelFrontAPI} from './../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
// import ReactPaginate from 'react-paginate';
// import { useLocation } from 'react-router-dom';
// import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
// import { Icon } from '@iconify/react';
// import ProjectCard from './ProjectCard';
// import Placeholder from 'react-bootstrap/Placeholder';
 
const ProjectCardListing = lazy(() => import('./ProjectCardListing'))
 
// const itemsPerPageOptions = [1000];
 
{/* Long Amount to convert short Amount like 30.5L or 1.2 Cr.  */}
const WishListDetails = ({loggeduserdetails}) => {
    // const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [noDataFound, setNoDataFound] = useState(false);
    const pageDetails={'pagetitle':'Projects','pageslug':'projects'};
 
    // const [currentPage, setCurrentPage] = useState(0);
    // const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    // const [getProjectBhkTypeData, getProjectBhkType] = useState([]);
    const [selectedBHK, setSelectedBHK] = useState([]);
    const [selectedConf, setSelectedConf] = useState([]);
 
    const [userdetails, setUserResponseData] = useState(null);
    // const [wishlistData, setUserWishList] = useState([]);
    const [isWishList, isWishlistActive] = useState(true);
    const { globalCity } = useContext(SearchVisibilityContext);
        const { urlcity , urllocality } = useParams();
    const [getAllParams1, setAllParams1] = useState([]);
 
 
    const paramsloc = new URLSearchParams(window.location.search);
    const locality_name = paramsloc.get('locality_name') || null;
    const [hasParams, setHasParams] = useState(false);
 
 
    useEffect(() => {
        getProjects();        
        if(loggeduserdetails) {
            if(Object.keys(loggeduserdetails).length===0) {
                userData().then((result) => {
                    setUserResponseData(result);
                    //getWishListProjects();
                })
            }   
        }
    }, []);
 
 
    const getProjects = async (paramsData) => {
        setLoading(true);
        let result = {};
        let params = {
            ...paramsData,
            city: globalCity || 'pune'
        };
        try {
            //setLoading(true);
                                 
            // console.log(paramsData);    
            // const params = {
            //     "city": "pune"
            // }
            // result = await executeLaravelFrontAPI('projects', { ...paramsData }, 'GET');
            //params.per_page = 500;
            // alert(globalCity);
            // result = await executeLaravelAPI('userwishlist', params, 'GET', userToken);      
            // console.log('wishlistprojects', result.data.data);
            // setPaginatedData(result.data.data);
            // setTotalItems(result.data.total);
            // isWishlistActive(true);
 
            result = await executeLaravelAPI('userwishlist', params, 'GET', userToken);
  
            console.log('wishlistprojects', result.data.data);
            setPaginatedData(result.data.data);
            setTotalItems(result.data.total);
            console.log(result.data.data);
            isWishlistActive(true);
 
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // setLoading(false);
            if (result.data && result.data.total === 0) {
                setNoDataFound(true);
            }
        }
    }
    const filterByBhkType = (result) => {
        return result.filter(item => !isNaN(item.name.charAt(0))) || [];
    };
    const filterByConfigurationType = (result) => {
        return result.filter(item => isNaN(item.name.charAt(0))) || [];    
    };
 
    {/* Pagination Function */}
    // const handlePageClick = ({ selected }) => {
    //     setLoading(true);
    //     setPaginatedData([]);
    //     setCurrentPage(selected);
 
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // };
 
 
    {/* Configuration Tab Function */}
    const [selectedTab, setSelectedTab] = useState(null);
    
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
 
    const getMinMaxCarpet = (project, configurationName) => {
        const carpetValues = [];
 
        project.towers.forEach((tower) => {
            const matchingConfigurations = tower.configurations.filter((config) => config.name === configurationName);
 
            if (matchingConfigurations.length > 0) {
                carpetValues.push(parseFloat(matchingConfigurations[0].carpet));
            }
        });
 
        const minCarpet = Math.min(...carpetValues);
        const maxCarpet = Math.max(...carpetValues);
 
        return { minCarpet, maxCarpet };
    };
 
    const convertToJSON = (data) => {
        return JSON.stringify(data, (key, value) => {
          if (Array.isArray(value)) {
            return value.map((item) => convertToJSON(item));
          } else if (typeof value === 'object') {
            return convertToJSON(value);
          }
          return value;
        });
      };
    //Wishlisted Data
    // const getWishedListed= async () => {
    //     try {
    //         const result = await executeLaravelAPI('userwishlist', '', 'GET', userToken);      
    //         setPaginatedData(result.data.data);
    //         setTotalItems(result.data.total);
    //         isWishlistActive(true);
    //         // console.log(jsonArray.length);
 
    //     } catch (error) {
    //         console.error('Error fetched project data:', error);
    //     }
    // };
    const handleShowFilterSidebarBtn = () => {
        const rightSideDiv = document.querySelector('.filter-sidebar');
        rightSideDiv.classList.toggle('active');
    }
    const  handleHideFilterSidebarBtn = () => {
        // const filterForm = document.querySelector('.filter-sidebar');
        // filterForm.reset();
        handleShowFilterSidebarBtn();
    }
    useEffect(() => {
    const handleShortlistedClick = (event) => {
        if (event.target.closest('.shortlisted')) {
            getProjects();
        }
    };
    document.addEventListener('click', handleShortlistedClick);
    return () => {// This will log when the event listener is removed
        document.removeEventListener('click', handleShortlistedClick);
    };
    }, []);
 
    return (
        <>
            {/* <Header onCityChange={handleCityChange}/> */}
            <React.Fragment key={pageDetails.pageslug}>
            {paginatedData.length !== 0 && loading &&
            <main className={`plp-page pb-5 pt-lg-5 pt-2 mt-5`}>
                <section className="pt-4">
                    <div className="px-2 wishListWrapper">
 
                        <div className="flex-column-reverse flex-md-row">
                            <div className={`d-flex flex-column gap-4 rounded project-listing-main col-12 col-xl-12`}>
                                <div className="d-flex row">
                                    <div className='col-lg-6 col-12'>
                                   
 
                                    {paginatedData ? (
                                        paginatedData.length === 0 && loading ? (
                                            <></>
                                        
                                    ) : (
                                        <h1 className="fs-6 mt-2"><span className="text-primary">Showing wishlist properties is</span> <span id="search-count" className="text-primary">{totalItems} </span>
                                    </h1>
                                    )
                                ) : (
                                    // <Placeholder  animation="wave" >
                                    //     <Placeholder xs={12} className="custom-loading-skeleton"/>
                                    // </Placeholder>
                                    <></>
                                )}
 
 
 
                                    
                                    </div>
                                    
                                </div>
                                <div className={`${paginatedData.length === 0 ? "" : "min-height-100vh"}`}>
                                {paginatedData ? (
                                    paginatedData.length === 0 && loading ? (
                                        (noDataFound ? (
                                            <div className="text-center mt-0">
                                                <h2>No Projects Found</h2>
                                            </div>
                                        ) : (
                                            // <Loader />
                                            <></>
                                        ))
                                    ) : (
                                        <div className="wishlistProjects p-0" id='wishlistProjects'>
                                            <Suspense fallback={<Loader />}>
                                            {paginatedData.map((project, index) => (
                                                <ProjectCardListing
                                                key={index}
                                                project={project}
                                                selectedTab={selectedBHK || selectedConf}
                                                handleTabClick={handleTabClick}
                                                getMinMaxCarpet={getMinMaxCarpet}
                                                appAbsPath={appAbsPath}
                                                userdetails={userdetails}
                                                isWishlistActive={isWishList}
                                                loggeduserdetails={parsedUserData}
                                            />
                                            ))}
                                            </Suspense>
                                        </div>
                                    )
                                ) : (
                                    <Loader />
                                )}
                                </div>
                            </div>
                        </div >
                    </div >
                </section >
            </main>
            }
            </React.Fragment>
        </>
    )
}
export default WishListDetails;