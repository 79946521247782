import { useEffect, useState, useContext } from "react";
import { parsedUserData, createGa4Event } from "../../Admin/Utils";
import { SearchVisibilityContext } from "./SearchVisibilityContext";
import wishlistBanner from '../../../Assets/img/wishlist-banner.png';

const messages = [
    {
        headText: "Skip the Search - We'll Find the Best Homes for You",
        ctaText: "Sign Up & Talk to an Expert"
    },
    {
        headText: "Make Informed Decisions with Real Data",
        subText: "Our experts analyze the market to find your best options.",
        ctaText: "Sign Up for Personalized Insights"
    },
    {
        headText: "The Perfect Home Won't Wait - Why Should You?",
        subText: "Get instant updates on price drops, upcoming launches, and exclusive pre-booking offers.",
        ctaText: "Sign Up for Instant Alerts"
    },
    {
        headText: "The Best Homes, Handpicked for You",
        subText: "Our experts analyze the market to match you with the right property!",
        ctaText: "Sign Up & Get a Personalised Consultation"
    },
    {
        headText: "Find the Right Home, Faster",
        ctaText: "Sign Up for a Personalised Consultation"
    },
    {
        headText: "Let's Simplify Your Home Search",
        ctaText: "Sign Up for Expert Assistance"
    }
];

const RandomTextLoginButton = () => {
    const [randomMessage, setRandomMessage] = useState({});
    const { setDownloadCouponBtn } = useContext(SearchVisibilityContext);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setRandomMessage(messages[randomIndex]);
    }, []);

    const handleNavItemClick = (btnClick) => {
        document.getElementById('overlay').style.display = 'block';
        sessionStorage.setItem('popupShown', 'true');

        if (btnClick === "Log in") {
            document.getElementById('login_title').textContent = "BeyondWalls";
            setDownloadCouponBtn(false);
        }
    };

    return (
        !parsedUserData && (
            <div id="RandomTextLoginButton">
                <div id='element-8' className='mt-md-5 mt-3 mb-3'>
                    <div className='wishlist-banner'>
                        <div className='d-flex align-items-bottom justify-content-around'>
                            <div className='wishlist-inner'>
                                <img src={wishlistBanner} className='tabcontentproject' />
                                <div className='inner mt-lg-5 ms-lg-5 ms-0'>
                                    <>
                                        <h3 className='text-white ms-2 mb-2'>{randomMessage.headText}</h3>
                                        {randomMessage.subText && (
                                            <p className='text-white ms-2'>{randomMessage.subText}</p>
                                        )}
                                        <button
                                            onClick={() => {
                                                handleNavItemClick('Log in');
                                                createGa4Event('listingPageMidScrollLogIn', 'ListingPageMidScrollLogIn', 'Log in');
                                            }}
                                            className="btn sc rounded btnHover position-relative z-2 ms-lg-2"
                                        >
                                            {randomMessage.ctaText}
                                        </button>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default RandomTextLoginButton;
