import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";  // Import Bootstrap Accordion
import { Helmet } from 'react-helmet-async';
import { appAbsPath, lazyLoadImages } from "../../Admin/Utils";
import BlogIcon from "../../../Assets/img/house.png";
import Sideimg from "../../../Assets/img/sideimg.png";
import Right from "../../../Assets/img/gr-right.png";
import BRight from "../../../Assets/img/blue-right.png";
import BgBlog from "../../../Assets/img/bgblog.svg";

const BlogDetail = () => {
  const [ShowLink, setShowLink] = useState(false);
  const navigate = useNavigate();
  const { slug } = useParams();
  const [blog, setBlogData] = useState(null);

  useEffect(() => {
    lazyLoadImages();
    getBlog(slug);
  }, [slug]);


  function decodeHTMLEntities(text) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }

  
  const getBlog = async (slug) => {
    try {
      const apiUrl = `https://corporate.beyondwalls.com/?portalblogsdetails=1&portalblogid=${slug}`;
      const response = await fetch(apiUrl);
      if (response.status === 200) {
        const data = await response.json();
        if (!data) navigate('/404', { replace: true });
        data.content = modifyImageSrc(data.content);
        setBlogData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const modifyImageSrc = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const images = doc.getElementsByTagName("img");

    for (let img of images) {
      if (img.hasAttribute("data-src")) {
        img.setAttribute("src", img.getAttribute("data-src").replace(/www.beyondwalls\.com/g, "corporate.beyondwalls.com"));
        img.removeAttribute("data-src");
      }
    }
    return doc.body.innerHTML;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      lazyLoadImages();
      setShowLink(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>{blog?.meta_title}</title>
        <meta name="description" content={blog?.meta_description} />
        <link rel="canonical" href={`https://www.beyondwalls.com/blog/${slug}`} />
      </Helmet>

      <main className="plp-page pb-5 bg-white">
        <section className="blog-banner mt-5 pt-5">
          <div className="container position-relative blogbannerbg" style={{ backgroundImage: `url(${blog?.thumbnail})` }}>
            <div className="row">
              <div className="icon-circle">
                <img src={blog?.blog_icon?.url || BlogIcon} alt="Blog Banner" className="img-fluid icon-circleimg" />
              </div>
              <img src={BgBlog} alt="Blog Banner" className="img-fluid bgblogwhite" />
            </div>
          </div>
        </section>

        <section className="details-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8">
                <h1 className="blog-title">{decodeHTMLEntities(blog?.title)}</h1>
                {/* <h1 className="blog-title">{blog?.title}</h1> */}
                {blog?.content && (
                  <article className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }}></article>
                )}
              </div>

              <div className="col-12 col-md-4">
                <div className="blog-sidebar">
                  <div className="sidebox1">
                    <img data-src={Sideimg} alt="Blog Banner" className="img-fluid lazy" />
                    <div className="sidecontent">
                      <h2 className="side-title">Know it alls with beyondwalls</h2>
                      <a href="/">
                        <img src={Right} alt="Blog Banner" className="img-fluid gr-right" />
                      </a>
                    </div>
                  </div>
                  <div className="sidebox2">
                    <h2 className="side-title">Analyse the Locations With High Livability Quotient?</h2>
                    <img src={BRight} alt="Blog Banner" className="img-fluid b-right" />
                  </div>
                </div>
              </div>

              {/* FAQ Section */}
              {blog?.faqs && blog.faqs.length > 0 && (
                <div className="col-12 faq-sec">
                  <h2 className="faq-title">Frequently Asked Questions</h2>
                  <Accordion>
                    {blog.faqs.map((faq, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body dangerouslySetInnerHTML={{ __html: faq.answer }}>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default BlogDetail;
 